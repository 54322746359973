import { Paper } from "@mui/material";
import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import { Authority, ColorX } from "IZOArc/STATIC";
import _ from "lodash";
import { useState } from "react";
import tabs from "./tabs";

const Chat = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [addOns] = useState([]);
  const renderTabPanels = () => {
    const preLoad = false;
    let tabsAllowed = tabs.filter((o: any) => o.reqAuth === "None" || Authority.IsAccessibleQ(o.reqAuth, o.reqLevel, o.reqFunc));
    return _.map(tabsAllowed, (o, i) => {
      if (!preLoad && selectedTab !== i) return <Box key={i} />;

      return (
        <VStack key={i} hidden={selectedTab !== i} style={{ width: "100%", height: "100%", alignContent: "center" }}>
          {_.isFunction(o.render) ? o.render(addOns) : o.render}
        </VStack>
      );
    });
  };

  const renderTabButtons = () => {
    let tabsAllowed = tabs.filter((o: any) => o.reqAuth === "None" || Authority.IsAccessibleQ(o.reqAuth, o.reqLevel, o.reqFunc));

    return _.map(tabsAllowed, (o: any, i) => {
      let label = o.label;
      let icon = o.icon;
      if (o.noTransform) {
        label = <Typography style={{ height: "100%", textTransform: "none" }}>{o.label}</Typography>;
      }
      switch (o.iconPos) {
        case "top":
        default:
          break;
        case "bottom":
          label = (
            <VStack gap={o.spacing || 5}>
              {label}
              {icon}
            </VStack>
          );
          icon = null;
          break;
        case "left":
          label = (
            <HStack gap={o.spacing || 5}>
              {o.alignment === "right" && <Spacer />}
              {icon}
              {label}
              {o.alignment === "left" && <Spacer />}
            </HStack>
          );
          icon = null;
          break;
        case "right":
          label = (
            <HStack gap={o.spacing || 5}>
              {o.alignment === "right" && <Spacer />}
              {label}
              {icon}
              {o.alignment === "left" && <Spacer />}
            </HStack>
          );
          icon = null;
          break;
      }
      return <Tab key={i} label={label} icon={icon} disabled={o.disabled} style={{ color: ColorX.GetColorCSS("Primary"), minHeight: o.height || "100%", minWidth: o.width || 200 }} />;
    });
  };

  const onChangeTab = (e: any, tab: any) => {
    setSelectedTab(tab);
  };

  const themeColor = ColorX.GetColorCSS("Primary");

  return (
    <VStack className="TestChat" width="100%" sx={{ background: "#f0f8ff" }}>
      <VStack className={"centre"} style={{ width: "100%", maxWidth: "600px", minHeight: "100%", height: "100%" }}>
        <Paper style={{ width: "100%", zIndex: 3, position: "fixed" }}>
          <Tabs
            TabIndicatorProps={{ style: { background: ColorX.GetColorCSS("Primary") } }}
            value={selectedTab}
            onChange={onChangeTab}
            sx={{ backgroundColor: ColorX.GetColorCSS("Secondary"), color: themeColor, height: "50px" }}
            variant="scrollable"
            scrollButtons={"auto"}
            allowScrollButtonsMobile={true}
          >
            {renderTabButtons()}
          </Tabs>
        </Paper>
        <Paper style={{ paddingTop: "50px", width: "100%", height: "100%" }}>{renderTabPanels()}</Paper>
      </VStack>
    </VStack>
  );
};
export default Chat;
