import { Warning } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { MultiLangText } from "Pages/FLE/interfaces/FLE.interface";

interface IMissingIconProps {
  text?: MultiLangText;
  lang?: keyof MultiLangText;
}

export const MissingIcon = ({ text = { EN: "Missing" }, lang = "EN" }: IMissingIconProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        color: "error.main",
        gap: 1,
      }}
    >
      <Warning />
      {text[lang]}
    </Box>
  );
};
