import { ColorX } from "IZOArc/STATIC";
import { Typography } from "@mui/material";

import "./Header.scss";

const Header = ({ title = "" }) => {
  return (
    <Typography
      style={{
        textAlign: "left",
        width: "100%",
        fontSize: 25,
        color: ColorX.GetColorCSS("Primary"),
      }}
    >
      {title}
    </Typography>
  );
};
export default Header;
