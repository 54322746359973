import { Component } from "react";

import _ from "lodash";
import PropsType from "prop-types";
import { Box } from "@mui/material";

import schema from "./schema";

import Formizo from "IZOArc/LabIZO/Formizo";
import { Accessor, ColorX, store } from "IZOArc/STATIC";

import SuggestedIntent from "./SuggestedIntent";

class Analyzer extends Component {
  static propTypes = {
    onMounted: PropsType.func,
  };

  static defaultProps = {
    onMounted: null,
  };

  constructor() {
    super();
    this.state = {
      records: [],
    };
  }

  componentDidMount() {
    this._setAllStates(() => {});
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Analyzer.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        if (this.props.onMounted) {
          this.props.onMounted({
            selectMsg: this._selectMsg,
            recordForInfo: this._recordForInfo,
          });
        }
        if (callback) callback();
      }
    );
  };

  _selectMsg = (msgId) => {
    let { records } = this.state;
    let record = records.filter((e) => e._id === msgId);
    if (record.length === 0) return;

    let rec = record[0];
    console.log(rec);

    this.setState((state, props) => ({
      form: {
        ...state.form,
        editing: rec,
      },
    }));
  };

  _recordForInfo = (data, res, startTime, endTime, _id, version) => {
    let processTime = (endTime - startTime) / 1000;
    console.log(data);

    let { channel, input, session_id } = data;
    let { content, type } = input;

    let { language, ws, intent, detect, watson_response, ansRes, message, live_chat } = res;
    let wsRes = (watson_response && watson_response[ws]) || {};
    ansRes = ansRes || {};

    let { __ans, __tans, __func, __param, __change } = ansRes;
    let joinC = (v) => (v && (_.isArray(v) ? v.join(",") : v)) || "";

    //mapping
    let payload = {
      _id: _id,
      sessionId: session_id,
      version: version,
      in_time: startTime,
      out_time: endTime,
      process_time: processTime,
      channel: channel,
      input: content,
      inputType: type,
      language: language,
      workspace: ws,
      live_chat: live_chat,
      intent: intent || "",
      intents: wsRes.intents || [],
      entities: wsRes.entities || [],
      nodesVisited: (wsRes.output && wsRes.output.nodes_visited) || [],
      detect: detect || [],
      __ans: joinC(__ans),
      __tans: joinC(__tans),
      __func: joinC(__func),
      __param: joinC(__param),
      __change: __change || false,
      output: message,
    };

    this.setState((state, props) => ({
      form: {
        ...state.form,
        editing: payload,
      },
      records: [...state.records, payload],
    }));
  };

  renderForm = () => {
    let { form } = this.state;
    return (
      <Formizo
        cssPrefix={"talk2va"}
        formID={"talk2va"}
        schema={schema.Info}
        readOnly={true}
        buttons={[]}
        auth={store.user.authority}
        level={store.user.level}
        defaultValue={form && form.editing}
        fieldSize={"small"}
      ></Formizo>
    );
  };

  render() {
    const { form, records } = this.state;
    return (
      <Box width={750} overflow="scroll" height="95vh">
        {form && records && <SuggestedIntent form={form} records={records} />}
        {this.renderForm()}
      </Box>
    );
  }
}

export default Analyzer;
