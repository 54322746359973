import { HStack } from "IZOArc/LabIZO/Stackizo";

import PasswordChanger from "./PasswordChanger/PasswordChanger";
import EmailChanger from "./EmailChanger/EmailChanger";

import { store } from "IZOArc/STATIC";

const Head = [
  {
    label: "Display Name",
    name: "UserDisplayName",
    width: 200,
  },
  {
    label: "Username",
    name: "username",
    width: 200,
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Last Update",
    name: "LastUpdate",
    transform: "datetime",
    dateFormat: "YYYY/MM/DD HH:mm:ss",
  },
];

const Table = [
  ...Head,
  {
    label: "Actions",
    name: "",
    width: 500,
    Cell: (row, field, addOns) => {
      if (store.user.UserID !== row._id) return <HStack />;
      return (
        <HStack spacing={200}>
          <PasswordChanger docID={row._id} username={row.username} jwt={store.user.JWT} />
          {/* <EmailChanger docID={row._id} username={row.username} jwt={store.user.JWT} /> */}
        </HStack>
      );
    },
  },
];

const Add = [...Head];

const Info = [
  {
    label: "Display Name",
    name: "UserDisplayName",
    format: "text",
  },
  {
    label: "Username",
    name: "username",
    format: "text",
  },
  {
    label: "Email",
    name: "email",
    format: "text",
  },
  {
    label: "Last Update",
    name: "LastUpdate",
    format: "text",
  },
];

const Edit = [...Info];

const Export = [];

const Import = [];

const ImportFormat = [...Export];

const Filter = [];

const AdvFilter = [];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
};

export default schema;
