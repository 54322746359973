import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { HStack } from "./Layouts";

interface IRadioButtonsGroupProps {
  label: string;
  value: string;
  direction?: "row" | "column";
  onChange: (value: string) => void;
  id?: string;
  options: { label: string; value: string; disabled?: boolean }[];
  disabled?: boolean;
}

export const RadioButtonsGroup = (props: IRadioButtonsGroupProps) => {
  const { label = "", value = "", options = [], onChange = () => {}, direction = "row", id = "radio-buttons-group-label", ...others } = props;
  return (
    <FormControl>
      <HStack width={"100%"} gap={2}>
        <FormLabel id={id}>{label}</FormLabel>
        <RadioGroup //
          row={direction === "row"}
          value={value}
          aria-labelledby="row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          {options.map((opt, index) => {
            return (
              <FormControlLabel //
                key={`row-radio-buttons-group-${index}`}
                value={opt.value}
                control={<Radio />}
                label={opt.label}
                onChange={() => onChange(opt.value)}
                disabled={opt.disabled || props.disabled}
              />
            );
          })}
        </RadioGroup>
      </HStack>
    </FormControl>
  );
};
