const Request = {
  DBInfo: "/Tables/CounterExample/Info",
  List: "/Tables/CounterExample/List",
  Add: "/Tables/CounterExample/Add",
  Delete: "/Tables/CounterExample/Delete",
  Edit: "/Tables/CounterExample/Edit",
  Export: "/Tables/CounterExample/Export",
  Import: "/Tables/CounterExample/Import",
  DeleteBulk: "/Tables/CounterExample/DeleteBulk",
  Sync: "/Tables/CounterExample/Sync",
};

let exports = {
  Request
};

export default exports;