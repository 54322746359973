import { IAPIResponseError, IAPIResponseErrorPayload } from "../../@types/be.d/IAPIResponse.d";
import store from "./AppStore";

export interface IErrorX extends IAPIResponseError {
  errorCode?: number;
  message?: string;
  name?: string;
  stack?: any;
}

class ErrorX {
  /**
   *
   * @param e IAPIResponseError | IAPIResponseErrorPayload
   */
  static Handle(e: IErrorX) {
    try {
      if (e.payload) {
        return this.HandleError(e.payload);
      }
      return this.HandleError(e);
    } catch (e) {
      return store.Alert("Unidentified Error", "error");
    }
  }

  static HandleError(e: IAPIResponseErrorPayload) {
    try {
      if (e.errorCode) {
        this.HandleErrorCode(e);
      } else {
        store.Alert(e.message || e.name, "error");
      }
    } catch (e) {
      return store.Alert("Unidentified Error", "error");
    }
  }

  static HandleErrorCode(e: IAPIResponseErrorPayload) {
    const { errorCode } = e;
    switch (errorCode) {
      case 4001:
        this.Logout(e.message);
        break;
      case 4002:
        this.Logout(e.message);
        break;
      case 4003:
        this.Logout(e.message);
        break;
      default:
        store.Alert("Error [" + e.errorCode + "] : " + e.message, "error");
        break;
    }
  }

  static Logout(message?: string) {
    setTimeout(() => {
      store.clearUser();
      store.clearProductItems();

      //redirect to login page
      if (window.location.href !== "/") {
        window.location.assign("./");
        if (message) store.Alert(message, "warning");
      }
    }, 500);
  }
}

export default ErrorX;
