import { ColorX, store } from "IZOArc/STATIC";
import { Box, Tab, Tabs } from "@mui/material";
import { FC, useState } from "react";
interface IMultiLangTabsProps {
  langs?: {
    display: string;
    value: string;
  }[];
  children: (lang: string, index: number) => JSX.Element;
}
export const MultiLangTabs: FC<IMultiLangTabsProps> = ({ langs = store.sysInfo.Language.available, children }) => {
  const defaultLang = store.sysInfo.Language.default;
  const defaultTab = langs.findIndex((l) => l.value == defaultLang);
  const [tab, setTab] = useState(defaultTab);
  return (
    <Box p={2} width="100%">
      <Box mb={2}>
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          aria-label="basic tabs example"
          sx={{ backgroundColor: ColorX.GetColorCSS("TabIndexBG"), color: ColorX.GetColorCSS("Primary") }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {langs.map((lang, index) => {
            return <Tab key={`multilag-tabs-${index}`} label={lang.display} />;
          })}
        </Tabs>
      </Box>
      <Box>
        {langs.map((lang, index) => {
          return tab === index && children(lang.value, index);
        })}
      </Box>
    </Box>
  );
};
