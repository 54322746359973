import React, { Component } from 'react';

import PropsType from 'prop-types';
import * as THREE from 'three';
import { GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import _ from 'lodash';

import { Accessor } from 'IZOArc/STATIC';

/**
 * 
 * @augments {Component<Props, State>}
 */
class Avatar3D extends Component {

  static propTypes = {
    onMounted: PropsType.func,
    width: PropsType.number,
    height: PropsType.number,
  }

  static defaultProps = {
    onMounted: null,
    width: 300,
    height: 450
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this._setAllStates(() => {
      this._Init3D();
    });
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(Avatar3D.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), () => {
      if(this.props.onMounted){
        this.props.onMounted({
          Play: this._Play,
          GetActions: this._GetActions
        });
      }
      if(callback) callback();
    });
  }

  _Init3D = async () => {
    let {width, height} = this.props;
    let scene = new THREE.Scene();
    let clock = new THREE.Clock();
    let camera = new THREE.PerspectiveCamera( 50, width/height, 0.01, 500 );
    let renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setClearColor( 0x000000, 0 );
    renderer.setSize( width, height );
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.outputEncoding = THREE.sRGBEncoding;
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    this.ref.appendChild( renderer.domElement );

    camera.position.x = -0.15;
    camera.position.y = 0.85;
    camera.position.z = 2;
    camera.lookAt(-0.15, 0.85, 0);
    scene.fog = new THREE.Fog( 0xa0a0a0, 10, 50 );

    this.setState({
      scene: scene,
      camera: camera,
      renderer: renderer,
      clock: clock
    }, () => {
      this._LoadModel();
      this._Animate();
    });
  }

  _LoadModel = async () => {
    let {scene} = this.state;
    let loader = new GLTFLoader();
    console.log(loader);

    let gltf = await loader.loadAsync('models/girl2.glb');

    console.log(gltf);
    let model = gltf.scene;
    scene.add( model );

    let animations = gltf.animations;
    let mixer = new THREE.AnimationMixer(model);

    let actions = {};
    _.map(animations, (o, i) => {
      actions[o.name] = mixer.clipAction(o);
    });

    let defaultAni = "in_walk";

    mixer.addEventListener('finished', () => {
      this._Play("idle_normal", true);
    });

    this.setState({
      actions: actions,
      mixer: mixer,
      currentAni: defaultAni
    }, () => {
      this._Play(defaultAni);
    });
  }

  _GetActions = () => {
    let {actions} = this.state;
    if(actions)
      return Object.keys(actions);
  }

  _Play = (name, loop = false) => {
    this.setState({
      currentAni: name
    }, () => {
      let {mixer, actions} = this.state;
      if(mixer){
        mixer.stopAllAction();
      } 
      if(actions && actions[name]){
        let newAni = actions[name];
        newAni.setLoop(loop? THREE.LoopRepeat: THREE.LoopOnce);
        newAni.play();
      }
    });
    
  }

  _Animate = () => {
    let {scene, camera, renderer, mixer, clock} = this.state;
    requestAnimationFrame( this._Animate );
    if(renderer){
      let mixerUpdateDelta = clock.getDelta();
      if(mixer) mixer.update( mixerUpdateDelta );
      renderer.render( scene, camera );
    }
  }

  render(){
    let {width, height} = this.props;
    return (
      <div className={"3D"} style={{
          width: width, 
          height: height,
          position: "relative", 
          zIndex: 50
        }} 
        ref={ref => {this.ref = ref;}}
        >
      </div>
    );
  }

}

export default Avatar3D;