import { Group, Sync, DocumentScanner, Checkroom, LockPerson } from "@mui/icons-material";

import SysBnR from "./SysBnR/SysBnR";
import SysUser from "./SysUser/SysUser";
import SysChatbot from "./SysChatbot";
import SysNLP from "./SysNLP";
import SysGeneral from "./SysGeneral/SysGeneral";
import SysDisclaimer from "./SysDisclaimer/SysDisclaimer";
import SysProductControl from "./SysProductControl/SysProductControl";
import SysProductPermission from "./SysProductPermission/SysProductPermission";
import { Settings } from "@mui/icons-material";

const tabs = [
  {
    label: "General",
    icon: <Settings />,
    reqAuth: "System.General",
    iconPos: "left",
    noTransform: true,
    render: <SysGeneral />,
  },
  {
    label: "Disclaimer",
    icon: <DocumentScanner />,
    reqAuth: "System.General",
    iconPos: "left",
    noTransform: true,
    render: <SysDisclaimer />,
  },
  {
    label: "Backup & Restore",
    icon: <Sync />,
    reqAuth: "System.BnR",
    render: <SysBnR />,
    iconPos: "left",
    noTransform: true,
    alignment: "left",
  },
  {
    label: "User Access Control",
    icon: <Group />,
    render: <SysUser />,
    iconPos: "left",
    noTransform: true,
    alignment: "left",
  },
  {
    label: "NLP",
    icon: <i className="fas fa-microchip fa-lg" />,
    iconPos: "left",
    noTransform: true,
    reqAuth: "System.NLP",
    render: <SysNLP />,
  },
  {
    label: "Chatbot",
    icon: <i className="fas fa-robot fa-lg" />,
    reqAuth: "System.Chatbot",
    iconPos: "left",
    noTransform: true,
    render: <SysChatbot />,
  },
  {
    label: "Product Control",
    icon: <Checkroom />,
    iconPos: "left",
    noTransform: true,
    reqAuth: "System.General",
    render: <SysProductControl />,
  },
  {
    label: "Agent Permission",
    icon: <LockPerson />,
    iconPos: "left",
    noTransform: true,
    reqAuth: "System.General",
    render: <SysProductPermission />,
  },
];

export default tabs;
