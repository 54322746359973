import { Grid } from "@mui/material";
import { DBAnswerSpec } from "@types";
import { IFLEDoc } from "Pages/FLE/interfaces/FLE.interface";
import { useFormContext } from "react-hook-form";
import { DeleteButton } from "v2/Components/IconButtons";
import { ContextSummary } from "./ContextSummary";

interface IProps {
  index: number;
  name: `contexts.${number}`;
  onDelete: (index: number) => void;
  canDelete: boolean;
}

export function SummaryContainer({ index, canDelete, ...props }: IProps) {
  const { watch, setValue, getValues } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  function deleteContext() {
    return props.onDelete(index);
  }

  const context = watch(`contexts.${index}`);

  return (
    context && (
      <Grid container>
        <ContextSummary index={index} {...props} />
        {canDelete && (
          <Grid container item xs={1}>
            <DeleteButton onClick={deleteContext} />
          </Grid>
        )}
      </Grid>
    )
  );
}
