import PropsType from "prop-types";
import React, { Component } from "react";
import ZCTouch from "../ZCTouch";
import ZCMButtons from "./ZCMButtons";
import ZCMCards from "./ZCMCards";
import ZCMFooter from "./ZCMFooter";
import ZCMHeader from "./ZCMHeader";
import ZCMImage from "./ZCMImage";
import ZCMImgButtons from "./ZCMImgButtons";
import "./ZCMsg.css";
import ZCMSysMsg from "./ZCMSysMsg";
import ZCMTable from "./ZCMTable";
import ZCMTemplates from "./ZCMTemplates";
import ZCMText from "./ZCMText";
import ZCMVideo from "./ZCMVideo";

class ZCMsg extends Component {
  static propTypes = {
    _onQuickReply: PropsType.func,
    pos: PropsType.oneOf(["in", "out"]),
    item: PropsType.object,
    last: PropsType.bool,
    typingBubbles: PropsType.bool,
    opacity: PropsType.number,
  };

  static defaultProps = {
    _onQuickReply: () => {},
    onCardBTNPress: () => {},
    pos: "in",
    item: { msg: { text: "" } },
    last: false,
    typingBubbles: false,
    opacity: 1,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  renderHeader() {
    let { showHeader, pos, typingBubbles } = this.state;
    let { user } = this.state.item;

    if (pos !== "in") return;
    if (!showHeader || typingBubbles) return;

    return <ZCMHeader {...this.state} sender={user} />;
  }

  renderMsg() {
    let { showQuickRepliesAsButtons, disableButtonsAfterSend, disableTemplateButtonsAfterSend, typingBubbles, typingIndicator, last, cssp } = this.state;
    let { msg, addOns } = this.state.item;

    let rendered = [];

    if (typingBubbles) {
      rendered.push(
        typingIndicator || (
          <div className={cssp + " zcmtyping"} key={"loading"}>
            <div className={cssp + " zcmtyping-circles"} />
            <div className={cssp + " zcmtyping-circles"} />
            <div className={cssp + " zcmtyping-circles"} />
          </div>
        )
      );
      return rendered;
    }

    if (!msg) return;

    if (msg.title) {
      rendered.push(<ZCMText {...this.state} key={"title"} text={msg.title} iaddOns={addOns} />);
    }

    if (msg.image) {
      rendered.push(<ZCMImage {...this.state} key={"image"} src={msg.image} iaddOns={addOns} />);
    }

    if (msg.video) {
      rendered.push(<ZCMVideo {...this.state} key={"video"} video={msg.video} iaddOns={addOns} />);
    }

    if (msg.text) {
      rendered.push(<ZCMText {...this.state} key={"text"} text={msg.text} iaddOns={addOns} />);
    }

    if (msg.table) {
      rendered.push(<ZCMTable {...this.state} key={"table"} table={msg.table} iaddOns={addOns} />);
    }

    if (msg.quickReplies) {
      if (showQuickRepliesAsButtons) {
        rendered.push(<ZCMButtons {...this.state} key={"quickreplies"} buttons={msg.quickReplies} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
      }
    }

    if (msg.buttons) {
      rendered.push(<ZCMButtons {...this.state} key={"buttons"} buttons={msg.buttons} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.imgbuttons) {
      rendered.push(<ZCMImgButtons {...this.state} key={"imgbuttons"} buttons={msg.imgbuttons} disabled={disableButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.templates) {
      rendered.push(<ZCMTemplates {...this.state} key={"template"} templates={msg.templates} disabled={disableTemplateButtonsAfterSend && !last} iaddOns={addOns} />);
    }

    if (msg.cards) {
      rendered.push(<ZCMCards {...this.state} key={"cards"} cards={msg.cards} disabled={disableTemplateButtonsAfterSend && !last} iaddOns={addOns} onCardBTNPress={this.props.onCardBTNPress} />);
    }

    return rendered;
  }

  renderFooter() {
    let { showFooter, typingBubbles } = this.state;
    let { createdAt, status, lapseTime } = this.state.item;

    if (!showFooter || typingBubbles) return;

    return <ZCMFooter {...this.state} createdAt={createdAt} status={status} lapseTime={lapseTime} />;
  }

  _onMsgPress = (_id, msg) => {
    console.log("_onMsgPress: ", _id, msg);
    let { onMsgPress } = this.state;
    if (onMsgPress) {
      onMsgPress(_id, msg);
    }
  };

  _onMsgLongPress = (_id, msg) => {
    console.log("_onMsgLongPress", _id, msg);
    let { onMsgLongPress } = this.state;
    if (onMsgLongPress) {
      onMsgLongPress(_id, msg);
    }
  };

  render() {
    let { cssp, pos, canClickOnIn, canClickOnOut, opacity } = this.state;
    let { _id, msg } = this.state.item;

    if (msg && msg.system) {
      return <ZCMSysMsg {...this.state} key={_id} text={msg.system} />;
    }

    let disabled = !((pos === "in" && canClickOnIn) || (pos === "out" && canClickOnOut));

    return (
      <ZCTouch {...this.state} cssPrefix={cssp} key={_id} onPress={() => this._onMsgPress(_id, msg)} onLongPress={() => this._onMsgLongPress(_id, msg)} disabled={disabled}>
        <div className={cssp + " bubble " + pos} style={{ opacity: opacity }}>
          {this.renderHeader()}
          {this.renderMsg()}
          {this.renderFooter()}
        </div>
      </ZCTouch>
    );
  }
}

export default ZCMsg;
