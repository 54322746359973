import React, { Component } from "react";

import PropsType from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import NotchedOutline from "@mui/material/OutlinedInput/NotchedOutline";
import { Box } from "@mui/material";

import MUIUtils from "./_gears/MUIUtils";

import { Accessor, ColorX } from "IZOArc/STATIC";
import { HStack } from "IZOArc/LabIZO/Stackizo";
import { Typography } from "@mui/material";

const styles = {
  root: {
    position: "relative",
    width: "100%",
  },
  content: {
    padding: "18.5px 14px",
    borderRadius: "5px",
    width: "100%",
    position: "relative",
  },
  inputLabel: {
    // slight alteration to spec spacing to match visual spec result

    fontWeight: "bold",
    color: "primary.main",
  },
  notched: {
    width: "100%",
  },
};

class OutlinedBox extends Component {
  static propTypes = {
    label: PropsType.string,
    theme: PropsType.object,
    style: PropsType.object,
  };

  static defaultProps = {
    label: "",
    theme: {},
  };

  constructor() {
    super();

    this.state = {
      labelWidth: 100,
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(OutlinedBox.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        this.setState({
          labelWidth: this.labelRef != null ? this.labelRef.offsetWidth : 0,
        });
      }
    );
  };

  render() {
    let { id, label, children, style } = this.props;
    let { labelWidth } = this.state;

    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          ...style,
        }}
      >
        <InputLabel ref={(e) => (this.labelRef = e)} htmlFor={id} variant="outlined" sx={{ ...styles?.inputLabel }} shrink>
          {label}
        </InputLabel>

        <Box id={id} sx={{ ...styles.content, p: 2 }}>
          <HStack>{children}</HStack>
          <NotchedOutline
            notched
            labelWidth={labelWidth}
            sx={{
              borderColor: "grey.200",
            }}
          />
        </Box>
      </Box>
    );
  }
}

export default OutlinedBox;
