import { FormizoSchema } from "IZOArc/LabIZO/Formizo";

const category: FormizoSchema[] = [
  {
    label: "Category",
    name: "categories",
    canAdd: true,
    canDelete: true,
    canMove: true,
    format: "array",
    arrayStyle: "table",
    array: [
      {
        label: "Name",
        name: "name",
        format: "text",
        validate: ["required"],
      },
      {
        label: "TC",
        name: "TC",
        format: "text",
        validate: ["required"],
      },
      {
        label: "SC",
        name: "SC",
        format: "text",
        validate: ["required"],
      },
      {
        label: "EN",
        name: "EN",
        format: "text",
        validate: ["required"],
      },
    ],
  },
  {
    label: "Products",
    name: "products",
    canAdd: true,
    canDelete: true,
    canMove: true,
    format: "array",
    arrayStyle: "table",
    array: [
      {
        label: "Name",
        name: "name",
        format: "text",
        validate: ["required"],
      },
      {
        label: "Category",
        name: "category",
        format: "text",
        validate: ["required"],
      },
      {
        label: "TC",
        name: "TC",
        format: "text",
        validate: ["required"],
      },
      {
        label: "SC",
        name: "SC",
        format: "text",
        validate: ["required"],
      },
      {
        label: "EN",
        name: "EN",
        format: "text",
        validate: ["required"],
      },
    ],
  },
];

export default {
  category,
};
