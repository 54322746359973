import { Typography } from "@mui/material";

const GraphTitle = ({ title = "", offset = 0 }) => {
  return (
    <Typography variant="subtitle1" style={{ paddingTop: "0.5rem", paddingLeft: 40 + offset, width: "100%" }}>
      {title}
    </Typography>
  );
};

export default GraphTitle;
