import { Box, Tooltip, IconButton, ButtonBase } from "@mui/material";
import { PushPin } from "@mui/icons-material";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import StyledIconButton from "IZOArc/LabIZO/Stylizo/StyledIconButton";
import { Accessor, ColorX, store } from "IZOArc/STATIC";
import _ from "lodash";
import { observer } from "mobx-react";
import { Component } from "react";
import { MenuConfig } from "config/config";
import "./Container.css";
import MenuButton from "./_gears/MenuButton";

class Menu extends Component {
  static propTypes = {};

  static defaultProps = {};

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Menu.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  menuButton(caption, path, fafa, reqAuth, reqLevel = 999, submenu = null, disabled = false, id = null) {
    return (
      <MenuButton
        location={this.props.location}
        key={caption}
        caption={caption}
        path={path}
        fafa={fafa}
        reqAuth={reqAuth}
        reqLevel={reqLevel}
        submenu={submenu}
        disabled={disabled}
        mini={store.mini}
        zIndex={50}
        id={id}
      />
    );
  }

  toggleMini() {
    store.toggleMini();
  }

  renderButtons() {
    return _.map(MenuConfig, (o, i) => {
      return this.menuButton(o.caption, o.link, o.faIcon, o.auth, o.level, o.submenu, o.disabled, o.id);
    });
  }

  renderPin() {
    return (
      <ButtonBase sx={{ width: "100%" }} onClick={() => this.toggleMini()} aria-label="label">
        <HStack width={"100%"} justifyContent={"flex-end"}>
          <Box sx={{ width: "40px", color: "primary.main" }}>
            <Tooltip title={store.mini ? "Show Label" : "Hide Label"} aria-label="label" arrow={true} placement="right">
              <PushPin />
            </Tooltip>
          </Box>
        </HStack>
      </ButtonBase>
    );
  }

  render() {
    return (
      <Box className={`menu ${store.mini ? "mini" : ""}`} width="140px" height="100%" bgcolor={ColorX.GetColorCSS("Secondary")} position="fixed" zIndex="4">
        <VStack width="100%" sx={{ paddingTop: "50px", alignItems: "flex-end" }}>
          {this.renderPin()}
          <VStack width="100%" sx={{ paddingTop: "40px" }}>
            {this.renderButtons()}
            <Spacer />
          </VStack>
        </VStack>
      </Box>
    );
  }
}

export default observer(Menu);
