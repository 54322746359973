import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import axios from "axios";
import { Tab, Tabs, Typography, Paper } from "@mui/material";

import { DOMAIN } from "config/config";
import tabs from "./tabs";

import { Accessor, Authority, ColorX, store } from "IZOArc/STATIC";
import { HStack, VStack } from "IZOArc/LabIZO/Stackizo";
import { StyledButton } from "IZOArc/LabIZO/Stylizo";

class ImportConfirm extends Component {
  static propTypes = {
    docID: PropsType.string,
    doc: PropsType.object,
    onQuit: PropsType.func,
    onQuitRefresh: PropsType.func,
    addOns: PropsType.object,
  };

  static defaultProps = {
    docID: "",
    doc: {},
    onQuit: () => {},
    onQuitRefresh: () => {},
    addOns: {},
  };

  constructor() {
    super();
    this.state = {
      selectedTab: 0,
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(ImportConfirm.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  ConfirmImport = {
    onCancel: async () => {
      return await this.ConfirmImport.onSubmit(false);
    },
    onConfirm: async () => {
      return await this.ConfirmImport.onSubmit(true);
    },
    onSubmit: async (proceed) => {
      let { docID, addOns } = this.props;
      let urlPart = "/Tables/FAQ/ConfirmImport";
      let url = DOMAIN + urlPart;

      let payloadOut = {
        data: {
          uuid: docID,
          proceed: proceed,
        },
        JWT: store.user.JWT,
        addOns: addOns,
      };

      try {
        console.log(urlPart, payloadOut);

        store.isLoading(true);
        let res = await axios.post(url, payloadOut);
        store.isLoading(false);

        console.log(urlPart, res.data);

        let { Success, payload } = res.data;
        if (Success === true) {
          this.ConfirmImport.onSuccess(payload, proceed);
        } else {
          this.ConfirmImport.onFail(payload);
        }
      } catch (e) {
        store.isLoading(false);
        this.ConfirmImport.onFail(e);
      }
    },
    onSuccess: (payload, proceed) => {
      let { onQuitRefresh } = this.props;
      store.Alert(payload, proceed ? "success" : "info");
      if (onQuitRefresh) onQuitRefresh();
    },

    onFail: (payload) => {
      let msg = payload.message || "";
      store.Alert(msg, "error");
    },
  };

  onChangeTab = (e, tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  renderTabPanels() {
    let { doc } = this.props;
    let { Summary } = doc;
    let { selectedTab } = this.state;
    return _.map(tabs, (o, i) => {
      return (
        <div key={i} hidden={selectedTab !== i} style={{ width: "100%", height: "100%" }}>
          {o.render(Summary)}
        </div>
      );
    });
  }

  renderTabButtons() {
    let { doc } = this.props;
    let { Summary } = doc;
    return _.map(tabs, (o, i) => {
      if (Authority.IsAccessibleQ(o.reqAuth, o.reqLevel, o.reqFunc)) {
        let label = (
          <HStack gap={5}>
            {o.icon}
            <Typography>{o.label(Summary)}</Typography>
          </HStack>
        );
        return <Tab key={o.label(Summary)} label={label} disabled={o.disabled} style={{ minHeight: 20, minWidth: 200 }} />;
      }
    });
  }

  render() {
    let { doc } = this.props;
    if (!doc) return <div />;
    let { Summary } = doc;
    if (!Summary) return <div />;
    const { error } = Summary;

    let { selectedTab } = this.state;
    let buttonWidth = 150;
    const themeColor = ColorX.GetColorCSS("Primary");

    return (
      <VStack gap={5} alignItems="flex-start" width="100%" height="100%">
        <Paper position="static" style={{ width: "100%" }}>
          <Tabs
            value={selectedTab}
            onChange={this.onChangeTab}
            style={{
              backgroundColor: ColorX.GetColorCSS("TabIndexBG"),
              color: ColorX.GetColorCSS("Edit"),
              minHeight: 20,
              ".MuiTabsIndicator": {
                background: themeColor,
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            {this.renderTabButtons()}
          </Tabs>
        </Paper>
        <Paper style={{ width: "100%", height: "100%", background: "transparent", padding: "5px" }}>{this.renderTabPanels()}</Paper>
        <VStack gap={5} alignItems="flex-end" style={{ width: "100%", height: "fit-content" }}>
          {error && error.length === 0 ? <Typography>{"Confirm to proceed?"}</Typography> : ""}
          <HStack width="100%" justifyContent="flex-end">
            {error && error.length === 0 && (
              <StyledButton
                className={"formizo-h-m"}
                key={1}
                theme={{
                  color: "green",
                  background: "white",
                  boxShadow: ColorX.GetBoxShadowCSS("grey"),
                  width: buttonWidth,
                }}
                onClick={this.ConfirmImport.onConfirm}
              >
                <HStack gap={5}>
                  <i className="fas fa-paper-plane" />
                  <Typography>{"Submit"}</Typography>
                </HStack>
              </StyledButton>
            )}

            <StyledButton
              className={"formizo-h-m"}
              key={2}
              theme={{
                color: "red",
                background: "white",
                boxShadow: ColorX.GetBoxShadowCSS("grey"),
                width: buttonWidth,
              }}
              onClick={this.ConfirmImport.onCancel}
            >
              <HStack gap={5}>
                <i className="fas fa-ban" />
                <Typography>{"Cancel"}</Typography>
              </HStack>
            </StyledButton>
          </HStack>
        </VStack>
      </VStack>
    );
  }
}

export default ImportConfirm;
