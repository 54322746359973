let info = [
  {
    label: "Agent",
    name: "AGENT",
    format: "array",
    arrayStyle: "table",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "",
        format: "text",
        validate: ["required"]
      }
    ]
  },
  {
    label: "Broker",
    name: "BROKER",
    format: "array",
    arrayStyle: "table",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "",
        format: "text",
        validate: ["required"]
      }
    ]
  },
  {
    label: "Dev",
    name: "DEV",
    format: "array",
    arrayStyle: "table",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "",
        format: "text",
        validate: ["required"]
      }
    ]
  },
];

export default {
  info
}