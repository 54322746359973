const Request = {
  DBInfo: "/Tables/FLE/Info",
  List: "/Tables/FLE/List",
  Add: "/Tables/FLE/Add",
  Delete: "/Tables/FLE/Delete",
  Edit: "/Tables/FLE/Edit",
  Export: "/Tables/FLE/Export",
  Import: "/Tables/FLE/Import",
  DeleteBulk: "/Tables/FLE/DeleteBulk",
};

let exports = {
  Request,
};

export default exports;
