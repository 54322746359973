import { Grid } from "@mui/material";
import { DBAnswerSpec } from "@types";
import { ContextId } from "Pages/FLE/components";
import { useFormContext } from "react-hook-form";
import { VStack } from "v2/Components/Layouts";
import { IFLEDoc } from "../interfaces/FLE.interface";

interface IProps {
  disabled?: boolean;
}

export function ContextsTable({ ...props }: IProps) {
  const { control, watch } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const contexts = watch("contexts");
  if (!contexts) return <></>;
  const GridItemProps = {
    item: true,
    xs: 1,
  };
  return (
    <VStack sx={{ width: "100%" }}>
      {contexts.map((context, _i) => {
        return (
          <Grid container key={`context-${_i}`}>
            <Grid {...GridItemProps} xs={2}>
              <ContextId name={`contexts.${_i}.alias.id`} />
            </Grid>
            <Grid {...GridItemProps} xs={1}>
              {" "}
              {context.type}
            </Grid>
            <Grid {...GridItemProps} xs={1}>
              {context.setting.type == "entity" && context.setting.values.map((o) => o.entity).join(", ")}
              {context.setting.type != "entity" && context.setting.type}
            </Grid>
            <Grid {...GridItemProps} xs={8}>
              {context.question["EN"]}
            </Grid>
          </Grid>
        );
      })}
    </VStack>
  );
}
