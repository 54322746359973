import Messenger from "./_gears/Messenger";

const ML_SERVER = "https://robot.mobinology.com:8151";
const DEV_SERVER = "http://localhost:5000";
const tabs = [
  {
    label: "SYVAGPT",
    icon: "",
    reqAuth: "None",
    iconPos: "left",
    noTransform: true,
    render: <Messenger name={"Small Talk"} LiveChatEnabled={true} viaBackend={false} url={process.env.NODE_ENV === "development" ? `${DEV_SERVER}/Talk2VA` : `${ML_SERVER}/smalltalk/Talk2VA`} />,
  },
  {
    label: "JHC QA",
    icon: "",
    reqAuth: "None",
    iconPos: "left",
    noTransform: true,
    render: <Messenger name={"JHC QA"} LiveChatEnabled={true} viaBackend={false} url={process.env.NODE_ENV === "development" ? `${DEV_SERVER}/Talk2VA` : `${ML_SERVER}/bertqa/Talk2VA`} />,
  },

  {
    label: "JHC 問答",
    icon: "",
    reqAuth: "None",
    iconPos: "left",
    noTransform: true,
    render: <Messenger name={"JHC 問答"} LiveChatEnabled={true} viaBackend={false} url={process.env.NODE_ENV === "development" ? `${DEV_SERVER}/Talk2VA` : `${ML_SERVER}/bertqa-tc/Talk2VA`} />,
  },
  // {
  //   label: "SYVA",
  //   icon: "",
  //   reqAuth: "None",
  //   iconPos: "left",
  //   noTransform: true,
  //   render: <Messenger name={"SYVA"} LiveChatEnabled={true} viaBackend={false} />,
  // },
];

export default tabs;
