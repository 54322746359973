import { useEffect, useState } from "react";

import axios from "axios";

import schema from "./schema";
import { DOMAIN } from "config/config";

import Formizo from "IZOArc/LabIZO/Formizo";
import { Env, ErrorX, store } from "IZOArc/STATIC";

const SysDisclaimer = () => {
  const [sysinfo, setSysinfo] = useState(null);
  const getSysInfo = async () => {
    let payloadOut = {
      JWT: store.user.JWT,
    };

    try {
      const { data } = await axios.post(`${DOMAIN}/Config/Disclaimer/Info`, payloadOut);
      if (!data.Success) ErrorX.Handle(data);
      setSysinfo(data.payload);
    } catch (e) {
      store.Alert("Cannot get disclaimer info", "error");
    }
  };
  useEffect(() => {
    getSysInfo();
  }, []);

  const onSubmit = async (data: any) => {
    let link = "/Config/Disclaimer/Edit";
    let url = DOMAIN + link;
    let payloadOut = {
      data: {
        ...data,
      },
      JWT: store.user.JWT,
    };

    try {
      console.log(link, payloadOut);

      store.isLoading(true);
      let { data } = await axios.post(url, payloadOut);
      await getSysInfo();
      store.isLoading(false);
      console.log(link, data);
      if (!data.Success) {
        ErrorX.Handle(data.payload);
        return;
      }

      //updon success
      store.Alert("System Disclaimer updated successfully", "success");
      await Env.GetSysInfo();
    } catch (e) {
      store.Alert("Cannot set system disclaimer", "error");
    }
  };

  const onDeletePermission = async (data: any) => {
    store.Ask("Delete ALL accepted disclaimer?", "Caution: This is irrevertable. This will terminate all ongoing chat session and force all users to reconfirm disclaimer.", deleteAction);
  }

  const deleteAction = async (data: any) => {
    let link = "/Config/Disclaimer/Delete";
    let url = DOMAIN + link;
    let payloadOut = {      
      JWT: store.user.JWT,
    };

    try {
      console.log(link, payloadOut);

      store.isLoading(true);
      let { data } = await axios.post(url, payloadOut);
      await getSysInfo();
      store.isLoading(false);
      console.log(link, data);
      if (!data.Success) {
        ErrorX.Handle(data.payload);
        return;
      }

      //updon success
      store.Alert("Disclaimer status deleted successfully", "success");
      await Env.GetSysInfo();
    } catch (e) {
      store.Alert("Cannot delete system disclaimer", "error");
    }
  }

  return (
    <Formizo
      //
      schema={schema.mwconfig}
      buttons={["Revert", "Submit", "DeletePermission"]}
      onSubmit={onSubmit}
      onCancel={onDeletePermission}
      defaultValue={sysinfo}
    />
  );
};
export default SysDisclaimer;
