import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";
import { Paper, Tab, Tabs, Badge, Autocomplete ,TextField } from "@mui/material";

import FItem from "../FItem";

import { Accessor, Authority } from "IZOArc/STATIC";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import { Typography } from "antd";

import { ColorX } from "IZOArc/STATIC";
class FGAutocompleteTabs extends Component {
  static propTypes = {
    //data
    ischema: PropsType.object.isRequired,
    formValue: PropsType.object,
    addOns: PropsType.object,
  };

  static defaultProps = {
    //data
    ischema: {},
    formValue: {},
    addOns: {},
  };

  constructor() {
    super();
    this.state = {
      selectedTab: 0,
      currentOptions: [],
      isOpen: false,
    };
  }

  componentDidMount() {
    this._setAllStates();
    console.log("enter FGAutos")
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FGAutocompleteTabs.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  getPageSchema = (page) => {
    let { formValue, addOns } = this.props;
    if (_.isFunction(page)) {
      let { selectedTab } = this.state;
      return page(formValue, addOns, selectedTab, this.onChangeTab);
    }
    return page;
  };

  getTabSchema = () => {
    let { ischema, formValue, addOns } = this.props;
    if (_.isFunction(ischema.autos)) {
      return ischema.autos(formValue, addOns);
    }
    return ischema.autos;
  };

  onChangeTab = (e, tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  renderSchema(page) {
    let { ischema, ...other } = this.props;
    let pageSchema = this.getPageSchema(page);
    return _.map(pageSchema, (o, i) => {
      return <FItem key={i} ischema={o} {...other} />;
    });
  }

  renderTabPanels() {
    let { selectedTab } = this.state;
    let tabSchema = this.getTabSchema();
    return _.map(tabSchema, (o, i) => {
      return (
        <div key={i} hidden={selectedTab !== i} style={{ width: "100%" }}>
          {this.renderSchema(o.page)}
        </div>
      );
    });
  }

  renderTabButtons() {
    let { ischema, auth, level } = this.props;
    let tabSchema = this.getTabSchema();
    return _.map(tabSchema, (o, i) => {
      if (Authority.IsAccessible(auth, level, o.reqAuth, o.reqLevel, o.reqFunc)) {
        let label = o.label;
        let icon = o.icon;
        if (o.noTransform) {
          label = <Typography style={{ textTransform: "none" }}>{o.label}</Typography>;
        }
        switch (o.iconPos) {
          case "bottom":
            label = (
              <VStack gap={o.spacing || 1}>
                {label}
                {icon}
              </VStack>
            );
            icon = null;
            break;
          case "top":
            label = (
              <VStack gap={o.spacing || 1}>
                {icon}
                {label}
              </VStack>
            );
            icon = null;
            break;
          case "left":
            label = (
              <HStack gap={o.spacing || 1}>
                {o.alignment === "right" && <Spacer />}
                {icon}
                {label}
                {o.alignment === "left" && <Spacer />}
              </HStack>
            );
            icon = null;
            break;
          case "right":
            label = (
              <HStack gap={o.spacing || 1}>
                {o.alignment === "right" && <Spacer />}
                {label}
                {icon}
                {o.alignment === "left" && <Spacer />}
              </HStack>
            );
            icon = null;
            break;
          default:
            break;
        }

        //error in tab component
        const { formError, formValue, addOns } = this.props;
        let error = false;
        if (_.isFunction(o.error)) {
          error = o.error(formValue, addOns, { formError });
          if (error) {
            label = (
              <Badge badgeContent={"!"} color="error">
                {label}
              </Badge>
            );
          }
        }

        return <Tab key={i} label={label} icon={icon} disabled={o.disabled} sx={{ minHeight: ischema.height, minWidth: ischema.width, color: error ? ColorX.GetColorCSS("Error") : "primary.main" }} />;
      }
    });
  }

render() {
  let { selectedTab } = this.state;
  let { ischema } = this.props;
  if (!ischema) return null;

  const tabSchema = this.getTabSchema();

  return (
    <VStack width="100%">
      <Paper position="static" style={{ width: "100%" }}>
        <Autocomplete
        open={this.state.isOpen}
        onOpen={() => this.setState({ isOpen: true })}
        onClose={() => this.setState({ isOpen: false })}
        onFocus={() => {
          this.setState({ isOpen: true });
        }}
          value={tabSchema[selectedTab]}
          onInputChange={(event, value, reason) => {
            if (reason === 'input') {
              const filteredOptions = tabSchema.filter((option) => option.label.includes(value));
              this.setState({ currentOptions: filteredOptions });
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const { currentOptions } = this.state;
              if (currentOptions.length > 0) {
                const newTabIndex = tabSchema.indexOf(currentOptions[0]);
                this.setState({ selectedTab: newTabIndex });
              }
            }
          }}
          onChange={(e, newValue) => {
            const newTabIndex = tabSchema.indexOf(newValue);
            this.setState({ selectedTab: newTabIndex });
          }}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option.label}
          options={tabSchema}
          renderInput={(params) => <TextField {...params} label="Product ID" />}

        />
      </Paper>
      <Paper style={{ width: "100%", background: "transparent", padding: "0 5px" }}>
        {this.renderTabPanels()}
      </Paper>
    </VStack>
  );
}

}

export default FGAutocompleteTabs;
