import React, { Component } from "react";

import _ from "lodash";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";

import tabs from "./tabs";

import { Accessor, Authority } from "IZOArc/STATIC";
import { VStack, HStack, Spacer } from "IZOArc/LabIZO/Stackizo";
import { Denied } from "IZOArc/Fallback";
import { ColorX } from "IZOArc/STATIC";

/** 
tabs = [
  {
    label: String,
    icon: String | JSX,
    reqAuth: String,
    render: JSX,
    iconPos: "top" | "left" | "right" | "bottom",
    noTransform: Boolean | false,
    spacing: Number | 5,
    alignment: "center" | "left" | "right",
    width: Number | 200,
    height: Number | 20
  }
];
*/

/**
 * @augments {Component<Props, State>}
 */
class System extends Component {
  static propTypes = {};

  static defaultProps = {
    preLoad: false,
  };

  constructor() {
    super();
    this.state = {
      selectedTab: 0,
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(System.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onChangeTab = (e, tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  renderTabPanels() {
    const { preLoad } = this.props;
    let { selectedTab, addOns } = this.state;
    let tabsAllowed = tabs.filter((o) => Authority.IsAccessibleQ(o.reqAuth, o.reqLevel, o.reqFunc));

    return _.map(tabsAllowed, (o, i) => {
      if (!preLoad && selectedTab !== i) return <Box key={i} />;

      return (
        <Box key={i} hidden={selectedTab !== i} style={{ width: "100%", height: "100%" }}>
          {_.isFunction(o.render) ? o.render(addOns) : o.render}
        </Box>
      );
    });
  }

  renderTabButtons() {
    let tabsAllowed = tabs.filter((o) => Authority.IsAccessibleQ(o.reqAuth, o.reqLevel, o.reqFunc));

    return _.map(tabsAllowed, (o, i) => {
      let label = o.label;
      let icon = o.icon;
      if (o.noTransform) {
        label = <Typography style={{ textTransform: "none" }}>{o.label}</Typography>;
      }
      switch (o.iconPos) {
        case "top":
        default:
          break;
        case "bottom":
          label = (
            <VStack gap={o.spacing || 5}>
              {label}
              {icon}
            </VStack>
          );
          icon = null;
          break;
        case "left":
          label = (
            <HStack gap={o.spacing || 5}>
              {o.alignment === "right" && <Spacer />}
              {icon}
              {label}
              {o.alignment === "left" && <Spacer />}
            </HStack>
          );
          icon = null;
          break;
        case "right":
          label = (
            <HStack gap={o.spacing || 5}>
              {o.alignment === "right" && <Spacer />}
              {label}
              {icon}
              {o.alignment === "left" && <Spacer />}
            </HStack>
          );
          icon = null;
          break;
      }
      return <Tab key={i} label={label} icon={icon} disabled={o.disabled} style={{ minHeight: o.height || 20, minWidth: o.width || 200 }} />;
    });
  }

  render() {
    let { selectedTab } = this.state;
    if (!Authority.IsAccessibleQ("System")) return <Denied />;

    const themeColor = ColorX.GetColorCSS("Primary");
    return (
      <VStack width="100%" height="100%">
        <Paper position="static" style={{ width: "100%" }}>
          <Tabs
            value={selectedTab}
            onChange={this.onChangeTab}
            style={{ backgroundColor: ColorX.GetColorCSS("TabIndexBG"), color: themeColor, minHeight: 20 }}
            variant="scrollable"
            scrollButtons="auto"
          >
            {this.renderTabButtons()}
          </Tabs>
        </Paper>
        <Paper style={{ width: "100%", height: "100%", background: "transparent", padding: "5px" }}>{this.renderTabPanels()}</Paper>
      </VStack>
    );
  }
}

export default System;
