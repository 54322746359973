import { useEffect, useState } from "react";

import { Backdrop, Box, CircularProgress, Snackbar, Alert } from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "axios";
import htmlParser from "html-react-parser";
import _ from "lodash";
import { observer } from "mobx-react";

import { ChatDOMAIN, DOMAIN, hasContainer, loginSys } from "config/config";
import "./Container.css";
import Footer from "./Footer";
import Menu from "./Menu";
import NavBar from "./NavBar";

import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import { SnackAlert, StyledButton, StyledIconButton, StyledLinearProgress } from "IZOArc/LabIZO/Stylizo";
import { ColorX, ErrorX, store } from "IZOArc/STATIC";

import { useLocation, useNavigate } from "react-router-dom";

function Container({ children }) {
  let navigate = useNavigate();
  let location = useLocation();
  const [snackOpen, setSnackOpen] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [buttonWidth] = useState("100px");
  const [show] = useState(true);

  useEffect(() => {
    GetServerDetail();
    GetChatbotDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (snackOpen !== !_.isEmpty(store.alert)) setSnackOpen(!_.isEmpty(store.alert));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.alert]);

  useEffect(() => {
    if (loadingOpen !== store.loading) setLoadingOpen(store.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.loading]);

  useEffect(() => {
    if (dialogOpen !== !_.isEmpty(store.ask)) setDialogOpen(!_.isEmpty(store.ask));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.ask]);

  const AutoLogin = () => {
    setTimeout(() => {
      navigate("/Dashboard");
      store.Alert("Auto Login", "success");
    }, 1000);
  };

  const AutoLogout = () => {
    setTimeout(() => {
      store.Alert("Unauthorized", "warning");
      store.clearUser();
      store.clearProductItems();
      navigate("/");
    }, 1000);
  };

  if (loginSys) {
    let isPublic = (location && location.pathname) === "/";
    let isChatbot = (location && location.pathname) === "/Chatbot";
    let isTest = false && location && location.pathname && location.pathname.startsWith("/Test");

    if (loginSys && !isPublic && !isChatbot && !isTest && !store.isLoggedIn()) {
      AutoLogout();
    }

    if (loginSys && isPublic && store.isLoggedIn() && store.isInitialized()) {
      AutoLogin();
    }
  }

  const GetServerDetail = async () => {
    let url = DOMAIN + "/Base/Name/Get";

    try {
      let rtn = await axios.post(url);
      if (rtn.data.Success === true) {
        store.setServer(rtn.data.payload);
      } else {
        ErrorX.Handle(rtn.data);
      }
    } catch {
      let isPublic = (location && location.pathname) === "/";
      let isTest = false && location && location.pathname && location.pathname.startsWith("/Test/");

      store.Alert("Cannot connect to server", "error");
      if (!isPublic && !isTest) {
        AutoLogout();
      }
    }
  };

  const GetChatbotDetail = async () => {
    let url = ChatDOMAIN + "/Details";

    try {
      const { data } = await axios.get(url);
      if (!data.Success) return ErrorX.Handle(data);

      store.setChatbot(data.payload);
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  const closeSnack = () => {
    setSnackOpen(false);
    setTimeout(() => {
      store.clearAlert();
    }, 1000);
  };

  const _onOK = async () => {
    if (store.ask.onConfirm && _.isFunction(store.ask.onConfirm)) {
      store.SetAskLoading(true);
      let res = await store.ask.onConfirm();
      if (store.ask.autoClose) {
        store.SetAskLoading(false);
        if (!res || res.Success === undefined || (res && res.Success)) {
          store.clearAsk();
        }
      }
    } else {
      store.clearAsk();
    }
  };

  const _onCancel = async () => {
    if (store.ask.onCancel && _.isFunction(store.ask.onCancel)) {
      store.SetAskLoading(true);
      await store.ask.onCancel();
      store.SetAskLoading(false);
    }
    store.clearAsk();
  };

  function renderButtons() {
    let buttonsJSX = {
      OK: (
        <StyledButton
          className={"formizo-h-m"}
          id="container-popup-OK"
          key={0}
          theme={{
            color: "green",
            background: "white",
            width: buttonWidth,
            disabled: {
              color: ColorX.GetColorCSS("grey"),
              background: ColorX.GetColorCSS("grey"),
            },
          }}
          onClick={_onOK}
          disabled={store.ask.loading}
        >
          <i className="fas fa-check" />
          <div className="formizo-h-m">OK</div>
        </StyledButton>
      ),
      Cancel: (
        <StyledButton
          className={"formizo-h-m"}
          id="container-popup-Cancel"
          key={2}
          theme={{
            color: "red",
            background: "white",
            width: buttonWidth,
            disabled: {
              color: ColorX.GetColorCSS("grey"),
              background: ColorX.GetColorCSS("grey"),
            },
          }}
          onClick={_onCancel}
          disabled={store.ask.loading}
        >
          <i className="fas fa-ban" />
          <div className="formizo-h-m">Cancel</div>
        </StyledButton>
      ),
    };

    return _.map(store.ask.buttons, (o, i) => {
      if (_.isString(o) && buttonsJSX[o]) return buttonsJSX[o];
      else {
        return o;
      }
    });
  }

  function renderDialog() {
    let title = store.ask.title;
    if (_.isString(title)) {
      title = htmlParser(title);
    }
    let message = store.ask.message;
    if (_.isString(message)) {
      message = htmlParser(message);
    }
    return (
      <Box bgcolor={ColorX.GetColorCSS("BackDropContainerBG")} padding={2} borderRadius={5} boxShadow={"0px 0px 2px 2px " + ColorX.GetColorCSS("Primary", 0.2)} minWidth={250}>
        <VStack width="100%">
          <HStack>
            <Box fontSize="large" fontWeight="bold" textAlign="left" width="100%" marginBottom={1}>
              {title}
            </Box>
            <Spacer />
            {store.ask.showCloseIcon && (
              <StyledIconButton onClick={() => store.clearAsk()} theme={{ label: ColorX.GetColorCSS("Primary"), width: 24 }}>
                <Close />
              </StyledIconButton>
            )}
          </HStack>
          <Box fontSize="normal" textAlign="left" width="100%" marginY={1}>
            {message}
          </Box>
          <StyledLinearProgress
            theme={{
              bar: ColorX.GetColorCSS("Primary", store.ask.loading ? 0.5 : 0.0),
              background: ColorX.GetColorCSS("Primary", store.ask.loading ? 0.2 : 0.0),
            }}
          />
          {store.ask.inner && store.ask.inner(store.ask.loading)}
          {store.ask.buttons && store.ask.buttons.length > 0 && <HStack marginTop={2}>{renderButtons()}</HStack>}
        </VStack>
      </Box>
    );
  }

  const SnackDuration = () => {
    if (!store.alert || !store.alert.severity) return 3000;
    switch (store.alert.severity) {
      default:
      case "success":
        return 3000;
      case "warning":
      case "warning":
        return 6000;
      case "info":
        return 4000;
      case "error":
        return 6000;
    }
  };

  let isPublic = (location && location.pathname) === "/";
  let isChatbot = (location && location.pathname) === "/Chatbot";
  let isTest = (location && location.pathname).startsWith("/Test/");
  let isContained = hasContainer && !isPublic && !isTest && !isChatbot && store.isLoggedIn() && show;

  return (
    <Box className="container" height="100%">
      {isContained && <Menu location={location} />}
      {isContained && <NavBar />}

      <Box className="main-content" paddingTop={isContained ? "30px" : 0} paddingLeft={isContained ? "40px" : 0} paddingBottom={"5px"} height="100vh">
        {children}
      </Box>

      {store?.alert && (
        <Snackbar open={snackOpen} autoHideDuration={SnackDuration()} onClose={closeSnack} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert onClose={closeSnack} severity={store?.alert && store?.alert?.severity}>
            {store?.alert && store.alert?.message}
          </Alert>
        </Snackbar>
      )}

      <Backdrop open={loadingOpen} sx={{ zIndex: 500, color: ColorX.GetColorCSS("Primary") }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop open={dialogOpen} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "main.theme.primary" }}>
        {renderDialog()}
      </Backdrop>
      {isContained && <Footer />}
    </Box>
  );
}

export default observer(Container);
