import React, { Component } from "react";

import axios from "axios";

import schema from "./schema";
import { DOMAIN } from "config/config";

import Formizo from "IZOArc/LabIZO/Formizo";
import { HStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, ErrorX, store } from "IZOArc/STATIC";
class SysChatbot extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      mwSettingDoc: {},
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this._getMWSettings();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(SysChatbot.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  Settings = {
    onSubmit: async (data) => {
      let link = "/Config/Middleware/Edit";
      let url = DOMAIN + link;
      let payloadOut = {
        data: {
          ...data,
        },
        JWT: store.user.JWT,
      };

      try {
        console.log(link, payloadOut);

        store.isLoading(true);
        let res = await axios.post(url, payloadOut);
        store.isLoading(false);

        console.log(link, res.data);
        let { Success } = res.data;

        if (Success) {
          this.Settings.onSuccess(res);
        } else {
          this.Settings.onFail("Middleware Config update fails.");
        }
      } catch (e) {
        this.Settings.onFail(ErrorX.Handle(e));
      }
    },

    onSuccess: (res) => {
      store.Alert("Middleware Config updated successfully", "success");
      this._getMWSettings();
    },

    onFail: (msg) => {
      store.isLoading(false);
      store.Alert(msg, "error");
    },
  };

  _getMWSettings = async () => {
    let link = "/Config/Middleware/Info";
    let url = DOMAIN + link;
    let payloadOut = {
      JWT: store.user.JWT,
    };

    try {
      console.log(link, payloadOut);

      store.isLoading(true);
      let res = await axios.post(url, payloadOut);
      store.isLoading(false);

      console.log(link, res.data);

      let { Success, payload } = res.data;
      if (Success) {
        this.setState({
          mwSettingDoc: payload,
        });
      } else {
        store.Alert(payload, "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  renderSettingForm() {
    let { mwSettingDoc } = this.state;
    return <Formizo height="100%" schema={schema.mwconfig} buttons={["Revert", "Submit"]} onSubmit={this.Settings.onSubmit} defaultValue={mwSettingDoc} />;
  }

  render() {
    return <HStack height="100%">{this.renderSettingForm()}</HStack>;
  }
}

export default SysChatbot;
