import React, { Component } from "react";

import axios from "axios";
import { Typography } from "@mui/material";
import { SyncAlt } from "@mui/icons-material";

import { DOMAIN } from "config/config";

import { Accessor, ColorX, ErrorX, store } from "IZOArc/STATIC";
import { HStack, VStack } from "IZOArc/LabIZO/Stackizo";
import { StyledButton } from "IZOArc/LabIZO/Stylizo";

class SysNLP extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates(() => {});
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(SysNLP.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  _SyncDialogFlow = {
    onClick: () => {
      store.Ask("Sync Dialog Flow", "Sync the EN flow to other languages?", this._SyncDialogFlow.onSubmit);
    },
    onSubmit: async () => {
      let url = DOMAIN + "/Data/Watsons/SyncDialogFlow";
      let payloadOut = {
        JWT: store.user.JWT,
        data: {},
      };

      try {
        let res = await axios.post(url, payloadOut);
        console.log(res);
        let { Success } = res.data;
        if (Success === true) {
          store.Alert("Sync Successful.", "success");
        } else {
          this._SyncDialogFlow.onError(res.data);
        }
      } catch (e) {
        this._SyncDialogFlow.onError(e);
      }
    },
    onError: (e) => {
      ErrorX.Handle(e);
    },
  };

  renderSyncDialogFlow() {
    return (
      <HStack justifyContent="flex-start" gap={1}>
        <StyledButton
          onClick={this._SyncDialogFlow.onClick}
          theme={{
            width: 200,
            background: ColorX.GetColorCSS("Decorate1"),
            color: ColorX.GetColorCSS("ButtonText1"),
            hover: {
              background: ColorX.GetColorCSS("Decorate1", 0.9),
              color: ColorX.GetColorCSS("ButtonText1"),
            },
          }}
        >
          <HStack gap={1} width="100%">
            <SyncAlt />
            <Typography>Sync Dialog Flow</Typography>
          </HStack>
        </StyledButton>
      </HStack>
    );
  }

  renderOperations() {
    return (
      <VStack alignItems="flex-start" gap={5}>
        {this.renderSyncDialogFlow()}
      </VStack>
    );
  }

  render() {
    return <HStack justifyContent="flex-start">{this.renderOperations()}</HStack>;
  }
}

export default SysNLP;
