import { forwardRef, Ref } from "react";
import { VStack } from "v2/Components/Layouts";

const LegacyVStack = forwardRef((props: any, ref: Ref<HTMLDivElement>) => {
  const { gap = 0, height = "100%", ...other } = props;
  return <VStack gap={gap} height={height} justifyContent={"flex-start"} {...other} ref={ref} />;
});

LegacyVStack.displayName = "LegacyVStack";

export default LegacyVStack;
