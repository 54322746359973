import { autorun, makeAutoObservable, runInAction, set, toJS } from "mobx";
import BrowserX from "./BrowserX";

class AppStore {
  browser = "";
  user = {
    authority: null,
    level: null,
    JWT: null,
  };
  server = {
    backendVersion: null,
  };
  chatbot = {
    chatbotVersion: null,
  };
  productItems = {
    items: null,
  };
  alert = {};
  loading = false;
  initialized = false;
  ask = {};
  mini = true;
  sysInfo = {
    initialized: false,
    Language: { default: "EN", available: [{ display: "English", value: "EN" }] },
  };

  constructor() {
    makeAutoObservable(this);

    let firstRun = true;

    autorun(() => {
      // on load check if there's an existing store on
      // localStorage and extend the store
      if (firstRun) {
        let existingStore = window.localStorage.getItem("store");

        if (existingStore) {
          set(this, JSON.parse(existingStore));
        }
      }

      // from then on serialize and save to localStorage
      let serializedThis = toJS(this);
      window.localStorage.setItem("store", JSON.stringify(serializedThis));
    });

    firstRun = false;
  }

  setBrowser() {
    runInAction(() => {
      let browser = BrowserX.getBrowser();
      this.browser = browser;
    });
  }

  setUser(user) {
    runInAction(() => {
      this.user = user;
    });
  }

  clearUser() {
    runInAction(() => {
      this.user = {};
    });
  }

  setServer(server) {
    runInAction(() => {
      this.server = server;
    });
  }
  setChatbot(payload) {
    runInAction(() => {
      this.chatbot = payload;
    });
  }

  setProductItems(payload) {
    runInAction(() => {
      this.productItems = {
        items: payload,
      };
    });
  }

  clearProductItems() {
    runInAction(() => {
      this.productItems.items = null;
    });
  }

  Alert(message, severity = "info") {
    runInAction(() => {
      this.alert = {
        message,
        severity,
      };
    });
  }

  Ask(title, message = "", onConfirm, onCancel, autoClose = true, buttons = ["OK", "Cancel"]) {
    runInAction(() => {
      this.ask = {
        title,
        message,
        onConfirm,
        onCancel,
        buttons: buttons,
        showCloseIcon: false,
        loading: false,
        autoClose: autoClose,
      };
    });
  }

  SetAskLoading(f) {
    runInAction(() => {
      this.ask.loading = f;
    });
  }

  Form(title, message = "", inner = null, onConfirm = null, onCancel = null) {
    runInAction(() => {
      this.ask = {
        title,
        message,
        onConfirm,
        onCancel,
        inner,
        buttons: [],
        showCloseIcon: true,
      };
    });
  }

  Pop(title, message = "", onConfirm = null) {
    runInAction(() => {
      this.ask = {
        title,
        message,
        onConfirm,
        buttons: ["OK"],
      };
    });
  }

  clearAlert() {
    runInAction(() => {
      this.alert = {};
    });
  }

  clearAsk() {
    runInAction(() => {
      this.ask = {};
    });
  }

  isLoggedIn() {
    return this.user.JWT;
  }

  isLoading(f) {
    runInAction(() => {
      this.loading = f;
    });
  }

  setInitialized(f) {
    runInAction(() => {
      this.initialized = f;
    });
  }

  isInitialized() {
    return this.initialized;
  }

  toggleMini() {
    runInAction(() => {
      this.mini = !this.mini;
    });
  }

  setSysInfo(payload) {
    runInAction(() => {
      this.sysInfo.initialized = true;
      this.sysInfo = { ...this.sysInfo, ...payload };
    });
  }
}

const store = new AppStore();
export default store;
