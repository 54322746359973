import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";

import GraphTitle from "../Graphs/GraphTitle";
import "./Usage.scss";

import { VStack } from "IZOArc/LabIZO/Stackizo";
import { ColorX } from "IZOArc/STATIC";

const Usage = ({ title, data }) => {
  const renderLine = () => {
    if (data && data.DailyConv && data.DailyConv.length > 0) {
      return (
        <ResponsiveContainer className='LineChartContainer'>
          <LineChart data={data.DailyConv}>
            <Line yAxisId='left' type='monotone' dataKey='count' stroke='#8884d8' activeDot={{ r: 3 }} />
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis yAxisId='left' />
            <Tooltip isAnimationActive={false} />
          </LineChart>
        </ResponsiveContainer>
      );
    }
    return <div />;
  };
  return (
    <VStack width='100%' className='Usage' style={{ background: ColorX.GetColorCSS("GraphBG") }}>
      <GraphTitle title={title} />
      {renderLine()}
    </VStack>
  );
};

export default Usage;
