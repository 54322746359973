import { Component } from "react";

import _ from "lodash";
import axios from "axios";
import { Grid } from "@mui/material";

import schema from "./schema";
import ScoreCard from "./ScoreCard/ScoreCard";
import ScoreCardVar from "./ScoreCard/ScoreCardVar";

import "./Dashboard.scss";

import { DOMAIN } from "config/config";
import { Accessor, Authority, store, ErrorX } from "IZOArc/STATIC";

import moment from "moment";
import DashboardHook from "./DashboardHook";

class Dashboard extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super();

    this.state = {
      data: {
        DailyConv: [],
        Overview: [],
        IntentAccuracy: [],
        IntentDistribution: [],
        CustomerBehavior: [],
      },
      base: {
        title: "",
        schema: schema,
        titles: {
          Usage: "USAGE OVERVIEW",
          Performance: "PERFORMANCE",
          CutomerBehaviour: "CUSTOMER BEHAVIOUR",
        },
        graphTitles: {
          DailyCov: "DAILY CONVERSATION",
          IntentAcc: "ACCURACY",
          IntentDis: "Top 10 FAQ DISTRIBUTION",
        },
        exportDoc: "Sunlife Chatbot - Analytics Report ",
        url: {
          Info: "/Data/Analytics/Dashboard",
          ReportExporter: "/Data/Analytics/ReportExporter",
          ReportFetchStatus: "/Data/Analytics/FetchReportStatus", // TODO: check and implement
          ReportDownloader: "/Data/Analytics/ReportDownload",
        },
      },
      dateRange: {
        from: moment().startOf("month"),
        to: moment(),
      },
    };
  }

  componentDidMount() {
    Authority.Require("Dashboard");
    this._setAllStates();
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Dashboard.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  fetchData = async () => {
    let { base, dateRange } = this.state;
    let url = DOMAIN + base.url.Info;
    let payload = {
      JWT: store.user.JWT,
      DateRange: dateRange,
    };
    let res = await axios.post(url, payload);

    if (res.data.Success) {
      let data = res.data.payload;
      this.setState((state, props) => ({
        data: data,
      }));
    } else {
      ErrorX.Handle(res.data);
    }
  };

  renderScoreCards = (data) => {
    let scorecard_items = _.map(data, (o, i) => {
      if (i === 0 || i === 1) {
        return (
          <Grid item xs={6} lg={6} key={i} style={{ display: "flex" }}>
            <ScoreCard key={i} title={o.title} values={o.values} remarks={o.remarks}></ScoreCard>
          </Grid>
        );
      } else {
        return (
          <Grid item xs={6} lg={6} key={i} height={"100%"}>
            <ScoreCardVar key={i} title={o.title} values={o.values} remarks={o.remarks}></ScoreCardVar>
          </Grid>
        );
      }
    });
    return (
      <div className={"ScoreCards"} height={"100%"}>
        <Grid container gap={1}>
          {scorecard_items}
        </Grid>
      </div>
    );
  };

  onChangeDateRange = (date) => {
    if (!date || date.length !== 2) return;
    this.setState(
      {
        dateRange: { from: date[0], to: date[1].endOf("day") },
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    let { base, dateRange, data } = this.state;
    return <DashboardHook base={base} data={data} dateRange={dateRange} onChangeDateRange={this.onChangeDateRange} fetchData={this.fetchData} />;
  }
}

export default Dashboard;
