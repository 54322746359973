import _ from "lodash";
import { Typography } from "@mui/material";
import { Cancel, CheckCircle, Help } from "@mui/icons-material";

import { ColorX } from "IZOArc/STATIC";
import { HStack } from "IZOArc/LabIZO/Stackizo";

const Summary = ({ data }) => {
  let pending = 0;
  let correct = 0;
  let inCorrect = 0;

  if (data && data.length > 0) {
    pending = _.filter(data, (o) => o && !o.After.Tag.tagged && !o.After.Tag.preTagged && !o.Input.isGarbage).length;
    correct = _.filter(data, (o) => o && (o.After.Tag.tagged || o.After.Tag.preTagged) && o.After.Tag.passed === true).length;
    inCorrect = _.filter(data, (o) => o && (o.After.Tag.tagged || o.After.Tag.preTagged) && o.After.Tag.passed === false).length;
  }

  return (
    <HStack gap={1}>
      <Help style={{ color: ColorX.GetColorCSS("Caution") }} />
      <Typography key="pending">{pending}</Typography>
      <CheckCircle style={{ color: ColorX.GetColorCSS("Approval") }} />
      <Typography key="correct">{correct}</Typography>
      <Cancel style={{ color: ColorX.GetColorCSS("Cancel") }} />
      <Typography key="incorrect">{inCorrect}</Typography>
    </HStack>
  );
};
export default Summary;
