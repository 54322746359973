import React, { Component } from 'react';
import PropsType from 'prop-types';
import htmlParser from 'html-react-parser';
import ZTagParser from './ZCMHTMLTags/ZTagParser';

import _ from 'lodash';

import "./ZCMsg.css";

class ZCMText extends Component {

  static propTypes = {
    text: PropsType.string,
  }

  static defaultProps = {
    text: ""
  }

  constructor(){
    super();
    this.state = {
      hide: true
    };
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  ReplaceSpecialTags = (text) => {
    let parsed = _.isString(text) ? htmlParser(text) : text;
    let {cssPrefix, HTMLSpecialTagParser, iaddOns} = this.state;

    try{
      if(HTMLSpecialTagParser){
        return HTMLSpecialTagParser(cssPrefix, parsed, iaddOns);
      }else{
        return ZTagParser.Parse(cssPrefix, parsed, iaddOns);
      }
    }catch{
      return text;
    }
  }

  setHide = (f) => {
    this.setState({
      hide: f
    });
  }

  renderReadMore(){
    let {cssp, readMoreCaption} = this.state;
    return (
      <div key="ReadMore" className={cssp + " msg-text-readmore"} onClick={() => this.setHide(false)}>
        {readMoreCaption}
      </div>
    );
  }

  renderReadLess(){
    let {cssp, readLessCaption} = this.state;
    return (
      <div key="ReadLess" className={cssp + " msg-text-readmore"} onClick={() => this.setHide(true)}>
        {readLessCaption}
      </div>
    );
  }

  renderText(text){
    let {cssp, HTMLEnabled, hideLongAnswer, longAnswerLength,
      revertReadMore, hide} = this.state;
    
    let rtn = [];
  
    if(HTMLEnabled){
      let blocks = this.ReplaceSpecialTags(text);
      
      if(hideLongAnswer){
        if(!Array.isArray(blocks)) {
          blocks = [blocks];
        }
        
        let length = 0;
        let short = false;
        _.map(blocks, (o, i) => {

          if(o.props && o.props.children){
            if(_.isString(o.props.children)){
              length += o.props.children.length;
            }
          }else{
            if(_.isString(o)){
              length += o.length;
            }
          }

          if(length < longAnswerLength){
            if(i === blocks.length - 1){
              short = true;
            }
            rtn.push(o);
          }else{
            if(!hide){
              rtn.push(o);
            }
          }

        });

        if(!short){
          if(hide){
            rtn.push(
              this.renderReadMore()
            );
          }else if(!hide && revertReadMore){
            rtn.push(
              this.renderReadLess()
            );
          }
        }
      }else{

        if(!Array.isArray(blocks)) {
          blocks = [blocks];
        }

        _.map(blocks, (o, i) => {
          rtn.push(o);
        });
      }
    }else{
      rtn = text;
      
      if(hideLongAnswer){
        if(text.length > longAnswerLength){
          let showText = text.substring(0, longAnswerLength);
          let hideText = text.substring(longAnswerLength);

          rtn = [
            <div className={cssp + " msg-text-box"}>
              <div key="showtext" className={cssp + " msg-text-show"}>
                {showText}
              </div>
              {
                !hide &&
                <div key="hidetext" className={cssp + " msg-text-hide"}>
                  {hideText}
                </div>
              }
              {
                hide &&
                this.renderReadMore()
              }
              {
                !hide && revertReadMore &&
                this.renderReadLess()
              }
            </div>
          ];
        }
      }
    }

    return rtn;

  }

  render(){
    let { cssp, text } = this.state;
    return (
      <div className={cssp + " msg-text"}>
        {this.renderText(text)}
      </div>
    )
  }
}

export default ZCMText;