import { DBAnswerSpec } from "@types";
import { IAllFLEAnswers, IFLEDoc, TFLEContext } from "Pages/FLE/interfaces/FLE.interface";
import { IUIEntity } from "Pages/FLE/interfaces/IUIEntity";
import { useFormContext, Controller } from "react-hook-form";
import { EntitySettings } from "../EntitySettings";
import { SystemSettings } from "../SystemSettings";

interface IProps {
  index: number;
  entities: IUIEntity[];
  name: `contexts.${number}`;
  disabled?: boolean;
}

export function ContextSettings({ index, entities, name, ...props }: IProps) {
  const { watch } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  const contextType = watch(`${name}.type`);

  if (!contextType) return <></>;
  switch (contextType) {
    case "system":
      return <SystemSettings name={`${name}.setting`} {...props} />;
    case "entity":
      return <EntitySettings name={`${name}.setting`} entities={entities} contextIndex={index} {...props} />;
    default:
      return <></>;
  }
}
