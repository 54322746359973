import { useEffect, useState } from "react";

import axios from "axios";

import schema from "./schema";
import { DOMAIN } from "config/config";

import Formizo from "IZOArc/LabIZO/Formizo";
import { Env, ErrorX, store } from "IZOArc/STATIC";

const SysProductPermission = () => {
  const [sysinfo, setSysinfo] = useState(null);
  const getSysInfo = async () => {
    let payloadOut = {
      JWT: store.user.JWT,
    };

    try {
      const { data } = await axios.post(`${DOMAIN}/Config/ProductPermission/Info`, payloadOut);
      if (!data.Success) ErrorX.Handle(data);
      setSysinfo(data.payload);
    } catch (e) {
      store.Alert("Cannot get system info", "error");
    }
  };
  useEffect(() => {
    getSysInfo();
  }, []);

  const onSubmit = async (data: any) => {
    let link = "/Config/ProductPermission/Edit";
    let url = DOMAIN + link;
    let payloadOut = {
      data: {
        ...data,
      },
      JWT: store.user.JWT,
    };

    try {
      console.log(link, payloadOut);

      store.isLoading(true);
      let { data } = await axios.post(url, payloadOut);
      await getSysInfo();
      store.isLoading(false);
      console.log(link, data);
      if (!data.Success) {
        ErrorX.Handle(data.payload);
        return;
      }

      //updon success
      store.Alert("Product Permission Config updated successfully", "success");
      await Env.GetSysInfo();
    } catch (e) {
      store.Alert("Cannot set Product Permission", "error");
    }
  };

  return (
    <Formizo
      //
      schema={schema.info}
      buttons={["Revert", "Submit"]}
      onSubmit={onSubmit}
      defaultValue={sysinfo}
    />
  );
};
export default SysProductPermission;
