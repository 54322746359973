import React, { Component } from 'react';
import PropsType from 'prop-types';

import "./ZCInput.css";

class ZCInput extends Component {

  static propTypes = {
    _onTextChange: PropsType.func,
    _onSend: PropsType.func
  }

  static defaultProps = {
    _onTextChange: () => {},
    _onSend: () => {}
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  onKeyDown = (e) => {
    let {input, pressEnterToSend} = this.state;
    if(pressEnterToSend && e.keyCode === 13 && e.shiftKey === false){
      this.onSend({ text: input && input.trim() });
      e.preventDefault();
    }
    return false;
  }

  onSend = (o) => {
    let {input, _onSend, available} = this.state;
    if(!available || input === undefined || input.trim() === "") {return;}
    _onSend(o);
  }

  renderSendBtn = () => {
    return (
      <i className="fas fa-paper-plane"/>
    );
  }

  renderInputBox(){
    let {cssp, lang, sendBtn, input, inputPlaceHolder, _onInputChange, onInputRenderAddOns, available} = this.state;
    return (
      <div className={cssp + " inputbox"}>
        {onInputRenderAddOns && onInputRenderAddOns()}
        <div className={cssp + " inputfield " + lang}>
          <textarea
            ref={this.textareaRef}
            onKeyDown={e => this.onKeyDown(e)}
            onChange={e => _onInputChange(e.target.value)}
            rows="1"
            placeholder={inputPlaceHolder}
            value={input || ""}
            disabled={!available}
            />
        </div>
        <div 
          className={cssp + " inputsend"} 
          onClick={() => { this.onSend({ text: input && input.trim() }); }}>
          {sendBtn? sendBtn() : this.renderSendBtn()}
        </div>
      </div>
    )
  }

  render(){
    let {cssp, menuBtn} = this.state;
    return (
      <div className={cssp + " input"}>
        {menuBtn && menuBtn()}
        {this.renderInputBox()}
      </div>
    );
  }

}

export default ZCInput;