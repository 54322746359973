const Request = {
  DBInfo: "/Tables/FileHandler/Info",
  List: "/Tables/FileHandler/List",
  Add: "/Tables/FileHandler/Add",
  Delete: "/Tables/FileHandler/Delete",
  Edit: "/Tables/FileHandler/Edit",
  Export: "/Tables/FileHandler/Export",
  Import: "/Tables/FileHandler/Import",
  DeleteBulk: "/Tables/FileHandler/DeleteBulk",
};

let exports = {
  Request
};

export default exports;