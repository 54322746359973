const Request = {
  DBInfo: "/Tables/FAQ/Info",
  List: "/Tables/FAQ/List",
  Add: "/Tables/FAQ/Add",
  Delete: "/Tables/FAQ/Delete",
  Edit: "/Tables/FAQ/Edit",
  Export: "/Tables/FAQ/Export",
  Import: "/Tables/FAQ/Import",
  DeleteBulk: "/Tables/FAQ/DeleteBulk",
};

let exports = {
  Request
};

export default exports;