import { Paper } from "@mui/material";
import { BodyTitle } from "v2/Components";
import { VStack } from "v2/Components/Layouts";
import { APIEndpointConfig, APIPayloadSets } from "./APIIntegrator";
import { ContextsTable } from "../../../components/ContextsTable";
interface IProps {
  disabled?: boolean;
}
export const WithAPIIntegration = ({ ...props }: IProps) => {
  return (
    <VStack width="95vw" gap={2}>
      <Paper sx={{ width: "100%", p: 2 }}>
        <BodyTitle>Contexts</BodyTitle>
        <ContextsTable {...props} />
      </Paper>

      <Paper sx={{ width: "100%", p: 2 }}>
        <BodyTitle>API Endpoint</BodyTitle>
        <APIEndpointConfig {...props} />
      </Paper>

      <APIPayloadSets {...props} />
    </VStack>
  );
};
