import { Typography } from "@mui/material";
import { DBAnswerSpec } from "@types";
import { FLEFormContext } from "Pages/FLE/hooks/FLEContexts";
import { IAPIIntegration, IFLEDoc } from "Pages/FLE/interfaces/FLE.interface";
import { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RadioButtonsGroup, VTextField } from "v2/Components";
import { ControlledTextField } from "v2/Components/HookForm/ControlledTextField";
import HStack from "v2/Components/Layouts/HStack";
import VStack from "v2/Components/Layouts/VStack";
interface IProps {
  disabled?: boolean;
}
export function APIEndpointConfig({ ...props }: IProps) {
  const { control } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  return (
    <Controller
      name="integrations.api"
      control={control}
      render={({ field: { value: apiConfig, onChange: onChangeAPIConfig } }) => {
        if (!apiConfig) return <></>;
        return (
          <VStack width={"100%"}>
            <RadioButtonsGroup
              label="Method"
              value={apiConfig.method}
              options={[
                { label: "GET", value: "GET" },
                { label: "POST", value: "POST" },
              ]}
              onChange={(value) => {
                apiConfig.method = value as "GET" | "POST";
                onChangeAPIConfig(apiConfig);
              }}
            ></RadioButtonsGroup>
            <HStack>
              <Typography sx={{ color: "primary", minWidth: "10%" }}>API Path</Typography>
              <ControlledTextField<IFLEDoc<DBAnswerSpec>>
                name="integrations.api.url"
                control={control}
                label="API Path"
                required
                fullWidth
                variant="outlined"
                //
                disabled={props.disabled}
              />
            </HStack>
          </VStack>
        );
      }}
    />
  );
}
