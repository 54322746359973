import { FieldType, Filterable } from "IZOArc/LabIZO/Tablizo";

export const FILTERABLES: Filterable[] = [
  {
    field: "alias",
    label: "Context Action Alias",
    type: FieldType.text,
  },
  // {
  //   field: "integrations.api.enabled",
  //   label: "API Integrations",
  //   type: FieldType.boolean,
  // },
];
