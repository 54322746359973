import React, { Component } from "react";

import _ from "lodash";
import axios from "axios";
import { v1 } from "uuid";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";

import ZChat from "_Utilities/ZChat/ZChat";
import Chat3D from "_Utilities/ZAvatar/Chat3D";
import { Chatbot, DOMAIN } from "config/config";

import { Accessor, store, ErrorX, Authority } from "IZOArc/STATIC";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import { StyledButton } from "IZOArc/LabIZO/Stylizo";
import { ColorX } from "IZOArc/STATIC";

class Avatar extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      sessionID: undefined,
      records: [],
      zchat: {
        hideLongAnswer: false,
        revertReadMore: true,
        buttonWidthFitContent: true,

        pressEnterToSend: true,
        animated: true,

        showHeader: false,
        showFooter: true,
        showStatus: false,
        showDateTime: true,
        showLapseTime: true,

        showInAvatar: true,
        showOutAvatar: false,
        hideSameAvatar: true,
        avatarAtTop: false,

        appendTextAfterSend: true,
        quickReplyBar: true,
        showQuickRepliesAsButtons: true,
        disableButtonsAfterSend: true,
        disableTemplateButtonsAfterSend: false,

        canClickOnIn: false,
        canClickOnOut: true,
        HTMLEnabled: true,
      },
    };
  }

  componentDidMount() {
    Authority.Require("Avatar");
    this._setAllStates(() => {
      this.setChatbot(() => {
        this.RestartChat();
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Avatar.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountChat = (callbacks) => {
    this.MountChat = callbacks;
  };

  onMountChat3D = (callbacks) => {
    this.MountChat3D = callbacks;
  };

  setChatbot = (callback) => {
    console.log("[-] START CHATBOT");
    let { interval, version, channel } = Chatbot;

    this.setState(
      {
        records: [],
        livechat: false,
        channel: channel,
        url: DOMAIN + "/Middleware/Message/Send",
        timeout: interval,
        sending: false,
        remarks: {
          user: "VVIP",
          version: version,
        },
      },
      callback
    );
  };

  RestartChat = () => {
    this.setState(
      {
        sessionID: v1(),
        records: [],
      },
      () => {
        console.log(this.state.sessionID);
        this.MountChat.Clear();
        this.welcomeMessage();
      }
    );
  };

  welcomeMessage = () => {
    let input = {
      type: "system",
      content: "CMD_WELCOME",
    };
    this.sendToServer(input, null);
  };

  onSend = (input, id) => {
    console.log(input, id);

    let inputO = {
      type: "text",
      content: input.text,
    };
    this.sendToServer(inputO, id, true);
  };

  onQuickReply = (quickReply, _id) => {
    let input = {
      type: "button",
      content: quickReply.payload,
    };
    this.sendToServer(input, _id, true);
  };

  onMsgLongPress = (msgId) => {
    let { records } = this.state;
    let record = records.filter((e) => e._id === msgId);
    if (record.length === 0) return;

    let rec = record[0];
    console.log(rec);

    let suggestedIntent = "";
    if (rec.intents && rec.intents.length > 0) {
      suggestedIntent = rec.intents[0].intent;
    }

    this.setState((state, props) => ({
      form: {
        ...state.form,
        editing: rec,
      },
      formSend: {
        ...state.formSend,
        editing: {
          suggested: suggestedIntent,
        },
      },
    }));
  };

  sendToServer = async (input, _id, record = false) => {
    let { url, sessionID, channel, remarks } = this.state;
    let startTime = new Date();

    let payload = {
      JWT: store.user.JWT,
      env: store.server.Env,
      data: {
        session_id: sessionID,
        channel: channel,
        input: input,
        timestamp: new Date(),
        remarks: remarks,
      },
    };

    console.log("[>] Data Sent: " + startTime, payload);

    try {
      this.MountChat.Typing();
      let res = await axios.post(url, payload);
      this.MountChat.Typing(false);
      if (res.data.Success === true) {
        let endTime = new Date();
        if (_id) this.MountChat.SetStatus(_id, "received");

        let processTime = (endTime - startTime) / 1000;
        console.log("[<] Data Received: " + endTime);
        console.log("[-] Process Time: " + processTime + "s");
        console.log("[-] MW Response: ", res.data);

        if (record) this.recordForInfo(payload.data, res.data.payload, startTime, endTime, _id);

        if (res.data.payload.live_chat) this.startLiveChatPoll();
        else this.stopLiveChatPoll();

        let msg = res.data.payload.message;
        console.log(msg);
        _.map(msg, (o, i) => {
          o.lapseTime = processTime;
        });

        this.appendReturnMessage(msg, _id);
        if (this.MountChat3D) {
          this.MountChat3D.Msg(msg[0].msg.text);

          //Special Handling
          if (msg[0].msg.action) {
            let { name, loop } = msg[0].msg.action;
            this.MountChat3D.Play(name, loop);
          }

          if (msg[0].msg.webnav) {
            console.log(msg[0].msg.webnav);
          }
        }
      } else {
        ErrorX.Handle(res.data);
      }
    } catch (e) {
      this.MountChat.Typing(false);
      ErrorX.Handle(e);
      if (_id) this.MountChat.SetStatus(_id, "pending");
    }
  };

  msgSet = (_id, field, value) => {
    let { messages } = this.state;
    messages.map((o) => {
      if (o._id === _id) {
        o[field] = value;
      }
      return o;
    });
    this.setState({
      messages: messages,
    });
  };

  appendReturnMessage = (messages, _id) => {
    this.MountChat.Append(messages.reverse());
    this.MountChat.SetStatus(_id, "read");
  };

  liveChatShoot = () => {
    let { url, sessionID, channel, count, timeout, livechat, sending, remarks, user } = this.state;

    if (!livechat || sending) return;
    //Send Message to MW
    let date = Math.round(new Date().getTime() / 1000);
    let payload = {
      JWT: user.JWT,
      data: {
        session_id: sessionID,
        channel: channel,
        input: {
          type: "system",
          content: "",
        },
        timestamp: date,
        remarks: remarks,
      },
    };

    this.setState(
      {
        sending: true,
        count: count + 1,
      },
      () => {
        console.log("[>] LiveChat Bullet Sent. Count:" + count);
        axios
          .post(url, payload)
          .then((res) => {
            console.log("[<] Bullet Received.");
            this.setState({ sending: false });

            if (!_.isEmpty(res.data.payload.message)) {
              console.log("[<] Message Received.");
              this.setState({ count: 0 });
              this.appendReturnMessage(res.data.payload.message);
            }

            if (res.data.payload.live_chat) {
              setTimeout(() => {
                this.liveChatShoot();
              }, timeout);
            } else this.stopLiveChatPoll();
          })
          .catch((res) => {
            console.log("[-] Resume", res);
            this.setState({ sending: false });
            this.liveChatShoot();
          });
      }
    );
  };

  startLiveChatPoll = () => {
    console.log("[-] Live Chat Start Polling");
    this.setState(
      {
        livechat: true,
      },
      () => {
        this.liveChatShoot();
      }
    );
  };

  stopLiveChatPoll = () => {
    console.log("[-] Live Chat Stop Polling");
    this.setState({
      livechat: false,
    });
  };

  recordForInfo = (data, res, startTime, endTime, _id) => {
    let processTime = (endTime - startTime) / 1000;
    console.log(data);
    //mapping
    let __ans = Accessor.Get(res.ansRes, "__ans");
    let __tans = Accessor.Get(res.ansRes, "__tans");
    let __func = Accessor.Get(res.ansRes, "__func");
    let __param = Accessor.Get(res.ansRes, "__param");
    let __change = Accessor.Get(res.ansRes, "__change");

    let payload = {
      _id: _id,
      sessionId: data.session_id,
      version: this.state.remarks.version,
      in_time: startTime,
      out_time: endTime,
      process_time: processTime,
      channel: data.channel,
      input: data.input.content,
      inputType: data.input.type,
      language: res.language,
      workspace: res.ws,
      intent: res.intent || "",
      intents: Accessor.Get(res.watson_response, res.ws + ".intents") || [],
      entities: Accessor.Get(res.watson_response, res.ws + ".entities") || [],
      nodesVisited: Accessor.Get(res.watson_response, res.ws + ".output.node_visited") || [],
      detect: res.detect || [],
      __ans: (_.isArray(__ans) ? __ans.join(",") : __ans) || "",
      __tans: (_.isArray(__tans) ? __tans.join(",") : __tans) || "",
      __func: (_.isArray(__func) ? __func.join(",") : __func) || "",
      __param: (_.isArray(__param) ? __param.join(",") : __param) || "",
      __change: __change || false,
      output: res.message,
    };

    this.setState((state, props) => ({
      form: {
        ...state.form,
        editing: payload,
      },
      formSend: {
        ...state.formSend,
        editing: {
          suggested: Accessor.Get(res.watson_response, res.ws + ".intents.0.intent") || "",
        },
      },
      records: [...state.records, payload],
    }));
  };

  renderChat = () => {
    return (
      <div className="t2e-chat">
        <ZChat
          cssPrefix=""
          browser={store.browser}
          onMounted={this.onMountChat}
          onSend={this.onSend}
          onQuickReply={this.onQuickReply}
          onMsgLongPress={this.onMsgLongPress}
          onMsgPress={this.onMsgLongPress}
          user={{
            _id: 1,
          }}
          {...this.state.zchat}
        />
      </div>
    );
  };

  renderAnimateCtrl = () => {
    return (
      <VStack width={300} gap={10} padding={2}>
        <Typography>{"Play Avatar Animation: "}</Typography>
        <VStack gap={3} style={{ padding: "5px 50px", maxHeight: 800, overflow: "auto" }}>
          {this.renderAnimateBtns()}
        </VStack>
      </VStack>
    );
  };

  renderAnimateBtns = () => {
    if (!this.MountChat3D) return;
    let actions = this.MountChat3D.GetActions();
    return _.map(actions, (o, i) => {
      return (
        <StyledButton
          key={i}
          theme={{ color: ColorX.GetColorCSS("Edit"), height: 20, textTransform: "none" }}
          onClick={() => {
            this.Play(o);
          }}
        >
          {o}
        </StyledButton>
      );
    });
  };

  Play = (animation, loop = false) => {
    this.MountChat3D.Play(animation, loop);
  };

  Msg = (msg) => {
    this.MountChat3D.Msg(msg);
  };

  render() {
    return (
      <HStack alignItems={"flex-start"}>
        {this.renderAnimateCtrl()}
        {this.renderChat()}
        <Spacer />
        <Chat3D onMounted={this.onMountChat3D} />
      </HStack>
    );
  }
}

export default observer(Avatar);
