import { TablizoSchema } from "IZOArc/LabIZO/Tablizo";

const Errors = [
  // {
  //   label: "#",
  //   name: "idx",
  //   width: 100
  // },
  {
    label: "Error",
    name: "error",
  },
  {
    label: "FAQ ID",
    name: "faq",
    width: 200,
  },
];

const Update: TablizoSchema[] = [
  {
    label: "FAQ ID",
    name: "id",
    width: 200,
  },
  {
    label: "(EN) +",
    name: "intent.EN.add",
    width: 100,
  },

  {
    label: "(TC) +",
    name: "intent.TC.add",
    width: 100,
  },

  {
    label: "(SC) +",
    name: "intent.SC.add",
    width: 100,
  },

  {
    label: "Answer Update (EN)",
    customFieldName: "ansEn",
    name: "answer",
    cellClass: "center-content",
    valueGetter: (_row, field, _addOns) => {
      if (!field) return "";
      let updatedProductAnswers: string[] = [];
      Object.keys(field).forEach((productName) => {
        if (field[productName]["EN"]?.new) {
          updatedProductAnswers.push(productName);
        }
      });
      return updatedProductAnswers.join(", ");
    },
  },
  {
    label: "Answer Update (TC)",
    customFieldName: "ansTc",
    name: "answer",
    cellClass: "center-content",
    valueGetter: (_row, field, _addOns) => {
      if (!field) return "";
      let updatedProductAnswers: string[] = [];
      Object.keys(field).forEach((productName) => {
        if (field[productName]["TC"]?.new) {
          updatedProductAnswers.push(productName);
        }
      });
      return updatedProductAnswers.join(", ");
    },
  },
  {
    label: "Answer Update (SC)",
    customFieldName: "ansSc",
    name: "answer",
    cellClass: "center-content",
    valueGetter: (_row, field, _addOns) => {
      if (!field) return "";
      let updatedProductAnswers: string[] = [];
      Object.keys(field).forEach((productName) => {
        if (field[productName]["SC"]?.new) {
          updatedProductAnswers.push(productName);
        }
      });
      return updatedProductAnswers.join(", ");
    },
  },

  {
    label: "New Quick Reply ",
    name: "quickReply.new",
  },
  {
    label: "Updated Quick Reply ",
    name: "quickReply.updated",
  },
];

const New: TablizoSchema[] = [
  // {
  //   label: "#",
  //   name: "idx",
  //   width: 100,
  // },
  {
    label: "FAQ ID",
    name: "id",
    width: 200,
  },
  {
    label: "Question Sample",
    name: "intent.EN.first",
  },
  {
    label: "EN",
    name: "intent.EN.length",
    width: 100,
  },
  {
    label: "TC",
    name: "intent.TC.length",
    width: 100,
  },
  {
    label: "SC",
    name: "intent.SC.length",
    width: 100,
  },
  {
    label: "Answer Sample",
    name: "answer.sample",
  },
  {
    label: "EN",
    customFieldName: "newAnsEn",
    name: "answer.list",
    width: 100,
    valueGetter: (_row, field, _addOns) => {
      if (!field) return "";
      let newProductAnswers: string[] = [];
      Object.keys(field).forEach((productName) => {
        if (field[productName]["EN"]?.first) {
          newProductAnswers.push(productName);
        }
      });
      return newProductAnswers.join(", ");
    },
  },
  {
    label: "TC",
    customFieldName: "newAnsTc",
    name: "answer.list",
    width: 100,
    valueGetter: (_row, field, _addOns) => {
      if (!field) return "";
      let newProductAnswers: string[] = [];
      Object.keys(field).forEach((productName) => {
        if (field[productName]["TC"]?.first) {
          newProductAnswers.push(productName);
        }
      });
      return newProductAnswers.join(", ");
    },
  },
  {
    label: "SC",
    customFieldName: "newAnsSc",
    name: "answer.list",
    width: 100,
    valueGetter: (_row, field, _addOns) => {
      if (!field) return "";
      let newProductAnswers: string[] = [];
      Object.keys(field).forEach((productName) => {
        if (field[productName]["SC"]?.first) {
          newProductAnswers.push(productName);
        }
      });
      return newProductAnswers.join(", ");
    },
  },
  {
    label: "New Quick Reply ",
    name: "quickReply.new",
  },
];

const Warnings = [
  {
    label: "Error",
    name: "error",
  },
  {
    label: "FAQ ID",
    name: "faq",
    width: 200,
  },
];

const schema = {
  Errors,
  Update,
  New,
  Warnings,
};

export default schema;
