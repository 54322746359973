// import { Player } from "video-react";
import { Typography } from "@mui/material";
import { FormatSchema, FormizoSchema } from "IZOArc/LabIZO/Formizo";
import { HStack, VStack } from "IZOArc/LabIZO/Stackizo";
import { TablizoSchema } from "IZOArc/LabIZO/Tablizo";
import _ from "lodash";
import AnsLib from "_Utilities/ELAIN/AnsLib";
import AniTest from "_Utilities/ZAvatar/AniTest";
import ZCMsg from "_Utilities/ZChat/ZCMsg/ZCMsg";
import ZEye from "_Utilities/ZEye/ZEye";

const Table: TablizoSchema[] = [
  {
    label: "Answer ID",
    name: "name",
    width: 180,
  },
  {
    label: "Sample (EN)",
    name: "text.EN.0",
  },
  {
    label: "Sample (TC)",
    name: "text.TC.0",
  },
  {
    label: "Sample (SC)",
    name: "text.SC.0",
  },
  {
    label: "Preview",
    name: "",
    width: 240,
    Cell: (row, field, addOns) => {
      let ansObj = {
        EN: AnsLib.Doc2Chat(row, "EN"),
        TC: AnsLib.Doc2Chat(row, "TC"),
        SC: AnsLib.Doc2Chat(row, "SC"),
      };
      let rtn: any = [];
      _.map(ansObj, (o, i) => {
        rtn.push(
          <HStack gap={0} padding={0} key={i}>
            <Typography>{i}</Typography>
            <ZEye>
              <VStack>
                <ZCMsg cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
              </VStack>
            </ZEye>
          </HStack>
        );
      });
      return <HStack gap={0}>{rtn}</HStack>;
    },
  },
];

const Preview: FormizoSchema[] = [
  {
    label: "Preview",
    width: 50,
    tabs: [
      {
        label: "EN",
        page: [
          {
            name: "EN",
            format: "display",
            noLabelGrid: true,
            Custom: (row, field, addOns) => {
              let o = AnsLib.Doc2Chat(row, "EN");
              return (
                <HStack gap={0}>
                  <VStack>
                    <ZCMsg cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                  </VStack>
                </HStack>
              );
            },
          },
        ],
      },
      {
        label: "TC",
        page: [
          {
            name: "TC",
            format: "display",
            noLabelGrid: true,
            Custom: (row, field, addOns) => {
              let o = AnsLib.Doc2Chat(row, "TC");
              return (
                <HStack gap={0}>
                  <VStack>
                    <ZCMsg cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                  </VStack>
                </HStack>
              );
            },
          },
        ],
      },
      {
        label: "SC",
        page: [
          {
            name: "SC",
            format: "display",
            noLabelGrid: true,
            Custom: (row, field, addOns) => {
              let o = AnsLib.Doc2Chat(row, "SC");
              return (
                <HStack gap={0}>
                  <VStack>
                    <ZCMsg cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />
                  </VStack>
                </HStack>
              );
            },
          },
        ],
      },
    ],
  },
];

const PlainText: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            header: "Text (EN)",
          },
          {
            label: "Text (EN)",
            name: "text.EN.0",
            format: "textarea",
            variant: "outlined",
          },
        ],
      },
      {
        page: [
          {
            header: "Text (TC)",
          },
          {
            label: "Text (TC)",
            name: "text.TC.0",
            format: "textarea",
            variant: "outlined",
          },
        ],
      },
      {
        page: [
          {
            header: "Text (SC)",
          },
          {
            label: "Text (SC)",
            name: "text.SC.0",
            format: "textarea",
            variant: "outlined",
          },
        ],
      },
    ],
  },
];

const QuickReply: FormizoSchema[] = [
  {
    label: "Quick Reply Buttons",
    name: "quickReplies",
    canAdd: true,
    canDelete: true,
    headerStyle: "outlined",
    addStyle: "placeholder",
    array: [
      {
        label: "Title",
        width: 50,
        tabs: [
          {
            label: "EN",
            page: [
              {
                label: "Title",
                name: "title.EN",
                format: "text",
              },
            ],
          },
          {
            label: "TC",
            page: [
              {
                label: "Title",
                name: "title.TC",
                format: "text",
              },
            ],
          },
          {
            label: "SC",
            page: [
              {
                label: "Title",
                name: "title.SC",
                format: "text",
              },
            ],
          },
        ],
      },
      {
        label: "Payload",
        name: "payload",
        format: "text",
      },
      {
        label: "Type",
        name: "type",
        format: "text",
      },
    ],
  },
];

const Image: FormizoSchema[] = [
  {
    width: 50,
    tabs: [
      {
        label: "EN",
        page: [
          {
            label: "Image URL",
            name: "image.EN",
            format: "text",
          },
          {
            label: "Preview",
            name: "image.EN",
            format: "display",
            Custom: (row, field, addOns) => {
              return <img src={field} alt={"preview"} />;
            },
          },
        ],
      },
      {
        label: "TC",
        page: [
          {
            label: "Image URL",
            name: "image.TC",
            format: "text",
          },
          {
            label: "Preview",
            name: "image.TC",
            format: "display",
            Custom: (row, field, addOns) => {
              return <img src={field} alt={"preview"} />;
            },
          },
        ],
      },
      {
        label: "SC",
        page: [
          {
            label: "Image URL",
            name: "image.SC",
            format: "text",
          },
          {
            label: "Preview",
            name: "image.SC",
            format: "display",
            Custom: (row, field, addOns) => {
              return <img src={field} alt={"preview"} />;
            },
          },
        ],
      },
    ],
  },
];

const Video: FormizoSchema[] = [
  {
    width: 50,
    tabs: [
      {
        label: "EN",
        page: [
          {
            label: "Video URL",
            name: "video.src.EN",
            format: "text",
          },
          {
            label: "Poster URL",
            name: "video.poster.EN",
            format: "text",
          },
          // {
          //   label: "Preview",
          //   name: "video.src.EN",
          //   format: "display",
          //   Custom: (row, field, addOns) => {
          //     if (row.video && row.video.src && row.video.src.EN) {
          //       return <Player src={row.video.src.EN} poster={row.video.poster.EN} />;
          //     }
          //   },
          // },
        ],
      },
      {
        label: "TC",
        page: [
          {
            label: "Video URL",
            name: "video.src.TC",
            format: "text",
          },
          {
            label: "Poster URL",
            name: "video.poster.TC",
            format: "text",
          },
          // {
          //   label: "Preview",
          //   name: "video.src.TC",
          //   format: "display",
          //   Custom: (row, field, addOns) => {
          //     if (row.video && row.video.src && row.video.src.TC) {
          //       return <Player src={row.video.src.TC} poster={row.video.poster.TC} />;
          //     }
          //   },
          // },
        ],
      },
      {
        label: "SC",
        page: [
          {
            label: "Video URL",
            name: "video.src.SC",
            format: "text",
          },
          {
            label: "Poster URL",
            name: "video.poster.SC",
            format: "text",
          },
          // {
          //   label: "Preview",
          //   name: "video.src.SC",
          //   format: "display",
          //   Custom: (row, field, addOns) => {
          //     if (row.video && row.video.src && row.video.src.SC) {
          //       return <Player src={row.video.src.SC} poster={row.video.poster.SC} />;
          //     }
          //   },
          // },
        ],
      },
    ],
  },
];

const Buttons: FormizoSchema[] = [
  {
    label: "Buttons",
    name: "buttons",
    canAdd: true,
    canDelete: true,
    headerStyle: "outlined",
    addStyle: "placeholder",
    array: [
      {
        label: "Title",
        width: 50,
        tabs: [
          {
            label: "EN",
            page: [
              {
                label: "Title",
                name: "title.EN",
                format: "text",
              },
            ],
          },
          {
            label: "TC",
            page: [
              {
                label: "Title",
                name: "title.TC",
                format: "text",
              },
            ],
          },
          {
            label: "SC",
            page: [
              {
                label: "Title",
                name: "title.SC",
                format: "text",
              },
            ],
          },
        ],
      },
      {
        label: "Payload",
        name: "payload",
        format: "text",
      },
      {
        label: "Type",
        name: "type",
        format: "text",
      },
    ],
  },
];

const ImageButtons: FormizoSchema[] = [
  {
    label: "Image Buttons",
    name: "imgbuttons",
    canAdd: true,
    canDelete: true,
    headerStyle: "outlined",
    addStyle: "placeholder",
    array: [
      {
        label: "Image URL",
        width: 50,
        tabs: [
          {
            label: "EN",
            page: [
              {
                label: "Image URL",
                name: "image.EN",
                format: "text",
              },
            ],
          },
          {
            label: "TC",
            page: [
              {
                label: "Image URL",
                name: "image.TC",
                format: "text",
              },
            ],
          },
          {
            label: "SC",
            page: [
              {
                label: "Image URL",
                name: "image.SC",
                format: "text",
              },
            ],
          },
        ],
      },
      {
        label: "Title",
        width: 50,
        tabs: [
          {
            label: "EN",
            page: [
              {
                label: "Title",
                name: "title.EN",
                format: "text",
              },
            ],
          },
          {
            label: "TC",
            page: [
              {
                label: "Title",
                name: "title.TC",
                format: "text",
              },
            ],
          },
          {
            label: "SC",
            page: [
              {
                label: "Title",
                name: "title.SC",
                format: "text",
              },
            ],
          },
        ],
      },
      {
        label: "Payload",
        name: "payload",
        format: "text",
      },
      {
        label: "Type",
        name: "type",
        format: "text",
      },
    ],
  },
];

const WebInteract: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            label: "Avatar Action",
            name: "action.name",
            format: "text",
          },
          {
            label: "Loop?",
            name: "action.loop",
            format: "bool",
            defaultValue: false,
          },
          {
            label: "Web Navigation",
            name: "webnav",
            format: "text",
          },
        ],
      },
      {
        width: 400,
        page: [
          {
            inject: <AniTest />,
          },
        ],
      },
    ],
  },
];

const Tail: FormizoSchema[] = [
  {
    tabs: [
      {
        label: "Plain Text",
        page: PlainText,
      },
      {
        label: "Quick Reply",
        page: QuickReply,
      },
      /*
      {
        reqLevel: 0,
        label: "Image",
        page: Image,
      },
      {
        reqLevel: 0,
        label: "Video",
        page: Video,
      },
      {
        reqLevel: 0,
        label: "Buttons",
        page: Buttons,
      },
      {
        reqLevel: 0,
        label: "Image Buttons",
        page: ImageButtons,
      },
      {
        reqLevel: 0,
        reqAuth: "Avatar",
        label: "Web Interact",
        page: WebInteract,
      },
      */
    ],
  },
];

const Add: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            label: "Name",
            name: "name",
            format: "text",
          },
          {
            label: "Remarks",
            name: "remarks",
            format: "text",
          },
          ...Tail,
        ],
      },
      {
        width: 300,
        page: Preview,
      },
    ],
  },
];

const Info: FormizoSchema[] = [
  {
    columns: [
      {
        page: [
          {
            label: "ID",
            name: "_id",
            validate: ["required"],
            format: "text",
            readOnly: true,
          },
          {
            label: "Name",
            name: "name",
            format: "text",
            readOnly: true,
          },
          {
            label: "Remarks",
            name: "remarks",
            format: "text",
          },
          ...Tail,
        ],
      },
      {
        width: 500,
        page: Preview,
      },
    ],
  },
];

const Edit: FormizoSchema[] = [...Info];

const Export: FormatSchema[] = [
  {
    label: "Answer ID",
    name: "_id",
  },
  {
    label: "Name",
    name: "name",
  },
  {
    label: "Domain",
    name: "domain",
  },
  {
    label: "Category",
    name: "category",
  },
  {
    label: "Sub-Category",
    name: "subcategory",
  },
  {
    label: "Sample (EN) sep.';;'",
    name: "text.EN",
    format: "array",
    separator: ";;",
  },
  {
    label: "Sample (TC) sep.';;'",
    name: "text.TC",
    format: "array",
    separator: ";;",
  },
  {
    label: "Sample (SC) sep.';;'",
    name: "text.SC",
    format: "array",
    separator: ";;",
  },
  {
    label: "Quick Replies (JSON)",
    name: "quickReplies",
    format: "json",
  },
];

const Import: FormizoSchema[] = [
  {
    name: "upload",
    label: "Upload file",
    format: "file",
    validate: ["required"],
    accept: ".xlsx, xls",
  },
];

const ImportFormat: FormatSchema[] = [...Export];

const Filter: any[] = [
  {
    label: "AnsID",
    format: "text",
    fields: [{ name: "_id" }],
  },
  {
    label: "Answer",
    format: "text",
    fields: [
      { name: "text.EN", type: "array" },
      { name: "text.TC", type: "array" },
      { name: "text.SC", type: "array" },
    ],
  },
  {
    label: "Domain",
    format: "text",
    fields: [{ name: "domain", type: "text" }],
  },
  {
    label: "Category",
    format: "text",
    fields: [{ name: "category", type: "text" }],
  },
  {
    label: "SubCat",
    format: "text",
    fields: [{ name: "subcategory", type: "text" }],
  },
];

const Filterables = [
  {
    field: "name",
    label: "Answer ID",
    type: "text",
  },
  {
    field: "text.EN",
    label: "Answer (EN)",
    type: "array",
  },
];

const AdvFilter = [
  {
    label: "FAQ ID",
    name: "_id",
    format: "text",
  },
  {
    label: "Answer EN",
    name: "text.EN",
    format: "text",
  },
  {
    label: "Answer TC",
    name: "text.TC",
    format: "text",
  },
  {
    label: "Answer SC",
    name: "text.SC",
    format: "text",
  },
  {
    inline: [
      {
        label: "Date From",
        name: "dateFrom",
        format: "datetime",
      },
      {
        label: "To",
        name: "dateTo",
        format: "datetime",
      },
    ],
  },
];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
  Filterables,
};

export default schema;
