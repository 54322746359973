import { store, ErrorX } from "IZOArc/STATIC";
import Formizo from "IZOArc/LabIZO/Formizo";
import schema from "./schema";
import { useState, useEffect } from "react";
import { DOMAIN } from "config/config";
import axios from "axios";
import _ from "lodash";
import { HStack } from "IZOArc/LabIZO/Stackizo";
import { Box } from "@mui/system";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

interface IIntentObj {
  _id: String;
  caption: String;
}
interface SuggestedIntentProps {
  form: { editing: any };
  records: object[];
  onChangeFormData: (data: any) => void;
}

const SuggestedIntent = ({ form, records, onChangeFormData }: SuggestedIntentProps) => {
  const [intents, setIntents] = useState<IIntentObj[] | []>([]);
  const { user } = store;

  useEffect(() => {
    const getAllIntents = async () => {
      let url = DOMAIN + "/Tables/Intent/List";
      let payload = {
        JWT: store.user.JWT,
        data: {
          skip: 0,
          limit: 9999,
          fields: ["_id"],
        },
        addOns: {
          workspace: "ALL",
          allIntents: true,
        },
      };
      try {
        let rtn = await axios.post(url, payload);
        console.log("/Tables/Intent/List", rtn.data);

        if (rtn.data.Success === true) {
          let docs = rtn.data.payload.docs;
          let intents = _.map(docs, (o: any, i: any) => {
            let splited = o._id.split(":");
            let caption = o._id;
            if (splited.length === 2) {
              caption = splited[1];
            }
            return {
              _id: o._id,
              caption: caption,
            };
          });

          setIntents(intents);
        } else {
          ErrorX.Handle(rtn.data);
        }
      } catch (e: any) {
        ErrorX.Handle(e);
      }
    };
    getAllIntents();
  }, []);

  const LogRecord = async (data: { remarks: string; suggested: string }) => {
    console.log("SugIntent onSubmit:", data);
    let url = DOMAIN + "/Tables/Talk2VA/Log";

    let lastRes = null;
    let currentIdx = records.indexOf(form.editing);
    if (currentIdx > 0) {
      lastRes = records[currentIdx - 1];
    }

    const env = await getEnv();
    let payload = {
      JWT: user.JWT,
      env: env,
      input: form.editing.input,
      intent: form.editing.intent,
      language: form.editing.language,
      comment: {
        suggest_intent: data.suggested,
        remarks: data.remarks,
      },
      response: {
        current: {
          ...form.editing,
        },
        last: {
          ...lastRes,
        },
      },
    };

    console.log(payload);
    try {
      let rtn = await axios.post(url, payload);
      console.log(rtn);
      store.Alert("Record Updated!", "success");
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  const getEnv = async () => {
    let url = DOMAIN + "/Middleware/Env/Get";

    let payload = {
      JWT: user.JWT,
    };

    try {
      let rtn = await axios.post(url, payload);
      console.log("/Middleware/Env/Get", rtn.data);
      return rtn.data.payload.current;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <HStack>
          <Typography variant="button">Suggested Intent</Typography>
        </HStack>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Formizo
            formID={"suggest_intent"}
            schema={schema.SuggestedIntent}
            readOnly={form.editing?.input.startsWith("CMD")}
            buttons={["Clear", "Submit"]}
            auth={store.user.authority}
            level={store.user.level}
            addOns={{ intents, input: form.editing?.input }}
            fieldSize={"small"}
            onSubmit={(data) => {
              LogRecord(data);
            }}
            onInvalid={() => {
              store.Alert("Provided information is invalid, no action taken.", "warning");
            }}
          ></Formizo>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SuggestedIntent;
