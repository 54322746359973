import { TextField } from "@mui/material";
import { Control, Controller, UseControllerProps, FieldValues, Path } from "react-hook-form";
export interface ControlledTextFieldProps<TFieldValues extends FieldValues = FieldValues> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, any>;
  rules?: UseControllerProps["rules"];
  [key: string]: any;
}

export function ControlledTextField<T extends FieldValues = FieldValues>({ name, control, rules, ...props }: ControlledTextFieldProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => {
        return <TextField {...field} {...props} error={!!error} helperText={error?.message} />;
      }}
      rules={rules}
    />
  );
}
