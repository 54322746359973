import { Checkbox, Tooltip, Typography } from "@mui/material";
import { InfoOutlined, ThumbDown, ThumbUp } from "@mui/icons-material";
import { DoNotDisturbOn } from "@mui/icons-material";
import { MenuItem, NativeSelect, Select } from "@mui/material";
import { FormizoSchema } from "IZOArc/LabIZO/Formizo";
import { HStack, VStack } from "IZOArc/LabIZO/Stackizo";
import { StyledCircularProgress, StyledLinearProgress } from "IZOArc/LabIZO/Stylizo";
import { ConditionOperator, CustomFilterValueProps, FieldType, Filterable, OperatorsType } from "IZOArc/LabIZO/Tablizo";
import { ColorX } from "IZOArc/STATIC";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { StartDate } from "config/config";
import { DATE_FORMAT } from "./constants/date-format";

const StatusMap = ["Preparing", "Tagging", "Tagged", "Training", "Trained", "Verifying", "Verified"];

const Status = {
  GetIdx: (status: any) => {
    return StatusMap.indexOf(status);
  },
};

const Table = [
  {
    label: "Alias",
    name: "alias",
    filterable: true,
  },
  {
    label: "Status",
    name: "Status",
    width: 120,
    filterable: true,
  },
  {
    label: "Progress",
    name: "Summary.qDone",
    width: 250,
    Cell: (row: any, field: any, addOns: any) => {
      if (row.Status === "Preparing") {
        return (
          <VStack width="100%" alignItems="flex-start">
            <Typography style={{ fontSize: 10 }}>{"Preparing... " + (row.Summary.qPreDone === row.Summary.qCount ? "Done." : "")}</Typography>
            <HStack gap="5px">
              <StyledLinearProgress
                variant="determinate"
                theme={{ bar: ColorX.GetColorCSS("Cancel"), background: ColorX.GetColorCSS("Cancel") }}
                value={((row.Summary.qPreDone || 0) / row.Summary.qCount) * 100}
              />
              <Typography style={{ fontSize: 10 }}>{Math.round(((row.Summary.qPreDone || 0) / row.Summary.qCount) * 100) + "%"}</Typography>
            </HStack>
          </VStack>
        );
      } else if (row.Status === "Trained" || row.Status === "Verifying") {
        return (
          <VStack width="100%" alignItems="flex-start">
            <Typography style={{ fontSize: 10 }}>{"Simulating... " + (field === row.Summary.qCount ? "Done." : "")}</Typography>
            <HStack gap="5px">
              <StyledLinearProgress variant="determinate" theme={{ bar: ColorX.GetColorCSS("Edit"), background: ColorX.GetColorCSS("Edit") }} value={((field || 0) / row.Summary.qCount) * 100} />
              <Typography style={{ fontSize: 10 }}>{Math.round(((field || 0) / row.Summary.qCount) * 100) + "%"}</Typography>
            </HStack>
          </VStack>
        );
      } else if (row.Status === "Training") {
        return (
          <VStack width="100%" alignItems="flex-start">
            <Typography style={{ fontSize: 10 }}>{"Waiting for NLP Model Response... " + (row.Status === "Trained" ? "Done." : "")}</Typography>
            <HStack gap="5px">
              <StyledLinearProgress variant="determinate" theme={{ bar: ColorX.GetColorCSS("Edit"), background: ColorX.GetColorCSS("Edit") }} value={row.Status === "Trained" ? 100 : 0} />
              <Typography style={{ fontSize: 10 }}>{row.Status === "Trained" ? 100 : 0 + "%"}</Typography>
            </HStack>
          </VStack>
        );
      } else if (row.Status === "Tagging" && row.Summary.qCount === row.Summary.qPreDone) {
        return "Pre-Simulation Completed.";
      } else {
        return "No Internal process is running.";
      }
    },
  },
  {
    label: "Created",
    name: "TimeStamp.Create",
    defaultSort: "desc",
    type: "dateTime",
    transform: "datetime",
    filterable: true,
  },
  {
    label: "Log Period (From)",
    name: "logPeriod.from",
    width: 180,
    valueGetter: (row: any, field: any, addOns: any) => moment(field),
    Cell: (row: any, field: any, addOns: any) => moment(field).format(DATE_FORMAT),
    type: "date",
    filterable: true,
  },
  {
    label: "Log Period (To)",
    name: "logPeriod.to",
    width: 180,
    valueGetter: (row: any, field: any, addOns: any) => moment(field),
    Cell: (row: any, field: any, addOns: any) => moment(field).format(DATE_FORMAT),
    type: "date",
    filterable: true,
  },
  {
    label: "Accuracy (Before)",
    name: "Summary.initialAcc",
    Cell: (row: any, field: any, addOns: any) => {
      if (Status.GetIdx(row.Status) > Status.GetIdx("Preparing")) {
        return (
          <HStack>
            <StyledCircularProgress
              theme={{
                bar: ColorX.GetColorCSS("Accuracy1"),
                background: ColorX.GetColorCSS("Accuracy2"),
                label: ColorX.GetColorCSS("AccuracyLabel"),
              }}
              value={(field || 0) * 100}
              withLabel
            />
          </HStack>
        );
      } else {
        return <div />;
      }
    },
  },
  {
    label: "Accuracy (After)",
    name: "Summary.finalAcc",
    Cell: (row: any, field: any, addOns: any) => {
      if (Status.GetIdx(row.Status) > Status.GetIdx("Trained")) {
        return (
          <HStack>
            <StyledCircularProgress
              theme={{
                bar: ColorX.GetColorCSS("Accuracy1"),
                background: ColorX.GetColorCSS("Accuracy2"),
                label: ColorX.GetColorCSS("AccuracyLabel"),
              }}
              value={(field || 0) * 100}
              withLabel
            />
          </HStack>
        );
      } else {
        return <div />;
      }
    },
  },
  {
    label: "I.C.",
    name: "Summary.IC",
    width: 100,
    Cell: (row: any, field: any, addOns: any) => {
      if (!field && field !== 0) return <div />;

      //improved
      if (field && field > 0) {
        return (
          <HStack justifyContent={"flex-start"} gap={1} style={{ color: ColorX.GetColorCSS("Approval") }}>
            <ThumbUp />
            <Typography>{JSON.stringify(Math.round(field * 100) / 100)}</Typography>
          </HStack>
        );
      }
      //worsen
      else if (field && field <= 0) {
        return (
          <HStack justifyContent={"flex-start"} gap={1} style={{ color: ColorX.GetColorCSS("Cancel") }}>
            <ThumbDown />
            <Typography>{JSON.stringify(Math.round(field * 100) / 100)}</Typography>
          </HStack>
        );
      }

      //no change
      else if (field === 0) {
        return (
          <HStack justifyContent={"flex-start"} gap={1} style={{ color: ColorX.GetColorCSS("TableText") }}>
            <DoNotDisturbOn style={{ visibility: "hidden" }} />
            <Typography>{JSON.stringify(0)}</Typography>
          </HStack>
        );
      }
      return <HStack>{JSON.stringify(field)}</HStack>;
    },
  },
];

const Add = [
  {
    label: "Alias",
    name: "alias",
    validate: ["required", "plain"],
    format: "text",
  },
  {
    label: "Category",
    format: "dropdown",
    name: "category",
    selectRef: "categories",
    selectCap: "_id.alias",
    selectVal: "_id.alias",
    placeholder: "Please select ...",
  },
  {
    label: "Sub-Category",
    name: "subcategory",
    format: "text",
  },
  {
    label: "Description (EN)",
    name: "intent.EN.description",
    format: "text",
  },
  {
    label: "Intent",
    format: "header",
  },
  {
    label: "Examples (EN)",
    name: "intent.EN.examples",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "text",
        format: "text",
      },
    ],
  },
  {
    label: "Description (TC)",
    name: "intent.TC.description",
    format: "text",
  },
  {
    label: "Examples (TC)",
    name: "intent.TC.examples",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "text",
        format: "text",
      },
    ],
  },
  {
    label: "Description (SC)",
    name: "intent.SC.description",
    format: "text",
  },
  {
    label: "Examples (SC)",
    name: "intent.SC.examples",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "text",
        format: "text",
      },
    ],
  },
  {
    label: "Answer",
    format: "header",
  },
  {
    label: "Text (EN)",
    name: "answer.text.EN",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "",
        format: "text",
      },
    ],
  },
  {
    label: "Text (TC)",
    name: "answer.text.TC",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "",
        format: "text",
      },
    ],
  },
  {
    label: "Text (SC)",
    name: "answer.text.SC",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "",
        format: "text",
      },
    ],
  },
  {
    label: "Remarks",
    name: "remarks",
    format: "text",
  },
  {
    label: "Quick Reply Buttons",
    name: "answer.quickReplies",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "Title (EN)",
        name: "title.EN",
        format: "text",
      },
      {
        label: "Title (TC)",
        name: "title.TC",
        format: "text",
      },
      {
        label: "Title (SC)",
        name: "title.SC",
        format: "text",
      },
      {
        label: "Payload",
        name: "payload",
        format: "text",
      },
      {
        label: "Type",
        name: "type",
        format: "text",
      },
    ],
  },
];

const Training: FormizoSchema[] = [
  {
    inject: <Typography style={{ fontSize: 9 }}>{"ID will be generated from lowercase of alias."}</Typography>,
  },
  {
    label: "Scenario Alias",
    name: "alias",
    validate: ["required", "plain"],
    format: "text",
    variant: "outlined",
  },
  {
    label: "Log Period",
    name: "DateRange",
    format: "daterange",
    dateType: "datetime",
    dateFormat: "YYYY-MM-DD HH:mm:ss",
    validate: ["required"],
    disabledDate: (current: any) => {
      return (current && current < moment(StartDate, "YYYY-MM-DD HH:mm:ss")) || current > moment();
    },
  },
  {
    label: "Simulation",
    name: "Options.Simulation",
    format: "display",
    Custom: (row: any, field: any, addOns: any) => {
      if (!row.Options) row.Options = {};
      if (row.Options.Simulation === undefined) row.Options.Simulation = true;
      return (
        <HStack justifyContent={"flex-start"} gap={5}>
          <Checkbox
            defaultChecked={true}
            value={row.Options.Simulation}
            style={{ color: ColorX.GetColorCSS("Edit") as string }}
            onChange={(event) => (row.Options.Simulation = event.target.checked)}
          />
          <Tooltip title={"Get the latest chat bot behavior before Tagging"}>
            <InfoOutlined style={{ color: ColorX.GetColorCSS("Approval") }} />
          </Tooltip>
        </HStack>
      );
    },
  },
  {
    label: "Total Entries",
    name: "dateStr",
    format: "display",
    Custom: (row: any, field: any, addOns: any) => (
      <HStack justifyContent={"flex-start"} gap={20}>
        <Typography>{addOns.totalEntries}</Typography>
        <Tooltip title={"Elain will help you select the best data for training from the total entries"}>
          <InfoOutlined style={{ color: ColorX.GetColorCSS("Approval") }} />
        </Tooltip>
      </HStack>
    ),
  },

  {
    label: "Advanced Options (Beta)",
    foldStyle: "none",
    collapse: [
      {
        label: "Sampling",
        name: "Options.Sampling",
        format: "bool",
        boolStyle: "switch",
      },
      {
        control: "Options.Sampling",
        fold: [
          {
            label: "Size",
            name: "Options.param.sample_size",
            format: "slider",
            defaultValue: 0.7,
            step: 0.1,
            min: 0.01,
            max: 1,
            marks: true,
            valueLabelFormat: (x: any) => x && x * 100 + "%",
          },
        ],
      },
      {
        label: "Include Garbage",
        name: "Options.IncludeGarbage",
        format: "bool",
        boolStyle: "checkbox",
      },
      // {
      //   label: "Filtering",
      //   name: "Options.Filter",
      //   format: "bool",
      //   boolStyle: "switch",
      // },

      // {
      //   control: "Options.Filter",
      //   fold: [
      //     {
      //       label: "Trivial Words",
      //       name: "Options.param.trivial_words",
      //       format: "bool",
      //     },
      //   ],
      // },
    ],
  },
];

const StatusFilterValue = ({ field, width, fieldState }: CustomFilterValueProps) => {
  return (
    <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
      <option value={""}></option>
      <option value={"Tagging"}>Tagging</option>
      <option value={"Verified"}>Verified</option>
      <option value={"Verifying"}>Verifying</option>
    </NativeSelect>
  );
};

const logPeriodFilterOperator: OperatorsType = {
  in: ConditionOperator.in,
};

const LogPeriodFilterValue = ({ field, width, fieldState }: CustomFilterValueProps) => {
  const months: { label: string; value: string }[] = [];
  const now = moment();
  const startDate = moment("2021-01-01");
  const monthDiff = Math.floor(now.diff(startDate, "months", true));
  [...Array(monthDiff)].forEach((_, i) => {
    const date = moment().subtract(i, "months");
    months.push({
      label: date.format("YYYY MMM"),
      value: date.format("YYYYMM"),
    });
  });

  return (
    <Select multiple {...field} sx={{ width }} value={Array.isArray(field?.value) ? field?.value : []} variant="standard" error={Boolean(fieldState?.error)}>
      {months.map(({ label, value }) => (
        <MenuItem key={uuidv4()} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

const Filterables: Filterable[] = [
  {
    label: "Alias",
    field: "alias",
    type: FieldType.text,
  },
  {
    label: "Status",
    field: "Status",
    type: FieldType.text,
    CustomFilterValue: StatusFilterValue,
  },
  {
    label: "Created",
    field: "TimeStamp.Create",
    type: FieldType.datetime,
  },
  {
    label: "Log Period",
    field: "logPeriod",
    type: FieldType.text,
    CustomFilterOperator: logPeriodFilterOperator,
    CustomFilterValue: LogPeriodFilterValue,
  },
];

const schema = {
  Table,
  Add,
  Training,
  Filterables,
};

export default schema;
