import { createTheme } from "@mui/material/styles";

// https://mui.com/zh/material-ui/customization/default-theme/

const themeColor = "#005F64";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: themeColor,
      light: "#337F83",
      dark: "#004246",
      contrastText: "#fff",
    },
    divider: "#E7EBF0",

    common: {
      black: "#1D1D1D",
      white: "#fff",
    },
    text: {
      primary: "#1A2027",
      secondary: themeColor,
      disabled: "rgba(0, 0, 0, 0.38)",
    },

    error: {
      main: "#EB0014",
      light: "#FF99A2",
      dark: "#C70011",
      contrastText: "#fff",
    },
    success: {
      main: "#1AA251",
      light: "#6AE79C",
      dark: "#1AA251",
      contrastText: "#fff",
    },
    warning: {
      main: "#DEA500",
      light: "#FFDC48",
      dark: "#AB6800",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      main: "#f50057",
      light: "#F73378",
      dark: "#AB003C",
      contrastText: "#fff",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
      contrastText: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      paper: "#fff",
      default: "#fff",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
});
