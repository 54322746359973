import { FormizoSchema } from "IZOArc/LabIZO/Formizo";
import { TablizoSchema } from "IZOArc/LabIZO/Tablizo";
import { Accessor, store } from "IZOArc/STATIC";
import _ from "lodash";

const Table: TablizoSchema[] = [
  {
    label: "Entity",
    name: "name",
  },
  {
    label: "Values (Count)",
    name: "values",
    valueGetter: (row, field, addOns) => {
      return field ? field.length : 0;
    },
  },

  {
    label: "Context Actions",
    name: "usedInContextAction",
    valueGetter: (row, field, addOns) => {
      return field?.length > 0 ? field.join(", ") : <></>;
    },
  },
];

const Tail: FormizoSchema[] = [
  {
    label: "Description",
    name: "Description",
    format: "text",
  },
  {
    label: "Used in Context Actions",
    name: "usedInContextAction",
    format: "array",
    arrayStyle: "table",
    canAdd: false,
    canDelete: false,
    showIndex: false,
    array: [
      {
        label: "",
        name: "",
        format: "text",
        readOnly: true,
      },
    ],
  },

  //new design
  {
    label: "Entities",
    name: "values",
    format: "array",
    arrayStyle: "table",
    canAdd: true,
    canDelete: (formValue, index) => {
      const canDelete = _.get(formValue, `values.${index}.usedInContextAction`, []).length === 0;
      return canDelete;
    },
    showIndex: false,
    array: [
      {
        label: " ",
        name: "value",
        format: "text",
        readOnly: (formValue, { iname }) => {
          iname = iname.replace(".value", ".usedInContextAction");
          const usedInContextAction = _.get(formValue, iname, []);
          return usedInContextAction.length > 0;
        },
        helperText: (formValue, { iname }) => {
          iname = iname.replace(".value", ".usedInContextAction");
          const usedInContextAction = _.get(formValue, iname, []);
          if (usedInContextAction.length > 0) {
            return "! This value is used in context actions: " + usedInContextAction.join(", ");
          }
          return "";
        },
      },
      {
        tabs: store.sysInfo.Language.available.map((_o) => ({
          label: `${_o.display}`,
          page: [
            {
              label: `Synonyms (${_o.display})`,
              name: `synonyms.${_o.value}`,
              format: "array",
              arrayStyle: "table",
              canAdd: true,
              canDelete: true,
              showIndex: false,
              array: [
                {
                  label: "",
                  name: "",
                  format: "text",
                },
              ],
            },
          ],
        })),
      },
    ],
  },
];

const Add = [
  {
    label: "Entity",
    name: "name",
    validate: ["required"],
    format: "text",
  },
  ...Tail,
];

const Info = [
  {
    label: "Entity",
    name: "name",
    validate: ["required"],
    format: "text",
    readOnly: true,
  },
  ...Tail,
];

const Edit = [...Info];

const Export: FormizoSchema[] = [];

const Import: FormizoSchema[] = [];

const ImportFormat: FormizoSchema[] = [...Export];

const Filter: FormizoSchema[] = [];

const AdvFilter: FormizoSchema[] = [];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
};

export default schema;
