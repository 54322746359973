import { Tooltip, Typography } from "@mui/material";
import { DeleteOutline, Restore } from "@mui/icons-material";
import { FormizoCustom } from "IZOArc/LabIZO/Formizo/FormizoSchema.interface";
import { HStack } from "IZOArc/LabIZO/Stackizo";
import { StyledButton } from "IZOArc/LabIZO/Stylizo";
import { ColorX, store } from "IZOArc/STATIC";

const restore = [
  {
    label: "Version",
    name: "name",
  },
  {
    label: " ",
    name: "_id",
    width: 120,
    Cell: (row: object, field: object, addOns: { Restore: (feild: object) => void }) => {
      return (
        <StyledButton
          onClick={() => {
            if (addOns.Restore) {
              addOns.Restore(field);
            }
          }}
          theme={{
            width: 100,
            height: 25,
            color: ColorX.GetColorCSS("Decorate2"),
            hover: {
              background: ColorX.GetColorCSS("Decorate2"),
              color: ColorX.GetColorCSS("Decorate2"),
            },
          }}
        >
          <HStack gap={5}>
            <Restore fontSize="small" />
            <Typography style={{ fontSize: 12 }}>Restore</Typography>
          </HStack>
        </StyledButton>
      );
    },
  },
  {
    label: " ",
    name: "",
    width: 50,
    Cell: (row: { _id: string }, field: any, addOns: { Delete: (_id: string) => void }) => {
      return (
        <Tooltip title="Delete" arrow={true} placement="top">
          <StyledButton
            onClick={() => {
              if (addOns.Delete) {
                addOns.Delete(row._id);
              }
            }}
            theme={{
              width: 25,
              minWidth: 25,
              height: 25,
              color: "red",
              hover: {
                background: ColorX.GetColorCSS("Cancel"),
                color: ColorX.GetColorCSS("Cancel"),
              },
            }}
          >
            <HStack gap={5}>
              <DeleteOutline fontSize="small" />
            </HStack>
          </StyledButton>
        </Tooltip>
      );
    },
  },
];

let mwconfig = [
  {
    label: "Chatbot Name",
    name: "cbname",
    format: "text",
  },
  {
    label: "Icon URL",
    name: "avatar",
    format: "text",
  },
  {
    label: "Live Chat",
    name: "LiveChat",
    format: "bool",
    boolStyle: "switch",
  },
  {
    label: "Ambiguous Handling",
    name: "SResolve",
    format: "bool",
    boolStyle: "switch",
  },

  // {
  //   label: "Connect to Facebook",
  //   foldStyle: "none",
  //   collapse: [
  //     {
  //       label: "page_access_token",
  //       name: "page_access_token",
  //       format: "text",
  //     },
  //     {
  //       label: "validation_token",
  //       name: "validation_token",
  //       format: "text",
  //     },
  //     {
  //       label: "app_secret",
  //       name: "app_secret",
  //       format: "text",
  //     },
  //   ],
  // },
];

interface ILangSetting {
  caption: string;
  value: string;
}
interface IAddOns {
  lang: ILangSetting[];
}

const general: FormizoCustom = (formValue: any, addOns: IAddOns) => {
  addOns.lang = store.sysInfo.Language.available.map((o: { display: string; value: string }) => {
    return { caption: o.display, value: o.value };
  });
  return [
    {
      label: "Default Language",
      name: "Language.default",
      format: "select",
      selectStyle: "dropdown",
      selectRef: "lang",
      selectCap: "caption",
      selectVal: "value",
    },
  ];
};

let schema = {
  restore,
  mwconfig,
  general,
};

export default schema;
