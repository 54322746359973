import { Box, Typography } from "@mui/material";
import axios from "axios";
import Datumizo from "IZOArc/LabIZO/Datumizo/Datumizo";
import Formizo from "IZOArc/LabIZO/Formizo/Formizo";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, Authority, ColorX, ErrorX, store } from "IZOArc/STATIC";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { DOMAIN } from "config/config";
import TrainCreate from "../Training/TrainCreate";
import ConvlogDetail from "./ConvlogDetail/ConvlogDetail";
import datalink from "./datalink";
import schema from "./schema";

class Convlog extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      intents: [],
      title: "CONVERSATION OVERVIEW",
      period: { DateRange: [moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"), moment().endOf("month").format("YYYY-MM-DD HH:mm:ss")] },
      serverSidePagination: true,
      base: {
        title: "Convlog",
        exportDoc: "Convlog",
        schema: schema,
        reqAuth: "Convlog",

        tablizo: {
          showSelector: true,
          columnsToolbar: false,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },

          Info: {
            title: "CONVERSATION DETAILS",
            url: datalink.Request.Add,
            success: "Convlog Load Successfully",
            fail: "Convlog Load Failed: ",
            schema: schema.Info,
            readOnly: true,
            Custom: (id, doc, onQuit, onQuitRefresh, renderFormizo, addOns) => {
              let prev = [];
              let thisDoc = doc;
              let msgID;
              do {
                msgID = Accessor.Get(thisDoc, "Context._prev.msgID") || "";
                if (msgID) {
                  thisDoc = addOns.getDoc(msgID);
                  prev.push(thisDoc);
                }
              } while (!_.isEmpty(msgID));
              //reverse the order, earliest first
              prev.reverse();

              return <ConvlogDetail id={id} doc={doc} prev={prev} intents={addOns.intents} />;
            },
          },

          Training: {
            title: "Initiate Training",
            Custom: (id, row, onQuit, onQuitRefresh, renderFormizo, addOns) => {
              return <TrainCreate onQuit={onQuit} DateRange={addOns.DateRange} />;
            },
          },
        },

        buttons: {
          inline: [{ icon: "info", func: "Info", reqFunc: "Info", caption: "Details" }],
          right: [
            { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export", reqLevel: 2 },
            {
              icon: "initTrain",
              func: this._initTraining,
              caption: "Initiate Training",
              reqFunc: "InitTrain",
              theme: {
                label: "white",
                background: ColorX.GetColorCSS("Decorate1"),
                hover: {
                  background: "#115293",
                },
              },
              reqLevel: 0,
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("Convlog");
    this._setAllStates(() => {
      this.getAllIntents();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Convlog.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  getAllIntents = async () => {
    let url = DOMAIN + "/Tables/Intent/List";
    let payload = {
      JWT: store.user.JWT,
      data: {
        skip: 0,
        limit: 9999,
        fields: ["_id", "Description"],
      },
      addOns: {
        workspace: "ALL",
        allIntents: true,
      },
    };
    try {
      let rtn = await axios.post(url, payload);
      console.log("/Tables/Intent/List", rtn.data);

      if (rtn.data.Success === true) {
        let docs = rtn.data.payload.docs;
        let intents = _.map(docs, (o, i) => {
          let splited = o._id.split(":");
          let caption = o._id;
          if (splited.length === 2) {
            caption = splited[1];
          }
          return {
            _id: o._id,
            caption: caption,
            Description: o.Description,
          };
        });
        this.setState((state, props) => ({
          intents: intents,
        }));
      } else {
        ErrorX.Handle(rtn.data);
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  _initTraining = () => {
    console.log("init training");
    this.MountDatumizo.CustomInner("Training");
  };

  renderDatePicker = () => {
    let { period } = this.state;
    return (
      <Formizo
        width={350}
        height="100%"
        schema={[
          {
            label: "",
            name: "DateRange",
            format: "daterange",
            dateType: "datetime",
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            // disabledDate: (current) => {
            //   return (current && current < moment(StartDate)) || current > moment().endOf("month");
            // },
            noLabelGrid: true,
            defaultValue: period.DateRange,
          },
        ]}
        buttons={[]}
        onChange={(value, _n, _v, fetchData) => {
          this.setState({ period: value });
          if (fetchData) {
            this.MountDatumizo.Reload();
          }
        }}
      />
    );
  };

  getDoc = (docID) => {
    return this.MountDatumizo.GetDoc(docID);
  };

  renderTable = () => {
    let { base, serverSidePagination, period, intents } = this.state;
    return (
      <Datumizo
        base={base}
        addOns={{
          ...period,
          getDoc: this.getDoc,
          intents: intents,
        }}
        serverSidePagination={serverSidePagination}
        onMounted={this.onMountDatumizo}
        languageSelector={false}
      />
    );
  };

  render() {
    let { title } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <HStack
            justifyContent="space-between"
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Typography
              style={{
                alignItems: "left",
                display: "flex",
                fontSize: 25,
                color: ColorX.GetColorCSS("Primary"),
              }}
            >
              {title}
            </Typography>
            {this.renderDatePicker()}
            <Spacer />
          </HStack>
        </Box>

        {this.renderTable()}
      </VStack>
    );
  }
}

export default Convlog;
