import _ from "lodash";

import GPie from "../../Graphs/GPie";
import GraphTitle from "../../Graphs/GraphTitle";
import "./Distribution.scss";

import { VStack } from "IZOArc/LabIZO/Stackizo";

const Distribution = ({ title, data }) => {
  if (data) {
    data = _.sortBy(data, (o) => -o.value).slice(0, 10);
  }

  return (
    <VStack className="Distribution" width="100%">
      <GraphTitle title={title || ""} />
      <GPie data={data} />
    </VStack>
  );
};

export default Distribution;
