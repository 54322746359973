import { TablizoCustom } from "IZOArc/LabIZO/Tablizo";
import { DynamicInfo, DynamicAdd, DynamicEdit } from "./schema/form-schema";
import { Typography } from "@mui/material";
import { FormizoSchema } from "IZOArc/LabIZO/Formizo";
import { FormizoCustom } from "IZOArc/LabIZO/Formizo/FormizoSchema.interface";
import { VStack } from "IZOArc/LabIZO/Stackizo";
import { store } from "IZOArc/STATIC";
import { Filterables } from "./schema/filter-schema";
const Table: TablizoCustom = (tbLang: { display: string; value: string }) => [
  {
    label: "ID",
    name: "name",
    filterable: true,
    hide: true,
  },
  {
    label: "FAQ ID",
    name: "alias",
    filterable: true,
    defaultSort: "asc",
  },
  {
    label: `Button Caption (${tbLang.value})`,
    name: `intent.${tbLang.value}.description`,
    filterable: true,
  },
  {
    label: `Question (${tbLang.value})`,
    name: `intent.${tbLang.value}.examples.0.text`,
    filterable: true,
  },
  {
    label: `Answer (${tbLang.value})`,
    name: `answer.product.WHS.${tbLang.value}.0`,
    filterable: true,
  },
  {
    label: `Created Time`,
    name: `CREATED_AT`,
    filterable: false,
  },
  {
    label: `Last Updated Time`,
    name: `LASTMODIFIED`,
    filterable: false,
  },
];

export const QuickReply: FormizoSchema = {
  label: "Quick Reply Buttons",
  name: "answer.quickReplies",
  canAdd: true,
  canDelete: true,
  headerStyle: "outlined",
  addStyle: "header",
  array: [
    {
      label: "Display",
      width: 50,
      name: " ",
      tabs: [
        {
          label: "EN",
          page: [
            {
              label: "Display",
              name: "title.EN",
              format: "text",
            },
          ],
        },
        {
          label: "TC",
          page: [
            {
              label: "Display",
              name: "title.TC",
              format: "text",
            },
          ],
        },
        {
          label: "SC",
          page: [
            {
              label: "Display",
              name: "title.SC",
              format: "text",
            },
          ],
        },
      ],
    },
    {
      label: "Button Payload",
      name: "payload",
      format: "select",
      selectStyle: "dropdown",
      selectRef: "intents",
      selectCap: "caption",
      selectVal: "_id",
      placeholder: "Intent",
      validate: ["required"],
      variant: "grid",
      width: 250,
    },
  ],
};

const Questions: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => [
  {
    label: `${fLang.display}(${fLang.value})`,
    name: `intent.${fLang.value}.examples.0.text`,
    format: "text",
    validate: ["required"],
  },
  {
    label: "Other Languages",
    foldStyle: "none",
    collapse: store.sysInfo.Language.available.map((o) => {
      if (o.value === fLang.value) return <div />;
      return {
        label: `${o.display}`,
        name: `intent.${o.value}.examples.0.text`,
        format: "text",
      };
    }),
  },
];
const Answers: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => [
  {
    label: `${fLang.display}(${fLang.value})`,
    name: `answer.text.${fLang.value}.0`,
    id: "faq-create-default-answer",

    format: "textarea",
    validate: ["required"],
    rows: 5,
  },
  {
    label: "Other Languages",
    foldStyle: "none",
    collapse: store.sysInfo.Language.available.map((o) => {
      if (o.value === fLang.value) return <div />;
      return {
        label: `${o.display}`,
        name: `answer.text.${o.value}.0`,
        format: "textarea",
      };
    }),
  },
  QuickReply,
];
const Settings: FormizoSchema[] = [
  {
    inject: (
      <VStack alignItems="flex-start" width="100%">
        <Typography style={{ fontSize: 9 }}>{"*ID will add FAQ_ prefix to associated components ID."}</Typography>
        <Typography style={{ fontSize: 9 }}>{"*Only alphanumeric characters (a-z, A-Z, 0-9) and underscore (_) is allowed."}</Typography>
      </VStack>
    ),
  },
  {
    label: "ID",
    name: "id",
    validate: ["required", "plain"],
    format: "text",
  },
  {
    label: "Advanced Settings",
    collapse: [
      {
        label: "Alias",
        name: "alias",
        format: "text",
        placeholder: "(Optional) Alias will be the same as ID if empty.",
      },
      {
        label: "Description",
        name: "description",
        format: "text",
        placeholder: "(Optional) Description will be the same as Alias if empty.",
      },
      {
        label: "Button Caption (EN)",
        name: "btn.EN",
        format: "text",
        placeholder: "(Optional) Caption will be the same as Description if empty.",
      },
      {
        label: "Button Caption (TC)",
        name: "btn.TC",
        format: "text",
        placeholder: "(Optional) Caption will be the same as Description if empty.",
      },
      {
        label: "Button Caption (SC)",
        name: "btn.SC",
        format: "text",
        placeholder: "(Optional) Caption will be the same as Description if empty.",
      },
    ],
  },
];

const schema = {
  Table,
  DynamicInfo,
  DynamicAdd,
  DynamicEdit,
  Questions,
  Answers,
  Settings,
  Filterables,
  QuickReply
};

export default schema;
