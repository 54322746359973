import { Box, Typography } from "@mui/material";
import { History } from "@mui/icons-material";
import axios from "axios";
import Formizo from "IZOArc/LabIZO/Formizo";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import Stepizo from "IZOArc/LabIZO/Stepizo";
import { StyledButton } from "IZOArc/LabIZO/Stylizo";
import { Accessor, ColorX, ErrorX, store } from "IZOArc/STATIC";
import PropsType from "prop-types";
import React, { Component } from "react";
import { DOMAIN } from "config/config";
import datalink from "./datalink";
import schema from "./schema";
import { getProductItems } from "config/sunlife-product-config";

class FAQCreate extends Component {
  static defaultProps = {
    onQuit: () => {},
    onQuitRefresh: () => {},
    getQuickReplies: () => {},
    addOns: {},
  };

  constructor() {
    super();
    this.state = {
      activeStep: 0,
      steps: [
        {
          label: "Questions",
        },
        {
          label: "Answers",
        },
        {
          label: "Settings",
        },
      ],
      formProps: {
        Questions: {},
        Answers: {},
        Settings: {},
      },
      productItems: []
    };
  }

  async componentDidMount() {
    const productItems = await getProductItems();
    this.setState({
      productItems: productItems
    })
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FAQCreate.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountStepizo = (callbacks) => {
    this.MountStepizo = callbacks;
  };

  onMountFormizoQ = (callbacks) => {
    this.MountFormizoQ = callbacks;
  };

  onMountFormizoA = (callbacks) => {
    this.MountFormizoA = callbacks;
  };

  onMountFormizoS = (callbacks) => {
    this.MountFormizoS = callbacks;
  };

  _onStepChange = (step) => {
    this.setState({ activeStep: step });
  };

  _realSubmit = async () => {
    let { formProps } = this.state;
    let { addOns, onQuitRefresh, getQuickReplies } = this.props;
    console.log(formProps);

    let url = DOMAIN + datalink.Request.Add;
    let payload = {
      data: {
        ...formProps.Questions,
        ...formProps.Answers,
        ...formProps.Settings,
      },
      JWT: store.user.JWT,
      addOns: {
        workspace: "master",
        ...addOns,
      },
    };

    try {
      console.log(datalink.Request.Add, payload);

      store.isLoading(true);
      let res = await axios.post(url, payload);
      store.isLoading(false);

      console.log(datalink.Request.Add, res.data);

      if (res.data.Success === true) {
        store.Alert("FAQ Added Successfully", "success");
        getQuickReplies();
        onQuitRefresh();
      } else {
        let msg = "FAQ Add Failed: " + ((res.data && res.data.message) || "");
        ErrorX.Handle({ ...res.data, message: msg });
      }
    } catch (e) {
      let msg = "FAQ Add Failed: " + e;
      store.Alert(msg, "error");
    }
  };

  _onSubmitS = (formProps) => {
    console.log(formProps);
    this.setState(
      (state, props) => ({
        formProps: {
          ...state.formProps,
          Settings: formProps,
        },
        activeStep: 2,
      }),
      () => {
        this.MountStepizo.setCompleted(2);
        this._realSubmit();
      }
    );
  };

  getSchemaAnswers() {
    const Answers = (formValue, addOns, fLang) =>  {
      let output = [];

      this.state.productItems.forEach((product) => {
        let answerTabs = []; 

        store.sysInfo.Language.available.forEach((lang) => {
          answerTabs.push({
            label: `${lang.display}(${lang.value})`,
            name: `answer.product.${product}.${lang.value}.0`,
            id: `faq-product-${product}-${lang.value}`,
            format: "textarea",
            validate: ["required"],            
          })          
        })

        output.push({
          label: product,
          collapse: answerTabs
        })
      })

      output.push(schema.QuickReply);
      return output;
    }
          
    return Answers;
  }

  renderNextButtonS() {
    return (
      <StyledButton
        key="next"
        onClick={() => {
          this.MountFormizoS.Submit();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "150px",
        }}
      >
        <HStack>
          <Typography>FINISH</Typography>
          <Spacer />
        </HStack>
      </StyledButton>
    );
  }

  _onSubmitA = (formProps) => {
    console.log(formProps);
    this.setState(
      (state, props) => ({
        formProps: {
          ...state.formProps,
          Answers: formProps,
        },
        activeStep: 2,
      }),
      () => {
        this.MountStepizo.setCompleted(1);
      }
    );
  };

  renderNextButtonA() {
    return (
      <StyledButton
        id="next"
        key="next"
        onClick={() => {
          this.MountFormizoA.Submit();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "150px",
        }}
      >
        <HStack>
          <div>NEXT</div>
          <Spacer />
          <i className="fas fa-arrow-right" />
        </HStack>
      </StyledButton>
    );
  }

  _onSubmitQ = (formProps) => {
    console.log(formProps);
    this.setState(
      (state, props) => ({
        formProps: {
          ...state.formProps,
          Questions: formProps,
        },
        activeStep: 1,
      }),
      () => {
        this.MountStepizo.setCompleted(0);
      }
    );
  };

  renderNextButtonQ() {
    return (
      <StyledButton
        id="next"
        key="next"
        onClick={() => {
          this.MountFormizoQ.Submit();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "150px",
        }}
      >
        <HStack>
          <div>NEXT</div>
          <Spacer />
          <i className="fas fa-arrow-right" />
        </HStack>
      </StyledButton>
    );
  }

  // Revert Buttons
  renderRevertA() {
    return (
      <StyledButton
        key="revert"
        onClick={() => {
          this.MountFormizoA.Revert();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "100px",
          margin: "10px",
        }}
      >
        <HStack>
          <HStack>
            <div>REVERT</div>
            <Spacer />
            <History />
          </HStack>
        </HStack>
      </StyledButton>
    );
  }

  renderRevertQ() {
    return (
      <StyledButton
        key="revert"
        onClick={() => {
          this.MountFormizoQ.Revert();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "100px",
          margin: "10px",
        }}
      >
        <HStack>
          <HStack>
            <div>REVERT</div>
            <Spacer />
            <History />
          </HStack>
        </HStack>
      </StyledButton>
    );
  }

  renderRevertS() {
    return (
      <StyledButton
        key="revert"
        onClick={() => {
          this.MountFormizoS.Revert();
        }}
        theme={{
          color: "white",
          background: ColorX.GetColorCSS("Primary"),
          hover: {
            background: ColorX.GetColorCSS("Primary2"),
          },
          boxShadow: "transparent",
          borderRadius: "0px",
          width: "100px",
          margin: "10px",
        }}
      >
        <HStack>
          <HStack>
            <div>REVERT</div>
            <Spacer />
            <History />
          </HStack>
        </HStack>
      </StyledButton>
    );
  }

  //Setting Page
  renderSettings() {
    return (
      <VStack>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 20,
            fontFamily: "Segoe UI",
            color: ColorX.GetColorCSS("Primary"),
            margin: "5px 0",
          }}
        >
          SETTINGS
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 16,
            fontFamily: "Segoe UI",
            color: "black",
            margin: "5px 0",
          }}
        >
          Give a representative ID and alias to this FAQ set for easier recongnition and search.
        </Typography>
        <HStack alignItems="flex-start" marginY={3}>
          <Formizo
            height="auto"
            width="600px"
            schema={schema.Settings}
            buttons={[this.renderRevertS(), this.renderNextButtonS()]}
            onMounted={this.onMountFormizoS}
            onSubmit={this._onSubmitS}
            fieldStyle="filled"
            buttonAlign="right"
            onInvalid={() => {
              store.Alert("The data is invalid.", "warning");
            }}
          />
        </HStack>
      </VStack>
    );
  }

  renderAnswer() {
    return (
      <VStack>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 20,
            fontFamily: "Segoe UI",
            color: ColorX.GetColorCSS("Primary"),
            margin: "5px 0",
          }}
        >
          INPUT ANSWER RESPONSE
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 16,
            fontFamily: "Segoe UI",
            color: "black",
            margin: "5px 0",
          }}
        >
          Input the answer to be responded by the chatbot for the question asked.
        </Typography>
        <HStack alignItems="flex-start" marginY={3}>
          <Typography
            style={{
              textAlign: "left",
              width: "100px",
              fontSize: 20,
              fontFamily: "Segoe UI",
              color: ColorX.GetColorCSS("Primary"),
              padding: "15px 0",
            }}
          >
            ANSWER
          </Typography>
          <Formizo
            height="auto"
            width="600px"
            schema={this.getSchemaAnswers()}
            buttons={[this.renderRevertA(), this.renderNextButtonA()]}
            onMounted={this.onMountFormizoA}
            onSubmit={this._onSubmitA}
            fieldStyle="filled"
            buttonAlign="right"
            onInvalid={() => {
              store.Alert("The data is invalid.", "warning");
            }}
            addOns={this.props.addOns}
          />
        </HStack>
      </VStack>
    );
  }

  renderQuestion() {
    return (
      <VStack>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 20,
            fontFamily: "Segoe UI",
            color: ColorX.GetColorCSS("Primary"),
            margin: "5px 0",
          }}
        >
          INPUT REPRESENTATIVE QUESTION
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 16,
            fontFamily: "Segoe UI",
            color: "black",
            margin: "5px 0",
          }}
        >
          Input the question that can well represent the intention of the enquirer.
        </Typography>
        <HStack alignItems="flex-start" marginY={3}>
          <Typography
            style={{
              textAlign: "left",
              width: "100px",
              fontSize: 20,
              fontFamily: "Segoe UI",
              color: ColorX.GetColorCSS("Primary"),
              padding: "15px 0",
            }}
          >
            QUESTION
          </Typography>
          <Formizo
            height="500px"
            width="600px"
            schema={schema.Questions}
            buttons={[this.renderRevertQ(), this.renderNextButtonQ()]}
            onMounted={this.onMountFormizoQ}
            onSubmit={this._onSubmitQ}
            fieldStyle="filled"
            buttonAlign="right"
            onInvalid={() => {
              store.Alert("The data is invalid.", "warning");
            }}
          />
        </HStack>
      </VStack>
    );
  }

  renderStep() {
    let { activeStep } = this.state;
    return (
      <Box>
        <Box display={activeStep === 0 ? undefined : "none"}>{this.renderQuestion()}</Box>
        <Box display={activeStep === 1 ? undefined : "none"}>{this.renderAnswer()}</Box>
        <Box display={activeStep === 2 ? undefined : "none"}>{this.renderSettings()}</Box>
      </Box>
    );
  }

  render() {
    let { steps, activeStep } = this.state;
    return (
      <VStack>
        <Stepizo steps={steps} activeStep={activeStep} onMounted={this.onMountStepizo} onStepChange={this._onStepChange} />
        {this.renderStep()}
      </VStack>
    );
  }
}

export default FAQCreate;
