const Request = {
  DBInfo: "/Tables/Entity/Info",
  List: "/Tables/Entity/List",
  Add: "/Tables/Entity/Add",
  Delete: "/Tables/Entity/Delete",
  Edit: "/Tables/Entity/Edit",
  Export: "/Tables/Entity/Export",
  Import: "/Tables/Entity/Import",
  Sync: "/Tables/Entity/Sync",
  DeleteBulk: "/Tables/Entity/DeleteBulk",
};

let exports = {
  Request
};

export default exports;