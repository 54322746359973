import { useState } from "react";

import _ from "lodash";
import { observer } from "mobx-react";
import { Typography, Tooltip, Box, ButtonBase } from "@mui/material";

import MenuButtonList from "./MenuButtonList";

import Accessizo from "IZOArc/LabIZO/Accessizo";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import { store, ColorX } from "IZOArc/STATIC";
import { HMarquee } from "IZOArc/LabIZO/Animatizo";
import "./syva_logo.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

function MenuButton({ caption = "", path = "", fafa = "", reqAuth = "", reqLevel = 999, submenu = [], disabled = false, mini = false, zIndex = 50, id = "" }) {
  const [openNested, setOpenNested] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [inPage, setInPage] = useState(false);
  useEffect(() => {
    if (location.pathname.includes(path)) {
      setInPage(true);
    } else {
      setInPage(false);
    }
  }, [location.pathname, path]);

  const _onClick = (path) => {
    navigate(path);
  };

  const openNestedMenu = () => {
    setOpenNested(true);
  };

  const closeNestedMenu = () => {
    setOpenNested(false);
  };

  function renderFafa(fafa) {
    if (fafa === "syva_logo") {
      const style = {
        backgroundColor: inPage ? ColorX.GetColorCSS("Secondary") : ColorX.GetColorCSS("Primary"),
      };

      return <Box sx={style} height={"25px"} width={"30px"} className={"syva_logo"}></Box>;
    }

    if (_.isString(fafa)) {
      return <i className={fafa} />;
    }

    return fafa;
  }
  function renderButton() {
    let inPageTheme = {
      padding: "0",
      textTransform: "none",
      borderRadius: "0px",
      color: ColorX.GetColorCSS("Secondary"),
      background: ColorX.GetColorCSS("Primary"),
      //hover
      "&:hover": {
        color: ColorX.GetColorCSS("Secondary"),
        background: ColorX.GetColorCSS("Primary"),
      },
      position: "relative",
      zIndex: zIndex,
    };

    let theme = {
      padding: "0",
      textTransform: "none",
      borderRadius: "0px",
      color: ColorX.GetColorCSS("Primary"),
      background: ColorX.GetColorCSS("Secondary"),
      //hover
      "&:hover": {
        color: ColorX.GetColorCSS("Secondary"),
        background: ColorX.GetColorCSS("Primary"),
        "& .syva_logo": {
          backgroundColor: ColorX.GetColorCSS("Secondary"),
        },
      },
      position: "relative",
      zIndex: zIndex,
    };

    return (
      <ButtonBase sx={inPage ? inPageTheme : theme}>
        <HStack padding={1} width="140px">
          <HMarquee width="90px">
            <Typography
              sx={{
                fontSize: 14,
                opacity: 1,
                whiteSpace: "nowrap",
                height: "100%",
                //color: inPage ? ColorX.GetColorCSS("Secondary") : ColorX.GetColorCSS("Primary"),
              }}
            >
              {caption}
            </Typography>
          </HMarquee>
          <Spacer />
          <VStack gap={0}>
            <Spacer />
            <Tooltip title={mini ? caption : ""} aria-label="label" placement="right" arrow={true}>
              <HStack width={24} height={24} sx={{ justifyContent: "flex-start" }}>
                {renderFafa(fafa)}
                <Spacer />
              </HStack>
            </Tooltip>
            <Spacer />
          </VStack>
        </HStack>
      </ButtonBase>
    );
  }

  function renderNested() {
    if (openNested && submenu) {
      return <MenuButtonList buttons={submenu} mini={mini} zIndex={zIndex} />;
    }
  }

  function renderInner() {
    return (
      <HStack
        gap={0}
        id={id ? id : caption}
        className="MenuButton-inner"
        style={{ width: "100%", position: "relative" }}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            _onClick(path);
          }
        }}
        onMouseEnter={() => {
          openNestedMenu();
        }}
        onMouseLeave={() => {
          closeNestedMenu();
        }}
      >
        {renderButton()}
        {renderNested()}
      </HStack>
    );
    //}
  }

  return (
    <Accessizo key={caption} reqAuth={reqAuth} reqLevel={reqLevel} auth={store.user.authority} level={store.user.level}>
      {renderInner()}
    </Accessizo>
  );
}

export default observer(MenuButton);
