import { FormatSchema, FormizoSchema } from "IZOArc/LabIZO/Formizo";
import { TablizoSchema } from "IZOArc/LabIZO/Tablizo";
import _ from "lodash";

const Table: TablizoSchema[] = [
  {
    label: "Phone number",
    name: "_id",
    width: 180,
  },
  {
    label: "Permission",
    name: "permission",
  }
];

const Add: FormizoSchema[] = [
  {
    label: "Phone number",
    name: "_id",
    validate: ["required"],
    format: "text"
  },
  {
    label: "Permission",
    name: "permission",
    validate: ["required"],
    format: "select",
    selectStyle: "dropdown",
    selectRef: ["AGENT", "BROKER", "DEV"],
  }
]

const Info: FormizoSchema[] = [
  ...Table
];

const Edit: FormizoSchema[] = [{
  label: "Phone number",
  name: "_id",
  validate: ["required"],
  format: "text",
  readOnly: true
},
{
  label: "Permission",
  name: "permission",
  validate: ["required"],
  format: "select",
  selectStyle: "dropdown",
  selectRef: ["AGENT", "BROKER", "DEV"],
}];

const Export: FormatSchema[] = [
];

const Import: FormizoSchema[] = [
  {
    name: "upload",
    label: "Upload file",
    format: "file",
    validate: ["required"],
    accept: ".xlsx, xls",
  },
];

const ImportFormat: FormatSchema[] = [...Export];

const Filter: any[] = [
  {
    label: "Whatsapp number",
    format: "text",
    fields: [{ name: "_id" }],
  }
];

const Filterables = [
  {
    field: "_id",
    label: "Whatsapp Number",
    type: "text",
  }
];

const AdvFilter = [
  {
    label: "Whatsapp Number",
    name: "_id",
    format: "text",
  }
  
];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
  Filterables,
};

export default schema;
