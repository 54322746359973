import { FormatSchema, FormizoSchema } from "IZOArc/LabIZO/Formizo";
import { TablizoSchema } from "IZOArc/LabIZO/Tablizo";
import _ from "lodash";

const Table: TablizoSchema[] = [
  {
    label: "_id",
    name: "_id",
    width: 360,
  },
  {
    label: "folder",
    name: "folder"
  },
  {
    label: "path",
    name: "outpath",
  },
];

const Add: FormizoSchema[] = [
  {
    name: "folderPath",
    label: "folderPath",
    format: "select",
    selectStyle: "dropdown",
    selectRef: [] ,
  },
  {
    name: "file",
    label: "Upload file",
    format: "file",
  },
]

const DynamicAdd = (productItems: string[]): FormizoSchema[] => {  
  return [
    {
      name: "folderPath",
      label: "folderPath",
      format: "select",
      validate: ["required"],
      selectStyle: "dropdown",
      selectRef: productItems,
    },
    {
      name: "upload",
      label: "Upload file",
      format: "file",
      validate: ["required"],    
    },
  ];
}

const Info: FormizoSchema[] = [
  ...Table
];

const Edit: FormizoSchema[] = [{
  label: "Phone number",
  name: "_id",
  validate: ["required"],
  format: "text",
  readOnly: true
},
{
  label: "Permission",
  name: "permission",
  validate: ["required"],
  format: "select",
  selectStyle: "dropdown",
  selectRef: ["AGENT", "BROKER", "DEV"],
}];

const Export: FormatSchema[] = [
];

const Import: FormizoSchema[] = [
  {
    name: "upload",
    label: "Upload file",
    format: "file",
    validate: ["required"],
  },
];

const ImportFormat: FormatSchema[] = [...Export];

const Filter: any[] = [
  {
    label: "Whatsapp number",
    format: "text",
    fields: [{ name: "_id" }],
  }
];

const Filterables = [
  {
    field: "_id",
    label: "File Name",
    type: "text",
  },
  {
    field: "folder",
    label: "Folder",
    type: "text"
  }
];

const AdvFilter = [
  {
    label: "Whatsapp Number",
    name: "_id",
    format: "text",
  }
  
];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Export,
  Import,
  ImportFormat,
  Filter,
  AdvFilter,
  Filterables,
  DynamicAdd
};

export default schema;
