import { Box } from "@mui/material";
import { Cable, AccountTree, Dashboard, Forum, NotInterested, Science, WhatsApp, AttachFile } from "@mui/icons-material";
import { ModelTraining } from "@mui/icons-material";

export const MenuConfig = [
  {
    caption: "DashBoard",
    id: "menu-dashboard",
    link: "/Dashboard",
    faIcon: <Dashboard />,
    auth: "Dashboard",
  },
  {
    caption: "Talk-to-VirtualAssistant",
    id: "menu-talk-to-virtual-assistant",
    link: "/Talk2VA",
    faIcon: "syva_logo",
    auth: "Talk2VA",
    submenu: [
      {
        caption: "T2VATrainer",
        id: "menu-t2vatrainer",
        link: "/T2VATrainer",
        faIcon: <ModelTraining />,
        auth: "T2VATrainer",
      },
    ],
  },
  {
    caption: "FAQs",
    id: "menu-faq",
    link: "/FAQ",
    faIcon: "fas fa-comments fa-lg",
    auth: "FAQ",
    submenu: [
      {
        caption: "Counter Examples",
        id: "menu-counter-examples",
        link: "/FAQ/CounterExample",
        faIcon: <NotInterested />,
        auth: "Workspace.CounterExample",
      },
    ],
  },
  {
    caption: "Workspace",
    id: "menu-workspace",
    faIcon: "fas fa-crop-alt fa-lg",
    auth: "Workspace",
    link: "/Workspace",
    disabled: true,
    submenu: [
      {
        caption: "Intents",
        id: "menu-intents",
        link: "/Workspace/Intent",
        faIcon: "fas fa-crosshairs fa-lg",
        auth: "Workspace.Intent",
      },
      {
        caption: "Entities",
        id: "menu-entities",
        link: "/Workspace/Entity",
        faIcon: "fas fa-puzzle-piece fa-lg",
        auth: "Workspace.Entity",
      },
      {
        caption: "Answers",
        id: "menu-answers",
        link: "/Answer",
        faIcon: "fas fa-comment-dots fa-lg",
        auth: "Answer",
      },
    ],
  },
  {
    caption: "Conversation",
    id: "menu-conversation",
    link: "/Convlog",
    faIcon: <Forum />,
    auth: "Convlog",
  },
  {
    caption: "Audit Log",
    id: "request-log",
    link: "/RequestLog",
    faIcon: <Cable />,
    auth: "RequestLog",
  },
  {
    caption: "Training",
    id: "menu-training",
    link: "/Training",
    faIcon: "fas fa-robot fa-lg",
    auth: "Training",
  },

  {
    caption: "Flow Editor",
    id: "menu-fle",
    link: "/FLE",
    faIcon: <AccountTree />,
    auth: "FLE",
  },
  {
    caption: "System",
    id: "menu-system",
    link: "/System",
    faIcon: "fas fa-cog fa-lg",
    auth: "System",
  },
  {
    caption: "Technology Previews",
    id: "menu-technology-previews",
    link: "/TechnologyPreviews",
    faIcon: <Science />,
    auth: "TechnologyPreviews",
    submenu: [
      {
        caption: "AWS LiveChat",
        id: "menu-aws-livechat",
        link: "/AWSLiveChat",
        faIcon: (
          <Box height={"25px"}>
            <img style={{ height: "100%" }} src={`${process.env.PUBLIC_URL}/Images/AWS/aws_connect_icon.png`} alt="ic_connect"></img>
          </Box>
        ),
        auth: "AWSLiveChat",
      },
      {
        caption: "Avatar development",
        id: "menu-avatar-development",
        link: "/Avatar",
        faIcon: "far fa-grin-alt",
        auth: "Avatar",
      },
      {
        caption: "Deep Learning",
        id: "menu-deep-learning",
        link: "/TestChat",
        faIcon: "fas fa-robot fa-lg",
        auth: "",
      },
    ],
  },
  {
    caption: "Whatsapp Permission",
    id: "whatsapppermissionlist",
    link: "WhatsappPermissionList",
    faIcon: <WhatsApp />,
    auth: "WhatsappPermissionList"
  },
  {
    caption: "File Handler",
    id: "filehandler",
    link: "FileHandler",
    faIcon: <AttachFile />,
    auth: "FAQ"
  }
];
