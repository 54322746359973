import { Box, Grid } from "@mui/material";
import { AvailLANG, T2EContext, T2EMessage, ZChat } from "@types";
import { useState, useEffect } from "react";
import { HStack, VStack } from "v2/Components";
import { contextAPI } from "./apis";
import { ZCContextButton } from "./ZCContextButton";

export interface IZCContextProps {
  typingDisabled: boolean;
  inQR: boolean;
  messages: ZChat[];
  onMessageUpdate?: (messages: ZChat | any) => void;
  quickReplies: never[];
  typing: boolean;
  detect: {
    Entities: any[];
    Semantics: any[];
    Context?: T2EContext[];
    ContextAction: string;
  };
  sessionID: string;
  lang: AvailLANG;
  children?: React.ReactNode;
}

export function useStateContext(detect: { Entities: any[]; Semantics: any[]; Context?: T2EContext[]; ContextAction: string }) {
  let [stateContexts, setStateContexts] = useState<T2EContext[]>([]);
  useEffect(() => {
    if (!detect?.Context) return setStateContexts([]);

    const { Context } = detect;
    const contextToDisplay = Context.filter((c) => c.value);
    setStateContexts(contextToDisplay);
  }, [detect?.Context]);

  return { stateContexts, setStateContexts };
}

export function ZCContextPanel({ children, detect, onMessageUpdate, lang = "TC", ...props }: IZCContextProps) {
  const { stateContexts, setStateContexts } = useStateContext(detect);
  if (!stateContexts.length) return <></>;

  async function handleDelete(context: T2EContext) {
    const actionId = detect.ContextAction;
    const contexts = stateContexts.filter((c) => c.alias !== context.alias);
    const res = await contextAPI.update(contexts, props.sessionID, actionId);
    if (onMessageUpdate) onMessageUpdate(res);
    //remove from state
    setStateContexts(contexts);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 1,
        width: "100%",
        py: 1,
      }}
    >
      {stateContexts.map((_c, i) => {
        return <ZCContextButton context={_c} onDelete={handleDelete} key={i} lang={lang} />;
      })}
    </Box>
  );
}
