import _ from "lodash";
import { ValidationError } from "./ValidationError";
import { TValidationType } from "./TValidationType";

export type IValidationRules = {
  [key in TValidationType]: (value: any) => {
    valid: boolean;
    message?: ValidationError;
  };
};

export const ValidationRules: IValidationRules = {
  required: (value) => {
    return {
      valid: !_.isEmpty(value),
      message: ValidationError.REQUIRED,
    };
  },
  email: (value) => {
    return {
      valid: !value || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value),
      message: ValidationError.EMAIL,
    };
  },
  number: (value) => {
    return {
      valid: !value || !isNaN(Number(value)),
      message: ValidationError.NUMBER,
    };
  },
  plain: (value) => {
    return {
      valid: !value || /^[a-zA-Z0-9_]+$/i.test(value),
      message: ValidationError.PLAIN,
    };
  },
  plainSpace: (value: string) => {
    return {
      valid: !value || /^[a-zA-Z0-9_ ]+$/i.test(value),
      message: ValidationError.PLAIN_SPACE,
    };
  },
};
