import { Typography } from "@mui/material";

const Info = [
  {
    tabs: [
      {
        label: "Basic Info",
        page: [
          {
            label: "Conversation ID",
            name: "_id",
            format: "text",
            readOnly: true,
          },
          {
            label: "Session ID",
            name: "sessionId",
            format: "text",
          },
          {
            label: "Chatbot Version",
            name: "version",
            format: "text",
          },
          {
            label: "Receive Time",
            name: "in_time",
            format: "text",
          },
          {
            label: "Reply Time",
            name: "out_time",
            format: "text",
          },
          {
            label: "Proccess Time (s)",
            name: "process_time",
            format: "text",
          },
          {
            label: "Channel",
            name: "channel",
            format: "text",
          },
          {
            label: "Message Content",
            name: "input",
            format: "text",
          },
          {
            label: "Message Type",
            name: "inputType",
            format: "text",
          },
          {
            label: "Language",
            name: "language",
            format: "text",
          },
          {
            reqLevel: 0,
            accessizo: [
              {
                label: "Workspace",
                name: "workspace",
                format: "text",
              },
            ],
          },
          {
            label: "Intent",
            name: "intent",
            format: "text",
          },
          {
            label: "Direct Answer",
            name: "__ans",
            format: "text",
          },
          {
            label: "LiveChat",
            name: "live_chat",
            format: "bool",
          },
          {
            label: "Change Topic",
            name: "__change",
            format: "bool",
          },
          {
            reqLevel: 0,
            accessizo: [
              {
                label: "Template",
                name: "__tans",
                format: "text",
              },
              {
                label: "Function",
                name: "__func",
                format: "text",
              },
              {
                label: "Parameters",
                name: "__param",
                format: "text",
              },
            ],
          },
        ],
      },
      {
        label: "Details",
        page: [
          {
            label: "Intents",
            name: "intents",
            headerStyle: "outlined",
            array: [
              {
                label: "Intent",
                name: "intent",
                format: "text",
              },
              {
                label: "Confidence",
                name: "confidence",
                format: "text",
              },
            ],
          },
          {
            label: "Entities",
            name: "entities",
            headerStyle: "outlined",
            array: [
              {
                label: "",
                name: "location",
                format: "display",
                Custom: (data, field, state) => {
                  let { cssPrefix } = state;
                  return <div className={cssPrefix + " zrf-field-data"}>{data.input && field && data.input.substring(field[0], field[1])}</div>;
                },
              },
              {
                label: "Entity",
                name: "entity",
                format: "text",
              },
              {
                label: "Value",
                name: "value",
                format: "text",
              },
              {
                label: "Confidence",
                name: "confidence",
                format: "text",
              },
            ],
          },
          {
            reqLevel: 0,
            accessizo: [
              {
                label: "Nodes Visited",
                name: "nodesVisited",
                headerStyle: "outlined",
                array: [
                  {
                    label: "",
                    name: "",
                    format: "text",
                  },
                ],
              },
              {
                label: "Detected Keywords",
                name: "detect.Entities",
                headerStyle: "outlined",
                array: [
                  {
                    label: "",
                    name: "",
                    format: "display",
                    Custom: (data, field, state) => {
                      let { cssPrefix } = state;
                      return <div className={cssPrefix + " zrf-field-data"}>{data.input && field && data.input.substring(field.from, field.to)}</div>;
                    },
                  },
                  {
                    label: "Name",
                    name: "name",
                    format: "text",
                  },
                  {
                    label: "Pattern",
                    name: "pattern",
                    format: "text",
                  },
                  {
                    label: "Value",
                    name: "value",
                    format: "display",
                    Custom: (data, field, state) => {
                      return field ? JSON.stringify(field) : "";
                    },
                  },
                ],
              },
              {
                label: "Detected Semantics",
                name: "detect.Semantics",
                headerStyle: "outlined",
                array: [
                  {
                    label: "",
                    name: "",
                    format: "display",
                    Custom: (data, field, state) => {
                      let { cssPrefix } = state;
                      return <div className={cssPrefix + " zrf-field-data"}>{data.input && field && data.input.substring(field.from, field.to)}</div>;
                    },
                  },
                  {
                    label: "Name",
                    name: "name",
                    format: "text",
                  },
                  {
                    label: "Pattern",
                    name: "pattern",
                    format: "text",
                  },
                  {
                    label: "Value",
                    name: "value",
                    format: "display",
                    Custom: (data, field, addOns) => {
                      return field ? JSON.stringify(field) : "";
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const SuggestedIntent = [
  {
    label: "Input",
    name: "input",
    format: "display",
    readOnly: true,
    Custom: (data, feild, addOns) => {
      return <Typography variant="subtitle1">{addOns?.input}</Typography>;
    },
  },
  {
    label: "Suggested Intent",
    name: "suggested",
    format: "select",
    selectStyle: "dropdown",
    selectRef: "intents",
    selectCap: "caption",
    selectVal: "_id",
    placeholder: "Please select intent...",
    validate: ["required"],
  },
  {
    label: "Remarks",
    name: "remarks",
    format: "textarea",
  },
];

let all = {
  Info,
  SuggestedIntent,
};

export default all;
