import { DragIndicator } from "@mui/icons-material";
import { Theme } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { Draggable } from "react-beautiful-dnd";

interface IDragableListProps {
  id: string;
  index: number;
  item: any;
  children: (item: any, index: number) => JSX.Element;
  sx?: SxProps<Theme>;
  showDragIndicator?: boolean;
}
export function DragableList(props: IDragableListProps) {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {(_provided, _snapshot) => (
        <Box //
          ref={_provided.innerRef}
          {..._provided.draggableProps}
          {..._provided.dragHandleProps}
          sx={
            {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",

              p: 1,
              ...props.sx,
            } as SxProps
          }
        >
          {props.children(props.item, props.index)}
          {props.showDragIndicator && <DragIndicator sx={{ color: "grey.400", width: "50px" }} />}
        </Box>
      )}
    </Draggable>
  );
}
