import { Component } from "react";
import PropsType from "prop-types";

import { Box, Typography } from "@mui/material";

import schema from "./schema";
import datalink from "./datalink";

import Datumizo from "IZOArc/LabIZO/Datumizo/Datumizo";
import { VStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, Authority, ColorX } from "IZOArc/STATIC";

/**
 * @augments {Component<Props, State>}
 */
class SysUser extends Component {
  static propTypes = {
    addOns: PropsType.object,
  };

  static defaultProps = {
    addOns: {},
  };
  static userAccess = Authority.IsAccessibleQ("System.User");

  constructor() {
    super();
    this.state = {
      title: "User Access Control",
      serverSidePagination: false,
      base: {
        title: "User",
        exportDoc: "users",
        schema: schema,
        reqAuth: "System.User",

        noDefaultTable: false,
        noDefaultButtons: false,

        tablizo: {
          columnsToolbar: SysUser.userAccess,
          filterToolbar: SysUser.userAccess,
          densityToolbar: SysUser.userAccess,
          exportToolbar: false,
          density: "standard", //compact, standard, comfortable
          defaultPageSize: 50,
          showSelector: false,
        },

        formizo: {},

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Add: {
            title: "Add User",
            url: datalink.Request.Add,
            success: "User Added Successfully",
            fail: "User Add Failed: ",
            schema: schema.Add,
            buttons: ["Clear", "Submit"],
            onSubmit: "Add",
          },
          Delete: {
            title: "Delete this User?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Delete,
            success: "User Deleted Successfully.",
            fail: "User Delete Failed: ",
            onSubmit: "Delete",
          },
          Edit: {
            title: "Edit User ",
            url: datalink.Request.Edit,
            success: "User Edited Successfully",
            fail: "User Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },
          Info: {
            title: "User Access Control ",
            url: datalink.Request.Info,
            success: "User Access Control Load Successfully",
            fail: "User Access Control Load Failed: ",
            schema: schema.Info,
            readOnly: true,
          },
          Import: {
            title: "User Import",
            content: "",
            url: datalink.Request.Import,
            success: "User Imported Successfully.",
            fail: "User Import Failed: ",
            schema: schema.ImportFormat,
            replace: false,
          },
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },
          DeleteBulk: {
            title: (n) => "Delete these " + n + " User?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.DeleteBulk,
            success: "User Deleted Successfully.",
            fail: "User Delete Failed: ",
            onSubmit: "DeleteBulk",
          },
        },

        buttons: {
          inline: [
            // { icon: "edit", func: "Edit", caption: "Edit", reqAuth: "System.User", reqFunc: "Edit" },
            { icon: "info", func: "Info", caption: "Details" },
            // { icon: "delete", func: "Delete", caption: "Delete", reqAuth: "System.User", reqFunc: "Delete" },
            // {
            //   icon: <LockOpen />,
            //   func: async (id) => {
            //     try {
            //       let url = DOMAIN + datalink.Request.Unlock;
            //       let payloadOut = {
            //         JWT: store.user.JWT,
            //         data: {
            //           docID: id,
            //         },
            //       };
            //       let res = await axios.post(url, payloadOut);
            //       let { Success, payload } = res.data;

            //       if (Success === true) {
            //         this.MountDatumizo && this.MountDatumizo.Reload(true);
            //       } else {
            //         ErrorX.Handle(res.data);
            //       }
            //     } catch (e) {
            //       ErrorX.Handle(e);
            //     }
            //   },
            //   caption: "Unlock",
            //   disableFunc: (id, row) => !row.errorAttempt,
            //   reqAuth: "System.User",
            // },
          ],
          // left: [{ icon: "add", func: "Add", caption: "Add User", reqAuth: "System.User", reqFunc: "Add" }],
          // right: [
          // { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqAuth: "System.User", reqFunc: "Delete", theme: "caution" },
          //{ icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
          //{ icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
          // ],
        },
      },
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(SysUser.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  render() {
    let { addOns } = this.props;
    let { base, serverSidePagination, title } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo base={base} serverSidePagination={serverSidePagination} onMounted={this.onMountDatumizo} addOns={addOns} languageSelector={false} />
      </VStack>
    );
  }
}

export default SysUser;
