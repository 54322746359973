import { HMarquee } from "IZOArc/LabIZO/Animatizo";
import _ from "lodash";
import React, { useEffect } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import "./GPie.scss";

const GPie = ({ data, toPercent = false, cx = "50%", cy = "50%" }) => {
  let pieColor = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#003f5c", "#58508d", "#bc5090", "#ff6361", "#ffa600"];
  const [alternateLegend, setAlternateLegend] = React.useState(false);
  const [pieData, setPieData] = React.useState(data);
  useEffect(() => {
    let alternateData = _.map(data, (o) => {
      if (!o.alternateNames || o.alternateNames.length === 0) return o;
      //swap o.name with o.alternateName
      let alternateNames = [...o.alternateNames, o.name];
      o.name = o.alternateNames[0];
      alternateNames.shift();
      o.alternateNames = alternateNames;
      return o;
    });
    setPieData(alternateData);
  }, [alternateLegend, data]);

  if (!data) return <div />;

  const maxLegend = 18;
  const sumValue = _.sumBy(data, (o) => o.value) || 1;
  const Round = (o) => Math.round(o * 100);
  return (
    <div className="GPie">
      <ResponsiveContainer className="PieContainer" height="100%" width="100%">
        <PieChart className="PieChart" width={"100%"} height={"100%"}>
          <Tooltip
            isAnimationActive={false}
            formatter={(value, name, props) => {
              if (toPercent) {
                return [Round(value / sumValue) + "%", name];
              } else {
                return [value, name];
              }
            }}
          />
          <Pie data={pieData} innerRadius={"55%"} outerRadius={"90%"} fill="#8884d8" paddingAngle={5} dataKey="value" cx={cx} cy={cy}>
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={pieColor[index % pieColor.length]} />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            align="right"
            verticalAlign={"top"}
            wrapperStyle={{ right: "10px", top: "10%" }}
            onClick={(e) => {
              setAlternateLegend(!alternateLegend);
            }}
            width={"35%"}
            formatter={(value, entry) => {
              const { color } = entry;
              if (value && value.length >= maxLegend) {
                return (
                  <HMarquee width="120px" style={{ overflowY: "hidden" }}>
                    <p style={{ color }}>{value}</p>
                  </HMarquee>
                );
              }
              return <span style={{ color }}>{value}</span>;
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GPie;
