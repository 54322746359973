import "./ScoreCard.scss";

import { HStack } from "IZOArc/LabIZO/Stackizo";
import { ColorX } from "IZOArc/STATIC";
import htmlParser from "html-react-parser";
import _ from "lodash";

const ScoreCard = ({ title, values, remarks }) => {
  if (values && !_.isString(values)) values = JSON.stringify(values);
  return (
    <div className="ScoreCard" style={{ background: ColorX.GetColorCSS("CardBG1") }}>
      <h3>{values && htmlParser(values)}</h3>
      <HStack width="100%">
        <p>{title && htmlParser(title)}</p>
        <p style={{ color: ColorX.GetColorCSS("Primary"), paddingLeft: 5 }}>{remarks && "  -  " + htmlParser(remarks)}</p>
      </HStack>
    </div>
  );
};

export default ScoreCard;
