import React, { Component } from "react";

import { Box, Typography } from "@mui/material";

import schema from "./schema";
import datalink from "./datalink";

import Datumizo from "IZOArc/LabIZO/Datumizo/Datumizo";
import { VStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, ColorX, Authority } from "IZOArc/STATIC";

class WhatsappPermissionList extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "Whatsapp Permission Control",
      serverSidePagination: false,
      base: {
        title: "Whatsapp Permission Control",
        exportDoc: "whatsapp_permission_list",
        schema: schema,
        reqAuth: "WhatsappPermissionList",

        tablizo: {
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
          showSelector: true,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {    
          Add: {
            title: "Add Number",
            url: datalink.Request.Add,
            success: "Number Added Successfully",
            fail: "Number Add Failed: ",
            schema: schema.Add,
            buttons: ["Clear", "Submit"],
            onSubmit: "Add",
          },    
          Delete: {
            title: "Delete this Number?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Delete,
            success: "Number Deleted Successfully.",
            fail: "Number Delete Failed: ",
            onSubmit: "Delete",
          },
          Edit: {
            title: "Edit Number",
            url: datalink.Request.Edit,
            success: "Number Edited Successfully",
            fail: "Bynver Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },            
          DeleteBulk: {
            title: (n) => "Delete these " + n + " users?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.DeleteBulk,
            success: "Users Deleted Successfully.",
            fail: "Users Delete Failed: ",
            onSubmit: "DeleteBulk",
          },
          Export: {
            url: datalink.Request.Export,
          },
          Import: {
            title: "Number Import",
            content: "",
            url: datalink.Request.Import,            
            success: "Number Imported Successfully.",
            fail: "Number Import Failed: ",
          },
        },

        buttons: {
          inline: [
            { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },            
          ],
          inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete", reqLevel: 2 }],
          left: [{ icon: "add", func: "Add", caption: "Add User", reqFunc: "Add", reqLevel: 2 }],
          right: [
            { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution", reqLevel: 2 },
            { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export", reqLevel: 0 },
            { icon: "import", func: "Import", caption: "Import", reqFunc: "Import", reqLevel: 0 },
          ],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("WhatsappPermissionList");
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(WhatsappPermissionList.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }  

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  render() {
    let { base, serverSidePagination, title } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo base={base} serverSidePagination={serverSidePagination} />
      </VStack>
    );
  }
}

export default WhatsappPermissionList;
