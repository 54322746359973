import React, { Component } from 'react';

import _ from 'lodash';

import Avatar3D from './Avatar3D';

import { Accessor } from 'IZOArc/STATIC';
import { StyledButton } from 'IZOArc/LabIZO/Stylizo';
import { HStack, Spacer, VStack } from 'IZOArc/LabIZO/Stackizo';

class AniTest extends Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(AniTest.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), callback);
  }

  onMountAvatar = (callbacks) => {
    this.MountAvatar = callbacks;
    this.GetActions();
  }

  GetActions = () => {
    let actions = this.MountAvatar.GetActions();
    if(actions){
      this.setState({
        actions: actions
      });
    }else{
      setTimeout(() => {this.GetActions()}, 3000);
    }
  }

  Play = (ani, loop = false) => {
    if(!this.MountAvatar) return;
    this.MountAvatar.Play(ani, loop);
  }

  renderAniList(){
    let {actions} = this.state;
    let rtn = [];
    _.map(actions, (o, i) => {
      rtn.push(
        <StyledButton 
          key={i}
          onClick={() => {this.Play(o);}}
          theme={{
            textTransform: "none"
          }}>
          {o}
        </StyledButton>
      );
    });

    return (
      <VStack style={{maxHeight: 500, width: 200, overflow: "auto"}}>
        {rtn}
      </VStack>
    );
  }

  render(){
    return (
      <HStack height={470}>
        <VStack style={{
          position: "relative",
          right: 0
          }}>
          <Spacer/>
          <Avatar3D onMounted={this.onMountAvatar}/>
        </VStack>
        {this.renderAniList()}
      </HStack>
    );
  }

}

export default AniTest;
