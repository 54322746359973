import { DeleteForever } from "@mui/icons-material";
import { IconButton } from "@mui/material";

interface IDeleteButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const DeleteButton = ({ onClick = () => {}, ...props }: IDeleteButtonProps) => {
  return (
    <IconButton onClick={onClick} color="error" {...props}>
      <DeleteForever />
    </IconButton>
  );
};
