export const Request = {
  Info: "/Tables/Talk2VA/Info",
  List: "/Tables/Talk2VA/List",
  Add: "/Tables/Talk2VA/Add",
  Delete: "/Tables/Talk2VA/Delete",
  Edit: "/Tables/Talk2VA/Edit",
  ExportAll: "/Tables/Talk2VA/ExportAll",
  Sync: "/Tables/Talk2VA/Sync",
  Learn: "/Tables/Talk2VA/Learn",
  Pending: "/Tables/Talk2VA/Pending",
  Reject: "/Tables/Talk2VA/Reject",
  Pass: "/Tables/Talk2VA/Pass",
  DeleteBulk: "/Tables/Talk2VA/DeleteBulk",
};
