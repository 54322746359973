import { SxProps, Typography } from "@mui/material";
import { ColorX } from "IZOArc/STATIC";

export const BodyTitle = ({ children, sx = {}, ...props }: { children: string | JSX.Element; sx?: SxProps }) => {
  return (
    <Typography sx={{ fontWeight: "bold", color: ColorX.GetColorCSS("Primary"), py: 2, ...sx }} {...props}>
      {children}
    </Typography>
  );
};
