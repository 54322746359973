import { Component } from "react";

import _ from "lodash";
import moment from "moment";
import axios from "axios";
import { Typography, Button } from "@mui/material";
import { SaveOutlined, Warning } from "@mui/icons-material";

import schema from "./schema";
import { DOMAIN } from "config/config";

import Tablizo from "IZOArc/LabIZO/Tablizo";
import Accessizo from "IZOArc/LabIZO/Accessizo";
import { Accessor, ColorX, store, ErrorX } from "IZOArc/STATIC";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import Dropzone from "./_gears/Dropzone";
import TagForm from "./_gears/TagForm";
import "./SysBnR.scss";
import DownloadForm from "./_gears/DownloadForm";
class SysBnR extends Component<any, any> {
  static propTypes = {};

  static defaultProps = {
    apis: {
      db: {
        import: "/Data/Database/Import",
      },
      watsons: {
        import: "/Data/Watsons/Import",
      },
    },
  };

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates(() => {
      this._GetInfo();
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(SysBnR.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback?: any) => {
    this.setState(
      (state: any, props: any) => ({
        ...props,
      }),
      callback
    );
  };

  _dateStrToMomemt = (str: any) => {
    return moment(str, "YYYYMMDDHHmmss");
  };

  _momentToDisplay = (obj: any) => {
    return obj.format("DD MMM, YYYY HH:mm:ss");
  };

  _GetInfo = async () => {
    let url = DOMAIN + "/Data/General/Info";
    let payloadOut = {
      JWT: store.user.JWT,
    };

    try {
      let res = await axios.post(url, payloadOut);

      let { Success, payload } = res.data;
      if (Success === true) {
        let { dbs, LastBackup, Backups, BackupDetails, include, Imports, LastImport } = payload;
        dbs = _.map(dbs, (o, i) => {
          return {
            name: o,
            included: include.includes(o),
          };
        });

        Backups = _.map(Backups, (o, i) => {
          return {
            _id: o,
            name: this._momentToDisplay(this._dateStrToMomemt(o)),
            tag: BackupDetails && BackupDetails[i]?.tag,
            fileloc: BackupDetails && BackupDetails[i]?.fileloc,
          };
        });

        Imports = _.map(Imports, (o, i) => {
          return {
            _id: o,
            name: this._momentToDisplay(this._dateStrToMomemt(o)),
          };
        });

        this.setState({
          dbs: dbs,
          LastBackup: LastBackup,
          Backups: Backups,
          LastImport: LastImport,
          Imports: Imports,
          includeDB: include,
        });
      } else {
        store.Alert("Server return error.", "error");
      }
    } catch (e) {
      console.log(e);
      store.Alert("Cannot connect to Server.", "error");
    }
  };

  _Backup = {
    onClick: () => {
      store.Ask("Backup", "Backup System?", this._Backup.onSubmit);
    },
    onSubmit: async () => {
      let url = DOMAIN + "/Data/General/Backup";
      let payloadOut = {
        JWT: store.user.JWT,
      };

      try {
        let res = await axios.post(url, payloadOut);
        console.log(res);
        let { Success } = res.data;
        if (Success === true) {
          store.Alert("Backup Successful.", "success");

          this._GetInfo();
        } else {
          this._Backup.onError(res.data);
        }
      } catch (e) {
        this._Backup.onError(e);
      }
    },
    onError: (e: any) => {
      ErrorX.Handle(e);
    },
    onDelete: {
      onClick: (datestr: string) => {
        let mObj = this._dateStrToMomemt(datestr);
        let str = this._momentToDisplay(mObj);
        store.Ask("Delete", "Delete Backup " + str + "?", async () => {
          await this._Backup.onDelete.onSubmit(datestr);
        });
      },
      onSubmit: async (datestr: string) => {
        let mObj = this._dateStrToMomemt(datestr);
        let str = this._momentToDisplay(mObj);
        let url = DOMAIN + "/Data/General/Delete";
        let payloadOut = {
          JWT: store.user.JWT,
          data: {
            datestr: datestr,
          },
        };

        try {
          let res = await axios.post(url, payloadOut);
          console.log(res);
          let { Success } = res.data;
          if (Success === true) {
            store.Alert(str + " Successfully Deleted.", "success");
            this._GetInfo();
          } else {
            this._Backup.onDelete.onError(res.data);
          }
        } catch (e) {
          this._Backup.onDelete.onError(e);
        }
      },
      onError: (e: any) => {
        ErrorX.Handle(e);
      },
    },

    onTag: {
      onClick: (row: any) => {
        this.setState({ TagFormRow: row });
        this.setState({ showBackupTagForm: true });
        //ok, proceed
      },
      onClose: () => {
        this.setState({ showBackupTagForm: false });
      },
      onSubmit: async (row: any) => {
        let url = DOMAIN + "/Data/General/AddTag";
        let payloadOut = {
          JWT: store.user.JWT,
          data: row,
        };

        try {
          let res = await axios.post(url, payloadOut);
          console.log(res);
          let { Success } = res.data;
          if (Success === true) {
            this._GetInfo();
            this._Backup.onTag.onClose();
          } else {
            this._Backup.onTag.onError(res.data);
          }
        } catch (e) {
          this._Backup.onTag.onError(e);
        }
      },
      onError: (payload: any) => {
        let msg = payload.message || "";
        store.Alert(msg, "error");
      },
    },
  };

  _IncToggle = async (dbname: string, f: any) => {
    console.log(dbname, f);
    let url = DOMAIN + "/Config/Database/Include";
    let payloadOut = {
      JWT: store.user.JWT,
      data: {
        dbname: dbname,
        include: f,
      },
    };

    try {
      let res = await axios.post(url, payloadOut);
      console.log(res);
      let { Success } = res.data;
      if (Success === true) {
        this._GetInfo();
      } else {
        store.Alert("Cannot Update Doc Status.", "error");
      }
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };

  _Restore = {
    onClick: (datestr: string) => {
      let mObj = this._dateStrToMomemt(datestr);
      let str = this._momentToDisplay(mObj);
      store.Ask("Restore", "Restore System to " + str + "?<br/>The current state of the system will be backup-ed automatically.", async () => {
        await this._Restore.onSubmit(datestr);
      });
    },
    onSubmit: async (datestr: string) => {
      let mObj = this._dateStrToMomemt(datestr);
      let str = this._momentToDisplay(mObj);
      let url = DOMAIN + "/Data/General/Restore";
      let payloadOut = {
        JWT: store.user.JWT,
        data: {
          datestr: datestr,
        },
      };

      try {
        let res = await axios.post(url, payloadOut);
        console.log(res);
        let { Success } = res.data;
        if (Success === true) {
          store.Alert("Restore Successful to \n" + str + ".", "success");
          this._GetInfo();
        } else {
          this._Restore.onError(res.data);
        }
      } catch (e: any) {
        this._Restore.onError(e);
      }
    },
    onError: (e: any) => {
      ErrorX.Handle(e);
    },
  };

  renderBackup() {
    let { LastBackup } = this.state;
    return (
      <HStack justifyContent="flex-start" gap={1}>
        <Button onClick={this._Backup.onClick} style={{ width: "200px", backgroundColor: ColorX.GetColorCSS("Decorate1"), color: ColorX.GetColorCSS("ButtonText1") }} className="backup-btn">
          <HStack gap={5}>
            <SaveOutlined />
            <Typography>Backup</Typography>
          </HStack>
        </Button>
        <Typography>Last Backup:</Typography>
        <Typography style={{ fontWeight: "bold" }}>{LastBackup || "No Backup Available."}</Typography>
      </HStack>
    );
  }

  renderImport() {
    let { LastImport } = this.state;

    return (
      <VStack justifyContent="flex-start" gap={1} padding={2}>
        <HStack justifyContent="center" gap={1}>
          <Button onClick={this._Import.onClick} style={{ width: "200px", backgroundColor: ColorX.GetColorCSS("Decorate1"), color: ColorX.GetColorCSS("ButtonText1") }}>
            <HStack gap={5}>
              <Warning style={{ color: ColorX.GetColorCSS("yellow") }} />
              <Typography>Deploy</Typography>
            </HStack>
          </Button>
          <Typography>Last Import:</Typography>
          <Typography style={{ fontWeight: "bold" }}>{LastImport || "No Backup Available."}</Typography>
        </HStack>
        {this.renderVersions("import")}
        <Spacer />
      </VStack>
    );
  }

  renderOperations() {
    return (
      <VStack justifyContent="flex-start" gap={1} padding={2}>
        {this.renderBackup()}
        {this.renderVersions()}
        <Spacer />
      </VStack>
    );
  }

  renderDatabases() {
    let { dbs } = this.state;
    let { reqFunc } = schema;
    return (
      <Accessizo reqLevel={0} auth={store.user.authority} level={store.user.level || 0} reqFunc={reqFunc.Edit}>
        <VStack paddingY={2}>
          <Tablizo
            width={400}
            height="100%"
            density="compact"
            auth={store.user.authority}
            level={store.user.level}
            rowIdAccessor="name"
            schema={schema.database}
            showSelector={false}
            data={dbs}
            addOns={{
              onToggle: this._IncToggle,
            }}
          />
        </VStack>
      </Accessizo>
    );
  }

  /**
   *
   * @param {string} type "backup" || "import"

   */
  renderVersions(type = "backup") {
    let { Backups, Imports } = this.state;
    let data = Backups;
    if (type === "import") data = Imports;

    if (!data) return <></>;

    return (
      <Tablizo
        width={500}
        height="100%"
        density="compact"
        auth={store.user.authority}
        level={store.user.level}
        schema={schema.restore}
        showSelector={false}
        data={data}
        addOns={{
          Restore: type === "backup" ? this._Restore.onClick : undefined,
          Delete: type === "backup" ? this._Backup.onDelete.onClick : this._Import.onDelete.onClick,
          Download: type === "backup" ? this._Download.onClick : undefined,
          Tag: type === "backup" ? this._Backup.onTag.onClick : this._Import.onTag.onClick,
        }}
      />
    );
  }

  _Import = {
    onClick: (datestr: any) => {
      const { acceptedDBFiles, acceptedWatsonFiles } = this.state;
      //no file in the dropzone
      if (!acceptedDBFiles && !acceptedWatsonFiles) {
        store.Alert("No files to import", "error");
        store.clearAsk();
        return;
      }
      //more than 1 file
      if (acceptedDBFiles?.length > 1 || acceptedWatsonFiles?.length > 1) {
        store.Alert("Please upload only 1 .gz file at a time.", "error");
        store.clearAsk();
        return;
      }
      //ok, proceed
      store.Ask("Import", "Import and Deploy System?.", async () => {
        await this._Import.onSubmit();
      });
    },
    _getUploadFormData: (acceptedFiles: any) => {
      let payloadOut = {
        data: {
          upload: acceptedFiles[0],
        },
        schema: [],
        replace: false,
        JWT: store.user.JWT,
        addOns: [],
      };

      let upload = new FormData();
      upload.append("data", JSON.stringify(payloadOut.data || {}));
      upload.append("schema", JSON.stringify(payloadOut.schema || {}));
      upload.append("addOns", JSON.stringify(payloadOut.addOns || {}));
      upload.append("replace", JSON.stringify(payloadOut.replace || {}));
      upload.append("JWT", store.user.JWT || "");
      if (payloadOut.data.upload) {
        upload.append("upload", payloadOut.data.upload, payloadOut.data.upload.name);
      }
      return upload;
    },

    onSubmit: async () => {
      const { acceptedDBFiles, acceptedWatsonFiles } = this.state;

      store.SetAskLoading(true);
      console.log("submit _Import");

      let { apis } = this.props;

      try {
        let url = DOMAIN + apis.db.import;
        if (acceptedDBFiles) {
          const dbUpload = this._Import._getUploadFormData(acceptedDBFiles);
          let { data } = await axios.post(url, dbUpload);
          console.log(apis.db, data);

          if (!data.Success) throw new Error(data.Message);
          this._Import.onSuccess(data.payload);
        }
        if (acceptedWatsonFiles) {
          url = DOMAIN + apis.watsons.import;
          const watsonUpload = this._Import._getUploadFormData(acceptedWatsonFiles);
          let { data } = await axios.post(url, watsonUpload);
          console.log(apis.watson, data);

          if (!data.Success) throw new Error(data.Message);
          this._Import.onSuccess(data.payload);
        }
      } catch (e) {
        this._Import.onFail(e);
      }
    },

    onSuccess: (payload: any) => {
      if (!_.isEmpty(payload.error)) {
        let msg = _.map(payload.error, (o, i) => "ID (" + o.id + "): " + o.error);
        store.Alert("_Import Successfully with warning: \n" + msg.join("\n"), "warning");
      } else {
        store.Alert("_Import Successfully.", "success");
      }
      store.clearAsk();
    },

    onFail: (payload: any) => {
      let msg = payload.message || "";
      store.Alert(msg, "error");
      store.clearAsk();
    },

    onDelete: {
      onClick: (datestr: string) => {
        let mObj = this._dateStrToMomemt(datestr);
        let str = this._momentToDisplay(mObj);
        store.Ask("Delete", "Delete Backup " + str + "?", async () => {
          await this._Import.onDelete.onSubmit(datestr);
        });
      },
      onSubmit: async (datestr: string) => {
        let mObj = this._dateStrToMomemt(datestr);
        let str = this._momentToDisplay(mObj);
        let url = DOMAIN + "/Data/General/Delete";
        let payloadOut = {
          JWT: store.user.JWT,
          data: {
            datestr: datestr,
            dir: "import",
          },
        };

        try {
          let res = await axios.post(url, payloadOut);
          console.log(res);
          let { Success } = res.data;
          if (Success === true) {
            store.Alert(str + " Successfully Deleted.", "success");
            this._GetInfo();
          } else {
            this._Backup.onDelete.onError(res.data);
          }
        } catch (e) {
          this._Backup.onDelete.onError(e);
        }
      },
      onError: (e: any) => {
        ErrorX.Handle(e);
      },
    },

    onTag: {
      onClick: (row: any) => {
        this.setState({ TagFormRow: row });
        this.setState({ showImportTagForm: true });
      },
      onClose: () => {
        this.setState({ showImportTagForm: false });
      },
      onSubmit: async (row: any) => {
        let url = DOMAIN + "/Data/General/AddTag";
        let payloadOut = {
          JWT: store.user.JWT,
          data: { ...row, dir: "import" },
        };

        try {
          let res = await axios.post(url, payloadOut);
          console.log(res);
          let { Success } = res.data;
          if (Success === true) {
            this._GetInfo();
            this._Backup.onTag.onClose();
          } else {
            this._Backup.onTag.onError(res.data);
          }
        } catch (e) {
          this._Backup.onTag.onError(e);
        }
      },
    },
  };

  _Download = {
    onClick: (fileloc: { db?: string; watsons?: string }) => {
      this.setState({ fileloc });
      this.setState({ showDownloadForm: true });
    },
  };

  render() {
    return (
      <HStack justifyContent="flex-start" height="100%" gap={1} paddingX={2}>
        {this.renderDatabases()}
        {this.renderOperations()}
        <VStack>
          <Typography>Database File</Typography>
          <Dropzone onDrop={(acceptedFiles) => this.setState({ acceptedDBFiles: acceptedFiles })} />
          <Typography>NLP File</Typography>
          <Dropzone onDrop={(acceptedFiles) => this.setState({ acceptedWatsonFiles: acceptedFiles })} />
        </VStack>
        <TagForm
          //
          title={"Backup Tag"}
          open={this.state.showBackupTagForm}
          onClose={this._Backup.onTag.onClose}
          onSubmit={this._Backup.onTag.onSubmit}
          row={this.state.TagFormRow}
        />
        <TagForm
          //
          title={"Import Tag"}
          open={this.state.showImportTagForm}
          onClose={this._Import.onTag.onClose}
          onSubmit={this._Import.onTag.onSubmit}
          row={this.state.TagFormRow}
        />

        {this.state.showDownloadForm && <DownloadForm open={this.state.showDownloadForm} onClose={() => this.setState({ showDownloadForm: false })} fileloc={this.state.fileloc} />}
        {this.renderImport()}
      </HStack>
    );
  }
}

export default SysBnR;
