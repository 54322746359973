import React, { useState } from "react";
import { Box, Button, Link, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { ColorX } from "IZOArc/STATIC";
import { VStack } from "v2/Components";
import { DOMAIN } from "config/config";

const Popup = ({ fileloc, open, onClose }: IPopUpProps) => {
  return (
    <Dialog open={open ? true : false} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="tag title">Downloader</DialogTitle>

      <Box sx={{ p: 3 }}>
        <VStack>
          {fileloc.db && (
            <Button variant="contained" color="primary" component={Link} href={DOMAIN + fileloc.db.replace("./", "/")} target="_blank" download>
              Download Database
            </Button>
          )}
          {fileloc.watsons && (
            <Button variant="contained" color="secondary" component={Link} href={DOMAIN + fileloc.watsons.replace("./", "/")} target="_blank" download>
              Download Watsons
            </Button>
          )}
        </VStack>
      </Box>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
interface IPopUpProps {
  open: boolean;
  onClose: () => void;
  fileloc: {
    db?: string;
    watsons?: string;
  };
}

const DownloadForm = ({ open, onClose, fileloc }: IProps) => {
  if (!fileloc) return <Typography>Nothing to download</Typography>;
  return <Popup fileloc={fileloc} open={open} onClose={onClose} />;
};

interface IProps {
  open: boolean;
  onClose: () => void;
  fileloc: {
    db?: string;
    watsons?: string;
  };
}

export default DownloadForm;
