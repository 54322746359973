import "./ScoreCard.scss";

import { HStack } from "IZOArc/LabIZO/Stackizo";
import { ColorX } from "IZOArc/STATIC";
import htmlParser from "html-react-parser";
import _ from "lodash";

const ScoreCardVar = ({ title, values, remarks }) => {
  if (values && !_.isString(values)) values = JSON.stringify(values);

  return (
    <div className="ScoreCard" style={{ background: ColorX.GetColorCSS("CardBG2") }}>
      <h4>{values && htmlParser(values)}</h4>
      <HStack width="100%">
        <p variant="body2">{title && htmlParser(title)}</p>
        <p variant="body2" style={{ color: ColorX.GetColorCSS("Primary"), paddingLeft: 5 }}>
          {remarks && "  -  " + htmlParser(remarks)}
        </p>
      </HStack>
    </div>
  );
};

export default ScoreCardVar;
