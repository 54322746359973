import { Component } from "react";

import "amazon-connect-streams";
import "amazon-connect-chatjs";

import { Box, Typography, Tabs, Tab } from "@mui/material";

import Messenger from "Pages/Talk2VA/_gears/Messenger";

import { CCPDomain, CCPLoginUrl, AWSRegion } from "../../config/config";
import { Accessor, ColorX, Authority, store } from "IZOArc/STATIC";
import { HStack, VStack } from "IZOArc/LabIZO/Stackizo";
import "./AWSLiveChat.scss";
import ChatHistory from "./ChatHistory";
class AWSLiveChat extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "AWS Connect LiveChat Agent",
      agent: null,
      contact: null,
      contact_list: [], // store {contactId: ""}
      chatSessions: [], // {id: "", ... }
      mountMessenger: {},
    };
  }

  componentDidMount() {
    Authority.Require("AWSLiveChat");
    this._setAllStates(() => {
      this.AWSInit();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(AWSLiveChat.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-undef
    connect.core.terminate();

    this.setState = (state, callback) => {
      return;
    };
  }

  onMountMessenger = (callbacks) => {
    let { mountMessenger, contact } = this.state;
    mountMessenger[contact.contactId] = callbacks;
    this.setState({ mountMessenger });
    this.MountMessenger = mountMessenger;
  };

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  AWSInit = () => {
    let container = document.getElementById("aws-container");

    console.log("CCPDomain", CCPDomain);
    // eslint-disable-next-line no-undef
    connect.core.initCCP(container, {
      ccpUrl: CCPDomain,
      loginUrl: CCPLoginUrl,
      loginPopup: true, // optional, defaults to `true`
      loginPopupAutoClose: true, // optional, defaults to `false`
      loginOptions: {
        // optional, if provided opens login in new window
        autoClose: true, // optional, defaults to `false`
        height: 600, // optional, defaults to 578
        width: 400, // optional, defaults to 433
        top: 0, // optional, defaults to 0
        left: 0, // optional, defaults to 0
      },
      softphone: {
        allowFramedSoftphone: true,
      },
      region: AWSRegion,
    });

    // eslint-disable-next-line no-undef
    connect.core.onInitialized(() => {
      // eslint-disable-next-line no-undef
      connect.contact(this.Contact.Subscribe);
      // eslint-disable-next-line no-undef
      connect.agent(this.Agent.Subscribe);
      // eslint-disable-next-line no-undef
      connect.ChatSession.setGlobalConfig({
        loggerConfig: {
          logger: {
            debug: (msg) => console.debug(msg), // REQUIRED, can be any function
            info: (msg) => console.info(msg), // REQUIRED, can be any function
            warn: (msg) => console.warn(msg), // REQUIRED, can be any function
            error: (msg) => console.error(msg), // REQUIRED, can be any function
          },
        },
        region: AWSRegion,
      });
    });

    // eslint-disable-next-line no-undef
    connect.core.onViewContact(this.Contact.onSwitched);
  };

  Contact = {
    Subscribe: (contact) => {
      this.setState(
        {
          temp_contact: contact,
        },
        () => {
          let { temp_contact } = this.state;
          console.log("Subscribing to events for contact");
          if (contact.getActiveInitialConnection() && contact.getActiveInitialConnection().getEndpoint()) {
            console.log("New contact is from " + contact.getActiveInitialConnection().getEndpoint().phoneNumber);
          } else {
            console.log("This is an existing contact for this agent");
          }
          console.log("Contact is from queue " + contact.getQueue().name);
          console.log("Contact attributes are " + JSON.stringify(contact.getAttributes()));
          temp_contact.onIncoming(this.Contact.onIncoming);
          temp_contact.onAccepted(this.Contact.onAccepted);
          temp_contact.onConnected(this.Contact.onConnected);
          temp_contact.onEnded(this.Contact.onEnded);
        }
      );
    },

    Add: (incoming_contact) => {
      let { contact_list } = this.state;
      this.setState({ contact: incoming_contact });
      this.setState({ contact_list: contact_list.concat(incoming_contact) }); // {contactId: ""}
    },

    Remove: (new_contact) => {
      let { contact_list, contact } = this.state;
      let contacts = contact_list.filter((item) => item.getContactId() !== new_contact.getContactId()); // updated contact_list

      this.setState({ contact_list: contacts });

      if (contact.contactId === new_contact.contactId && contacts) {
        // if contact to be removed = current contact --> change to another contact if exists
        this.setState({ contact: contacts[0] });
      }
    },

    onIncoming: (contact) => {
      if (contact) {
        console.log("[contact.onIncoming] Contact is incoming. Contact state is " + contact.getStatus().type);
      } else {
        console.log("[contact.onIncoming] Contact is incoming. Null contact passed to event handler");
      }
    },

    onAccepted: (contact) => {
      if (contact) {
        console.log("[contact.onAccepted] Contact accepted by agent. Contact state is " + contact.getStatus().type);
        let { temp_contact } = this.state;
        this.setState({ contact: temp_contact });
        this.Contact.Add(contact);
      } else {
        console.log("[contact.onAccepted] Contact accepted by agent. Null contact passed to event handler");
      }
    },

    onConnected: async (contact) => {
      if (contact) {
        console.log("[contact.onConnected] Contact connected to agent. Contact state is " + contact.getStatus().type);

        let { contact_list } = this.state;
        // eslint-disable-next-line no-undef
        let cnn = contact.getConnections().find((cnn) => cnn.getType() === connect.ConnectionType.AGENT);
        let chatSession = await cnn.getMediaController();

        chatSession.id = contact.getContactId();
        chatSession.onMessage(this.ChatSession.onMessage(contact.getContactId()));
        this.ChatSession.Add(chatSession);

        if (!contact_list.some((item) => item.getContactId() === contact.getContactId())) this.Contact.Add(contact); // if incoming contact != contact in contact_list --> add to contact_list
      } else {
        console.log("[contact.onConnected] Contact connected to agent. Null contact passed to event handler");
      }
    },

    onEnded: (contact) => {
      if (contact) {
        console.log("[contact.onEnded] Contact has ended. Contact state is " + contact.getStatus().type);
        this.Contact.Remove(contact);
        this.ChatSession.Remove(contact.getContactId());
      } else {
        console.log("[contact.onEnded] Contact has ended. Null contact passed to event handler");
      }
    },

    onSwitched: (contact) => {
      let contactId = contact?.contactId;
      console.log("Switched to contact " + contactId);
      this.setState({ contact });
    },
  };

  Agent = {
    Subscribe: (agent) => {
      this.setState(
        {
          agent: agent,
        },
        () => {
          let { agent } = this.state;
          console.log("Subscribing to events for agent " + agent.getName());
          console.log("Agent is currently in status of " + agent.getStatus().name);
          agent.onRefresh(this.Agent.onRefresh);
          agent.onRoutable(this.Agent.onRoutable);
          agent.onNotRoutable(this.Agent.onNotRoutable);
          agent.onOffline(this.Agent.onOffline);
        }
      );
    },

    onRefresh: (agent) => {
      console.log("[agent.onRefresh] Agent data refreshed. Agent status is " + agent.getStatus().name);
    },

    onRoutable: (agent) => {
      console.log("[agent.onRoutable] Agent is routable. Agent status is " + agent.getStatus().name);
    },

    onNotRoutable: (agent) => {
      console.log("[agent.onNotRoutable] Agent is online, but not routable. Agent status is " + agent.getStatus().name);
    },

    onOffline: (agent) => {
      console.log("[agent.onOffline] Agent is offline. Agent status is " + agent.getStatus().name);
    },

    setAvailable: () => {
      let { agent } = this.state;
      var targetState = agent.getAgentStates().filter((o) => {
        // eslint-disable-next-line no-undef
        return o.type === connect.AgentStateType.ROUTABLE;
      })[0];
      agent.setState(targetState, {
        success: function () {
          console.log("Set agent status via Streams");
        },
        failure: function () {
          console.log("Failed to set agent status via Streams");
        },
      });
    },

    setOffline: () => {
      let { agent } = this.state;
      var targetState = agent.getAgentStates().filter((o) => {
        // eslint-disable-next-line no-undef
        return o.type === connect.AgentStateType.OFFLINE;
      })[0];
      agent.setState(targetState, {
        success: function () {
          console.log("Set agent status via Streams");
        },
        failure: function () {
          console.log("Failed to set agent status via Streams");
        },
      });
    },
  };

  ChatSession = {
    onMessage: (contactId) => async (event) => {
      this.setState({ contact: { contactId } });
      // TODO: change alert to contact name
      let { data } = event;
      let { Content, ParticipantRole } = data;
      if (Content && ParticipantRole !== "AGENT") {
        this.MountMessenger[contactId].onSendAppend(Content);
      }
    },
    sendMessage: async (id, msg) => {
      let { chatSessions } = this.state;

      this.setState({ contact: { contactId: id } });
      let session = chatSessions.find((c) => c.id === id);

      console.log("session", session);

      await session.sendMessage({
        contentType: "text/plain",
        message: msg,
      });
    },

    Add: (chatSession) => {
      let { chatSessions } = this.state;
      this.setState({ chatSessions: chatSessions.concat(chatSession) }); // {contactId: ""}
    },

    Remove: (id) => {
      let { chatSessions } = this.state;
      this.setState({ chatSessions: chatSessions.filter((item) => item.id !== id) });
    },
  };

  onClickMsgTransfer() {
    alert("Some Message ");
  }

  onMsgSelected = (msgId, msg) => {
    navigator.clipboard.writeText(msg.text);
    store.Alert("Content copied to clipboard.");
  };

  renderTabButtons(contact_list) {
    if (contact_list.length > 0) return contact_list.map((o, i) => <Tab disabled key={o.contactId} label={`Client ${i + 1}`} value={o.contactId} onClick={() => this.onClickTab(o.contactId)} />);
    return contact_list.map((o, i) => <Tab disabled key={o.contactId} label={`Client ${i + 1}`} value={o.contactId} onClick={() => this.onClickTab(o.contactId)} />);
  }

  onClickTab(contactId) {
    this.setState({ contact: { contactId } });
  }

  render() {
    let { title, contact_list, contact } = this.state;
    return (
      <VStack height="95%">
        <Box padding={1} width="100%" height="fit-content">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <HStack overflow="scroll" width="100%" height="100%">
          <VStack height={"100%"} width={500} className="LCA">
            <Tabs
              value={contact ? contact.contactId : 0}
              variant="fullWidth"
              scrollButtons="auto"
              orientation="horizontal"
              wrapped="true"
              style={{ backgroundColor: "#fff", width: "100%" }}
              TabIndicatorProps={{
                style: {
                  height: "5px",
                },
              }}
            >
              {this.renderTabButtons(contact_list)}
            </Tabs>
            {contact_list.map((o, i) => {
              let { contact } = this.state;
              let show = false;
              if (contact && contact.contactId === o.getContactId()) {
                // check if is current contact
                show = true;
              }

              return (
                <Box padding={1} width="100%" height="95%" className={show ? "" : "hide"}>
                  <Messenger
                    id={o.getContactId()} // assign contacct id as chatSession id for directing msg
                    onMounted={this.onMountMessenger}
                    LiveChatEnabled={true}
                    onMsgSelected={this.onMsgSelected}
                    viaBackend={false}
                    canClickOnIn={true}
                  />
                </Box>
              );
            })}
          </VStack>
          <Box padding={1} width="10%" height="fit-content"></Box>
          <VStack overflow="scroll" width={500} height="100%" className="livechat">
            {/* //chathistory here */}
            {contact && <ChatHistory contact={contact}></ChatHistory>}
            <div id="aws-container" style={{ height: "95%", width: 500 }}></div>
            <div id="ccp-container"></div>
            <div id="customerprofiles-container"></div>
            <div id="wisdom-container"></div>
          </VStack>
        </HStack>
      </VStack>
    );
  }
}

export default AWSLiveChat;
