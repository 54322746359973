import { BodyTitle } from "v2/Components";
import { AddButton } from "v2/Components/IconButtons";
import { VStack } from "v2/Components/Layouts";
import _ from "lodash";
import { DBAnswerSpec } from "@types";
import { APIReplaceableSetting } from "./APIReplaceableSetting";
import { Paper } from "@mui/material";
import { useContext } from "react";
import { FLEFormContext } from "Pages/FLE/hooks/FLEContexts";
import { IAPIIntegration } from "Pages/FLE/interfaces/FLE.interface";

interface IPayloadContentReplacementProps {
  apiConfig: IAPIIntegration<DBAnswerSpec>;
  payloadSet: number | "default";
  onChangeAPIConfig: (apiConfig: IAPIIntegration<DBAnswerSpec>) => void;
  title?: string;
}

export default ({ apiConfig, onChangeAPIConfig, payloadSet, title = "Payload Content Replacement" }: IPayloadContentReplacementProps) => {
  const fleFormContext = useContext(FLEFormContext);
  const onAddDefaultPayload = () => {
    let replaceables = apiConfig?.responses?.default?.replaceables;
    if (!replaceables) replaceables = [];
    replaceables.push({
      toReplace: "",
      fromResponse: "",
    });
    apiConfig.responses.default.replaceables = replaceables;

    onChangeAPIConfig(apiConfig);
  };
  const onChangeDefaultPayload = (toReplace: string, replaceBy: string, i: number): void => {
    if (!apiConfig.responses.default.replaceables) apiConfig.responses.default.replaceables = [];
    if (!toReplace && !replaceBy) {
      apiConfig.responses.default.replaceables.splice(i, 1);
    } else {
      apiConfig.responses.default.replaceables[i].toReplace = toReplace;
      apiConfig.responses.default.replaceables[i].fromResponse = replaceBy;
    }

    onChangeAPIConfig(apiConfig);
  };

  const onAddCondtionalPayload = () => {
    if (payloadSet === "default") return;

    let replaceables: any[] = _.get(apiConfig, `responses.conditionals[${payloadSet}].config.replaceables`, []);
    replaceables.push({
      toReplace: "",
      fromResponse: "",
    });
    _.set(apiConfig, `responses.conditionals[${payloadSet}].config.replaceables`, replaceables);
    onChangeAPIConfig(apiConfig);
  };
  const onChangeConditionalPayload = (toReplace: string, replaceBy: string, i: number): void => {
    let newApiConfig = _.set(apiConfig, `responses.conditionals[${payloadSet}].config.replaceables[${i}]`, {
      toReplace: toReplace,
      fromResponse: replaceBy,
    }) as any;

    if (!toReplace && !replaceBy) {
      newApiConfig.responses.conditionals[payloadSet].config!.replaceables!.splice(i, 1);
    } else {
      newApiConfig.responses.conditionals[payloadSet].config!.replaceables[i]!.toReplace = toReplace;
      newApiConfig.responses.conditionals[payloadSet].config!.replaceables[i]!.fromResponse = replaceBy;
    }

    onChangeAPIConfig(newApiConfig);
  };

  const defaultReplaceables = apiConfig.responses.default.replaceables;
  const { conditionals } = apiConfig?.responses;
  const conditionalReplaceables = (payloadSet: number) => (conditionals && conditionals[payloadSet] && conditionals[payloadSet].config!.replaceables) || [];
  return (
    <Paper sx={{ width: "100%", p: 2, height: "100%" }}>
      <VStack width={"100%"} height={"100%"} alignItems={"flex-start"} justifyContent="flex-start">
        <BodyTitle>{title}</BodyTitle>
        {payloadSet == "default" && (
          <>
            {defaultReplaceables &&
              defaultReplaceables.map((o, i) => {
                return <APIReplaceableSetting key={i} idx={i} toReplace={o.toReplace} replaceBy={o.fromResponse} onChange={onChangeDefaultPayload} />;
              })}

            <AddButton onClick={onAddDefaultPayload} />
          </>
        )}

        {payloadSet !== "default" && (
          <>
            {conditionals &&
              conditionalReplaceables(payloadSet).map((o, i) => {
                return (
                  <APIReplaceableSetting
                    //
                    toReplace={o.toReplace}
                    replaceBy={o.fromResponse}
                    idx={i}
                    onChange={onChangeConditionalPayload}
                    disabled={fleFormContext?.mode == "Info"}
                  />
                );
              })}

            <AddButton onClick={onAddCondtionalPayload} />
          </>
        )}
      </VStack>
    </Paper>
  );
};
