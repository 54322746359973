import React, { Component } from "react";

import { v1 } from "uuid";
import axios from "axios";
import _ from "lodash";
import PropsType from "prop-types";
import { Typography, Box } from "@mui/material";

import ZChat from "_Utilities/ZChat/ZChat";
import { Chatbot, ChatDOMAIN, DOMAIN, QA_DOMAIN } from "config/config";

import { VStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, store, ErrorX, ColorX } from "IZOArc/STATIC";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { observer } from "mobx-react";
import "./Messenger.scss";
class Messenger extends Component {
  static propTypes = {
    url: PropsType.string,
    onMounted: PropsType.func,
    LiveChatEnabled: PropsType.bool,
    onMsgRecieved: PropsType.func,
    onMsgSelected: PropsType.func,
    viaBackend: PropsType.bool,

    //zchat
    hideLongAnswer: PropsType.bool,
    revertReadMore: PropsType.bool,
    buttonWidthFitContent: PropsType.bool,

    pressEnterToSend: PropsType.bool,
    animated: PropsType.bool,

    showHeader: PropsType.bool,
    showFooter: PropsType.bool,
    showStatus: PropsType.bool,
    showDateTime: PropsType.bool,
    showLapseTime: PropsType.bool,

    showInAvatar: PropsType.bool,
    showOutAvatar: PropsType.bool,
    hideSameAvatar: PropsType.bool,
    avatarAtTop: PropsType.bool,

    appendTextAfterSend: PropsType.bool,
    quickReplyBar: PropsType.bool,
    showQuickRepliesAsButtons: PropsType.bool,
    disableButtonsAfterSend: PropsType.bool,
    disableTemplateButtonsAfterSend: PropsType.bool,

    canClickOnIn: PropsType.bool,
    canClickOnOut: PropsType.bool,
    HTMLEnabled: PropsType.bool,
  };

  static defaultProps = {
    url: "",
    name: "Talk to Virtual Assistant",
    onMounted: null,
    LiveChatEnabled: false,
    onMsgRecieved: null,
    onMsgSelected: null,
    viaBackend: true,

    //zchat
    hideLongAnswer: false,
    revertReadMore: true,
    buttonWidthFitContent: true,

    pressEnterToSend: true,
    animated: true,

    showHeader: false,
    showFooter: true,
    showStatus: false,
    showDateTime: true,
    showLapseTime: true,

    showInAvatar: true,
    showOutAvatar: false,
    hideSameAvatar: true,
    avatarAtTop: false,

    appendTextAfterSend: true,
    quickReplyBar: true,
    showQuickRepliesAsButtons: true,
    disableButtonsAfterSend: true,
    disableTemplateButtonsAfterSend: false,

    canClickOnIn: false,
    canClickOnOut: true,
    HTMLEnabled: true,
  };

  constructor() {
    super();
    this.state = {
      zchat: {
        hideLongAnswer: false,
        revertReadMore: true,
        buttonWidthFitContent: true,

        pressEnterToSend: true,
        animated: true,

        showHeader: false,
        showFooter: true,
        showStatus: false,
        showDateTime: true,
        showLapseTime: true,

        showInAvatar: true,
        showOutAvatar: false,
        hideSameAvatar: true,
        avatarAtTop: false,

        appendTextAfterSend: true,
        quickReplyBar: true,
        showQuickRepliesAsButtons: true,
        disableButtonsAfterSend: true,
        disableTemplateButtonsAfterSend: false,

        canClickOnIn: false,
        canClickOnOut: true,
        HTMLEnabled: true,
      },
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this._setChatbot(() => {
        this._regenSession();
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Messenger.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        if (this.props.onMounted) {
          this.props.onMounted({
            setChatbot: this._setChatbot,
            regenSession: this._regenSession,
            restartChat: this._restartChat,
            welcomeMessage: this._welcomeMessage,
            onSendAppend: this.onSendAppend,
          });
        }
        if (callback) callback();
      }
    );
  };

  onMountChat = (callbacks) => {
    this.MountChat = callbacks;
  };

  _getURL = () => {
    let { viaBackend, url } = this.props;
    if (url) return url;
    return viaBackend ? DOMAIN + "/Middleware/Message/Send" : ChatDOMAIN + "/Talk2VA";
  };

  _setChatbot = (callback) => {
    console.log("[-] START CHATBOT");

    let { interval, version, channel } = Chatbot;
    let url = this._getURL();

    this.setState(
      {
        livechat: false,
        channel: channel,
        url: url,
        timeout: interval,
        sending: false,
        version: version,
      },
      callback
    );
  };

  _regenSession = () => {
    this.setState(
      {
        sessionID: v1(),
      },
      () => {
        this._restartChat();
      }
    );
  };

  _restartChat = () => {
    this.MountChat.Clear();
    this._welcomeMessage();
  };

  _welcomeMessage = () => {
    let input = {
      type: "system",
      content: "CMD_WELCOME",
    };
    this.sendToServer(input, null);
  };

  onSend = (input, id) => {
    let inputO = {
      type: "text",
      content: input.text,
    };
    this.sendToServer(inputO, id, true);
  };

  onSendAppend = (text) => {
    let { sessionID } = this.state;
    let inputO = {
      type: "text",
      content: text,
    };
    this.MountChat.AppendText(text);
    this.sendToServer(inputO, sessionID, true);
  };

  onQuickReply = (quickReply, id) => {
    let input = {
      type: "button",
      content: quickReply.payload,
    };
    this.sendToServer(input, id, true);
  };

  onMsgLongPress = (msgId, msg) => {
    console.log("messenger.onMsgLongPress", msgId, msg);
    let { onMsgSelected } = this.props;
    if (onMsgSelected) {
      onMsgSelected(msgId, msg);
    }
  };

  sendToServer = async (input, _id, record = false) => {
    let { url, sessionID, channel, version, livechat } = this.state;
    let { onMsgRecieved, viaBackend } = this.props;
    let startTime = new Date();

    let payloadOut;

    if (viaBackend) {
      payloadOut = {
        JWT: store.user.JWT,
        env: store.server.Env,
        data: {
          session_id: sessionID,
          channel: channel,
          input: input,
          livechat: livechat,
          timestamp: new Date(),
          remarks: {
            version: version,
          },
        },
      };
    } else {
      payloadOut = {
        session_id: sessionID,
        channel: channel,
        input: input,
        livechat: livechat,
        timestamp: new Date(),
        remarks: {
          version: version,
        },
      };
    }

    console.log("[>] Data Sent: " + startTime, payloadOut);

    try {
      this.MountChat.Typing();
      let res = await axios.post(url, payloadOut);
      this.MountChat.Typing(false);
      if (res.data.Success === true) {
        let endTime = new Date();
        if (_id) this.MountChat.SetStatus(_id, "received");

        let processTime = (endTime - startTime) / 1000;
        console.log("[<] Data Received: " + endTime);
        console.log("[-] Process Time: " + processTime + "s");
        console.log("[-] MW Response: ", res.data);

        let payload;
        if (viaBackend) {
          payload = res.data.payload;
        } else {
          payload = res.data;
        }

        let payloadOutR;
        if (viaBackend) {
          payloadOutR = payloadOut.data;
        } else {
          payloadOutR = payloadOut;
        }

        if (record && onMsgRecieved) {
          onMsgRecieved(payloadOutR, payload, startTime, endTime, _id, version);
        }

        if (payload.live_chat) this.startLiveChatPoll();
        else this.stopLiveChatPoll();

        let msg = payload.message;

        _.map(msg, (o, i) => {
          o.lapseTime = processTime;
        });

        this.appendReturnMessage(msg, _id);
      } else {
        let payload;
        if (viaBackend) {
          payload = res.data.payload;
        } else {
          payload = res.data;
        }
        ErrorX.Handle(payload);
      }
    } catch (e) {
      this.MountChat.Typing(false);
      ErrorX.Handle(e);
      if (_id) this.MountChat.SetStatus(_id, "pending");
    }
  };

  appendReturnMessage = (messages, _id) => {
    this.MountChat.Append(messages.reverse());
    this.MountChat.SetStatus(_id, "read");
  };

  liveChatShoot = () => {
    let { url, sessionID, channel, count, timeout, livechat, sending, remarks } = this.state;
    let { viaBackend } = this.props;

    if (!livechat || sending) return;
    //Send Message to MW
    let date = Math.round(new Date().getTime() / 1000);
    let payloadOut;

    if (viaBackend) {
      payloadOut = {
        JWT: store.user.JWT,
        data: {
          session_id: sessionID,
          channel: channel,
          livechat: livechat,
          input: {
            type: "system",
            content: "CMD_POLLING",
          },
          timestamp: date,
          remarks: remarks,
        },
      };
    } else {
      payloadOut = {
        session_id: sessionID,
        channel: channel,
        livechat: livechat,
        input: {
          type: "system",
          content: "CMD_POLLING",
        },
        timestamp: date,
        remarks: remarks,
      };
    }

    this.setState(
      {
        sending: true,
        count: count + 1,
      },
      async () => {
        console.log("[>] LiveChat Bullet Sent. Count:" + count);

        try {
          let res = await axios.post(url, payloadOut);
          console.log("[<] Bullet Received.", res.data);
          this.setState({ sending: false });

          let { Success } = res.data;
          let payload;
          if (viaBackend) {
            payload = res.data.payload;
          } else {
            payload = res.data;
          }
          if (Success && !_.isEmpty(payload.message)) {
            console.log("[<] Message Received.");
            this.setState({ count: 0 });
            this.appendReturnMessage(payload.message);

            if (payload.live_chat) {
              setTimeout(() => {
                this.liveChatShoot();
              }, timeout);
            }
          } else this.stopLiveChatPoll();
        } catch (e) {
          console.log(e);
          this.setState(
            {
              sending: false,
            },
            () => {
              this.liveChatShoot();
            }
          );
        }
      }
    );
  };

  startLiveChatPoll = () => {
    let { LiveChatEnabled } = this.props;
    if (!LiveChatEnabled) return;
    console.log("[-] Live Chat Start Polling");
    this.setState(
      {
        livechat: true,
      },
      () => {
        this.liveChatShoot();
      }
    );
  };

  stopLiveChatPoll = () => {
    console.log("[-] Live Chat Stop Polling");
    this.setState({
      livechat: false,
    });
  };

  onCardBTNPress = async (o) => {
    try {
      await axios.post(`${QA_DOMAIN}/train`, o.payload);

      this.setState({
        sbopen: true,
        sbmsg: `Suggested answer: ${o.payload.suggested}`,
        sbseverity: "success",
      });
    } catch (e) {
      console.error(e);
      this.setState({
        sbopen: true,
        sbmsg: "Could not save record",
        sbseverity: "error",
      });
    }
  };

  CloseSB = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ sbopen: false });
  };

  renderChat() {
    let zchat = Accessor.Exclude(this.state, ["onMounted", "LiveChatEnabled", "onMsgRecieved", "onMsgSelected", "viaBackend"]);

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
      <div className="t2va-chat" style={{ width: "100%", height: "100%", paddingBottom: "0" }}>
        <ZChat
          cssPrefix=""
          browser={store.browser}
          onMounted={this.onMountChat}
          onSend={this.onSend}
          onQuickReply={this.onQuickReply}
          onCardBTNPress={this.onCardBTNPress}
          onMsgLongPress={this.onMsgLongPress}
          onMsgPress={this.onMsgLongPress}
          user={{
            _id: 1,
          }}
          {...zchat}
        />
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={this.state.sbopen} autoHideDuration={6000} onClose={this.CloseSB}>
          <Alert onClose={this.CloseSB} severity={this.state.sbseverity} sx={{ width: "100%" }}>
            {this.state.sbmsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }

  renderLink() {
    return (
      <Box>
        <Typography
          style={{
            color: ColorX.GetColorCSS("Primary"),
            fontSize: 9,
            width: "100%",
            paddingTop: "60px",
            position: "fixed",
          }}
        >
          {/* {"Connecting >>> " + this._getURL()} */}
        </Typography>
      </Box>
    );
  }

  renderHeader() {
    return (
      <div className="headline">
        <Box className="left">
          <div className={"syva_logo"}></div>
        </Box>

        <div className="text">{this.state.name}</div>

        <Box className="right"></Box>
      </div>
    );
  }

  render() {
    return (
      <VStack style={{ width: "100%", minHeight: "calc(100%)", height: "100%", background: "#fff" }}>
        {this.renderHeader()}
        {this.renderLink()}
        {this.renderChat()}
      </VStack>
    );
  }
}

export default observer(Messenger);
