import { Button, Grid, Typography } from "@mui/material";
import { DBAnswerSpec } from "@types";

import _ from "lodash";
import { useEffect, useState } from "react";
import { BodyTitle } from "v2/Components";
import { DeleteButton } from "v2/Components/IconButtons";
import { HStack, VStack } from "v2/Components/Layouts";
import { IAllFLEAnswers, IFLEAnswer, IFLECondition, IFLEDoc } from "../../../../interfaces/FLE.interface";
import { MultilangAnswerFeilds } from "../../../../components/MultilangAnswerFeilds";
import { ControlledMultilangAnswerFeilds } from "Pages/FLE/components";
import { useFormContext } from "react-hook-form";

interface IAnsContentProps {
  answers: IAllFLEAnswers<DBAnswerSpec>;
  selectedConditions: IFLECondition[];
  setSelectedConditions: (cond: IFLECondition[]) => void;
  onChangeAnswers: (answers: IAllFLEAnswers<DBAnswerSpec>) => void;
}

export function AnsContent(props: IAnsContentProps) {
  const { answers, setSelectedConditions, selectedConditions, onChangeAnswers } = props;
  const selectedAnswer = answers.conditionals?.find((ans) => _.isEqual(ans.conditions, selectedConditions));
  const { control } = useFormContext<IFLEDoc<DBAnswerSpec>>();
  const GridProps = {
    item: true,
    container: true,
    xs: 6,
    md: 4,
    lg: 2,
    justifyContent: "center",
    alignItems: "center",
  };

  const renderAnswerSelectionButtons = () => {
    const buttons = {
      conditional: (ans: IFLEAnswer<DBAnswerSpec>, ansIdx: number): JSX.Element => {
        return (
          <Grid {...GridProps} key={ansIdx}>
            <HStack>
              <Button
                onClick={() => {
                  setSelectedConditions(ans.conditions);
                }}
                variant={_.isEqual(selectedConditions, ans.conditions) ? "contained" : "text"}
              >
                {/* show last 8 characters */}
                {ans.answerDoc._id.slice(-8)}
              </Button>
              <DeleteButton
                onClick={() => {
                  answers.conditionals?.splice(ansIdx, 1);
                  onChangeAnswers(answers);
                }}
              />
            </HStack>
          </Grid>
        );
      },
      default: (): JSX.Element => {
        const noCondsSet = selectedConditions.length === 0;
        const allDefaultConds = selectedConditions.filter((cond) => cond.value === "default").length === selectedConditions.length;
        const isDefaultSelected = noCondsSet || allDefaultConds;

        return (
          <Grid {...GridProps}>
            <Button
              onClick={() => {
                setSelectedConditions(selectedConditions.map((cond) => ({ ...cond, value: "default" })));
              }}
              variant={isDefaultSelected ? "contained" : "text"}
            >
              Default
            </Button>
          </Grid>
        );
      },
    };

    return (
      <Grid container>
        {buttons.default()}
        {answers.conditionals?.map(buttons.conditional) || <></>}
      </Grid>
    );
  };
  const renderDefaultAnsConfig = () => {
    const editable = !selectedAnswer;

    return (
      <VStack width="50%">
        <HStack gap={2}>
          <BodyTitle>Default Answer</BodyTitle>
        </HStack>
        <ControlledMultilangAnswerFeilds<IFLEDoc<DBAnswerSpec>>
          //
          name={"answers.defaultAnsDoc"}
          control={control}
          disabled={!editable}
        />
      </VStack>
    );
  };
  const renderSelectedAnsConfig = (selectedAnswer: IFLEAnswer<DBAnswerSpec>) => {
    console.log("selectedAnswer", selectedAnswer);
    return (
      <VStack width="50%">
        <BodyTitle>Conditional Answers</BodyTitle>
        <MultilangAnswerFeilds
          answerDoc={selectedAnswer.answerDoc}
          onChangeAnswerDoc={(ansDoc) => {
            const selectedCondtionIndex = answers.conditionals?.findIndex((ans) => _.isEqual(ans.conditions, selectedConditions));

            console.log("selectedCondtionIndex", selectedCondtionIndex);
            if (selectedCondtionIndex !== undefined && answers.conditionals) {
              console.log({ selectedCondtionIndex });
              selectedAnswer.answerDoc = ansDoc;
              answers.conditionals[selectedCondtionIndex] = selectedAnswer;
              console.log("onChange Selected Answer:", answers);
              onChangeAnswers(answers);
            }
          }}
        />
      </VStack>
    );
  };
  return (
    <VStack width="100%">
      {renderAnswerSelectionButtons()}
      <HStack alignItems={"flex-start"}>
        {renderDefaultAnsConfig()}
        {(selectedAnswer && renderSelectedAnsConfig(selectedAnswer)) || <></>}
      </HStack>
    </VStack>
  );
}
