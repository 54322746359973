import { Fragment, useState } from "react";
import { store } from "IZOArc/STATIC";

import axios from "axios";
import { Button } from "@mui/material";
import { GetApp } from "@mui/icons-material";

import { DOMAIN } from "config/config";
import { HStack } from "IZOArc/LabIZO/Stackizo";

import "./Report.scss";
import moment from "moment";
import { ReportDownloadDialog } from "./ReportDownloadDialog";

interface IReportProps {
  base: any;
  dateRange: IReportDateRange;
}

export interface IReportDateRange {
  from: moment.Moment;
  to: moment.Moment;
}

const Report: React.FC<IReportProps> = ({ base, dateRange }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reportId, setReportId] = useState("");

  const request = async (): Promise<void> => {
    try {
      const docName = base.exportDoc + dateRange.from.format("YYYYMMDD") + "-" + dateRange.to.format("YYYYMMDD") + ".xlsx";
      store.Alert("Downloading Report:" + docName);

      const url = `${DOMAIN}${base.url.ReportExporter}`;
      const payload = {
        JWT: store.user.JWT,
        data: {
          format: null,
          schema: base.schema.Export,
          skip: 0,
          limit: 9999,
        },
        addOns: {
          dateRange,
        },
      };
      const res = await axios.post(url, payload);
      if (!res.data.Success) throw new Error(res.data.message);

      setReportId(res.data.payload.reportId);
      setDialogOpen(true);
    } catch (error: any) {
      onFail(error?.message || "error exporting report");
    }
  };

  const onClick = async (): Promise<void> => await request();

  const onFail = (message: string) => store.Alert(`Failed to download report: ${message}`, "error");

  const handleCloseDialog = (_event: React.SyntheticEvent): void => {
    setDialogOpen(false);
  };

  return (
    <Fragment>
      <HStack className="Buttons">
        <Button variant="contained" color="primary" onClick={onClick}>
          <GetApp fontSize="small" />
          Download
        </Button>
      </HStack>
      {dialogOpen && <ReportDownloadDialog open={dialogOpen} handleCancel={handleCloseDialog} reportId={reportId} base={base} dateRange={dateRange} />}
    </Fragment>
  );
};

export default Report;
