const Request = {
  DBInfo: "/Tables/Convlog/Info",
  List: "/Tables/Convlog/List",
  Info: "/Tables/Convlog/Get",
  Add: "/Tables/Convlog/Add",
  Delete: "/Tables/Convlog/Delete",
  Edit: "/Tables/Convlog/Edit",
  Export: "/Tables/Convlog/Export",
};

let exports = {
  Request
};

export default exports;
