import { FileCopy } from "@mui/icons-material";
import { IconButton, SxProps, Theme } from "@mui/material";
import { store } from "IZOArc/STATIC";

interface ICopyButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  onClickCopy?: string;
  sx?: SxProps<Theme>;
}

export const CopyButton = ({ onClick, onClickCopy, sx, ...props }: ICopyButtonProps) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    store.Alert("Copied to clipboard", "success");
  };

  const handleClick = () => {
    if (onClick) onClick();
    if (onClickCopy) {
      copyToClipboard(onClickCopy);
    }
  };

  return (
    <IconButton onClick={handleClick} {...props} sx={{ p: 0, ...sx }}>
      <FileCopy />
    </IconButton>
  );
};
