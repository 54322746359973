import { Button, Typography } from "@mui/material";
import { DBAnswerSpec } from "@types";
import axios from "axios";
import { DOMAIN } from "config/config";
import { ErrorX, store } from "IZOArc/STATIC";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Controller, FieldErrors, FormProvider, SubmitErrorHandler, useForm } from "react-hook-form";
import { IValidationPayload } from "v2/Components";
import { HStack, VStack } from "v2/Components/Layouts";
import { ErrorSummary, TabSelector, TabContents } from "../components";
import { IFLETab, defaultTabs } from "../constants";
import { defaultDoc } from "../constants/defaultDoc";
import { FLEFormContext } from "../hooks/FLEContexts";
import { IFLEDoc } from "../interfaces/FLE.interface";

interface IFLESetUpProps {
  id: string;
  row?: IFLEDoc<DBAnswerSpec>;
  onQuit: () => void;
  onQuitRefresh: (_id: string) => void;
  renderFormizo: (schema: any, buttons: any, onSubmit: any) => JSX.Element;
  tabs?: IFLETab[];
  mode?: "Add" | "Edit" | "Info";
}

export const FLESetUp: React.FC<IFLESetUpProps> = ({ tabs = defaultTabs, row = defaultDoc, mode = "Add", ...props }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [errors, setErrors] = useState<FieldErrors<IFLEDoc<DBAnswerSpec>> | null>(null);
  const { id } = props;
  const methods = useForm<IFLEDoc<DBAnswerSpec>>({ defaultValues: row, mode: "all" });

  const onValidSubmit = async (_row: any) => {
    try {
      setErrors(null);
      const url = DOMAIN + "/Tables/FLE/" + mode;

      const { data: res } = await axios.post(url, {
        JWT: store.user.JWT,
        data: _row,
      });

      console.log(_row);
      if (!res.Success) return ErrorX.Handle(res.payload);
      props.onQuitRefresh(_row._id);
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  };
  const onInValid = (errors: FieldErrors<IFLEDoc<DBAnswerSpec>>) => {
    setErrors(errors);
  };

  const onTabChage = (idx: number) => {
    setErrors(null);
    setTabIdx(idx);
  };

  return (
    <VStack width={"100%"} alignItems={"flex-start"} gap={2} height="100%" justifyContent={"flex-start"}>
      {errors && <ErrorSummary errors={errors} />}
      <HStack justifyContent={"space-between"}>
        {
          <TabSelector
            tabs={tabs}
            tabIdx={tabIdx}
            setTabIdx={(idx) => {
              methods.handleSubmit(() => onTabChage(idx), onInValid)();
            }}
          />
        }
        {mode !== "Info" && (
          <Button
            //
            variant="contained"
            color="secondary"
            onClick={methods.handleSubmit(onValidSubmit, onInValid)}
          >
            Submit
          </Button>
        )}
      </HStack>
      <FormProvider {...methods}>
        <FLEFormContext.Provider value={{ mode }}>{<TabContents tabs={tabs} tabIdx={tabIdx} id={id} />}</FLEFormContext.Provider>
      </FormProvider>
    </VStack>
  );
};
