import { NativeSelect, Typography } from "@mui/material";
import { FormizoSchema } from "IZOArc/LabIZO/Formizo";
import { HStack, VStack } from "IZOArc/LabIZO/Stackizo";
import { CustomFilterValueProps, FieldType, Filterable, TablizoSchema } from "IZOArc/LabIZO/Tablizo";
import CellExpand from "IZOArc/LabIZO/Tablizo/_gears/CellExpand";
import _ from "lodash";
import moment from "moment";
import ZCMsg from "_Utilities/ZChat/ZCMsg/ZCMsg";
import ZEye from "_Utilities/ZEye/ZEye";

const Table: TablizoSchema[] = [
  {
    label: "Session ID",
    name: "sessionID",
    width: 320,
    filterable: true,
  },
  {
    label: "Lang",
    name: "lang",
    width: 85,
    filterable: true,
  },
  {
    label: "Receive Time",
    name: "recTime",
    type: "dateTime",
    transform: "datetime",
    width: 180,
    filterable: true,
    defaultSort: "desc",
  },
  // {
  //   label: "Process",
  //   name: "processTime",
  //   width: 110,
  //   type: "number",
  //   Cell: (row, field, addOns) => field + "s",
  //   filterable: true,
  // },
  {
    label: "Channel",
    name: "channel",
    width: 120,
    filterable: true,
  },
  {
    label: "Product",
    name: "Context.product",
    filterable: true,
  },
  {
    label: "Input",
    name: "Input.Content",
    filterable: true,
  },
  {
    label: "Intent",
    name: "intent",
  },
  {
    label: "Confidence",
    name: "confidence",
    width: 120,
    coerceNumericType: "double",
  },
  {
    label: "Answer ID",
    name: "ansRes.__ans",
    valueGetter: (data: any, field: any, state: any) => {
      let rtn: any = [];
      if (field !== null && field !== undefined) {
        Object.keys(field).forEach((key) => {
          rtn += key + ": " + field[key];
        });
      }
      return rtn;
    },
    Cell: (data: any, field: any, state: any) => [<CellExpand value={field || ""} />],
    filterable: true,
  },
  {
    label: "Answer",
    name: "Response.0.msg.text",
    sortable: false,
  },
  {
    label: " ",
    name: "Response",
    width: 50,
    Cell: (row: any, field: any, addOns: any) => (
      <HStack>
        <ZEye>
          <VStack>
            {_.map(field, (o, i) => {
              return <ZCMsg key={i} cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />;
            })}
          </VStack>
        </ZEye>
      </HStack>
    ),
    sortable: false,
  },
];

const Tail: FormizoSchema[] = [
  {
    label: "Intents",
    name: "wsRes.master.intents",
    canAdd: false,
    canDelete: false,
    arrayStyle: "table",
    array: [
      {
        label: "Intent",
        name: "intent",
        format: "text",
      },
      {
        label: "Confidence",
        name: "confidence",
        format: "text",
      },
    ],
  },
  {
    label: "Entities",
    name: "wsRes.master.entities",
    array: [
      {
        label: "",
        name: "location",
        format: "display",
        Custom: (data: any, field: any, addOns: any) => {
          let { cssPrefix } = addOns;
          return <div className={cssPrefix + " zrf-field-data"}>{data.input && field && data.input.substring(field[0], field[1])}</div>;
        },
      },
      {
        label: "Entity",
        name: "entity",
        format: "text",
      },
      {
        label: "Value",
        name: "value",
        format: "text",
      },
      {
        label: "Confidence",
        name: "confidence",
        format: "text",
      },
    ],
  },
  {
    reqLevel: 0,
    accessizo: [
      {
        label: "Nodes Visited",
        name: "wsRes.master.output.nodes_visited",
        array: [
          {
            label: "",
            name: "",
            format: "text",
          },
        ],
      },
      {
        label: "Detected Keywords",
        name: "Detect.Entities",
        array: [
          {
            label: "",
            name: "",
            format: "display",
            Custom: (data: any, field: any, state: any) => {
              let { cssPrefix } = state;
              return <div className={cssPrefix + " zrf-field-data"}>{data.input && field && data.input.substring(field.from, field.to)}</div>;
            },
          },
          {
            label: "Name",
            name: "name",
            format: "text",
          },
          {
            label: "Pattern",
            name: "pattern",
            format: "text",
          },
          {
            label: "Value",
            name: "value",
            format: "display",
            Custom: (data: any, field: any, state: any) => {
              return field ? JSON.stringify(field) : "";
            },
          },
        ],
      },
      {
        label: "Detected Semantics",
        name: "Detect.Semantics",
        array: [
          {
            label: "",
            name: "",
            format: "display",
            Custom: (data: any, field: any, state: any) => {
              let { cssPrefix } = state;
              return <div className={cssPrefix + " zrf-field-data"}>{data.input && field && data.input.substring(field.from, field.to)}</div>;
            },
          },
          {
            label: "Name",
            name: "name",
            format: "text",
          },
          {
            label: "Pattern",
            name: "pattern",
            format: "text",
          },
          {
            label: "Value",
            name: "value",
            format: "display",
            Custom: (data: any, field: any, state: any) => {
              return field ? JSON.stringify(field) : "";
            },
          },
        ],
      },
    ],
  },
];

const Info: FormizoSchema[] = [
  {
    columns: [
      {
        width: "50%",
        page: [
          {
            label: "Conversation ID",
            name: "_id",
            format: "text",
            readOnly: true,
          },
          {
            label: "Session ID",
            name: "sessionID",
            validate: ["required"],
            format: "text",
            readOnly: true,
          },
          {
            label: "Receive Time",
            name: "recTime",
            format: "display",
            readOnly: true,
            Custom: (data: any, field: any, addOns: any) => {
              return <Typography>{moment(field).format("DD MMM YYYY, HH:mm:ss 0.SSS")}</Typography>;
            },
          },
          {
            label: "Reply Time",
            name: "outTime",
            format: "display",
            readOnly: true,
            Custom: (data: any, field: any, addOns: any) => {
              return <Typography>{moment(field).format("DD MMM YYYY, HH:mm:ss 0.SSS")}</Typography>;
            },
          },
          {
            label: "Proccess Time (s)",
            name: "processTime",
            format: "number",
            readOnly: true,
          },
          {
            label: "Channel",
            name: "channel",
            format: "text",
            readOnly: true,
          },
          {
            label: "Language",
            name: "lang",
            format: "text",
            readOnly: true,
          },
          {
            label: "LiveChat (Beta)",
            name: "liveChat",
            format: "bool",
            readOnly: true,
          },
          {
            label: "Message Content",
            name: "Input.Content",
            format: "text",
            readOnly: true,
          },
          {
            label: "Message Type",
            name: "Input.Type",
            format: "text",
            readOnly: true,
          },
          {
            label: "Geolocation",
            format: "text",
            readOnly: true,
          },
          {
            label: "Answer",
            name: "ansRes.__ans",
            format: "text",
            readOnly: true,
          },
          {
            reqLevel: 0,
            accessizo: [
              {
                label: "Template",
                name: "ansRes.__tans",
                format: "text",
                readOnly: true,
              },
              {
                label: "Function",
                name: "ansRes.__func",
                format: "text",
                readOnly: true,
              },
              {
                label: "Parameters",
                name: "ansRes.__param",
                format: "text",
                readOnly: true,
              },
            ],
          },
          {
            label: "Change Topic Echo",
            name: "ansRes.__change",
            format: "bool",
            readOnly: true,
          },
        ],
      },
      {
        width: "50%",
        page: Tail,
      },
    ],
  },
];

const Edit: FormizoSchema[] = [...Info];

const Export = [
  {
    Header: "Session ID",
    accessor: "sessionID",
  },
  {
    Header: "Receive Time",
    accessor: "recTime",
    format: "datetime",
  },
  {
    Header: "Reply Time",
    accessor: "outTime",
    format: "datetime",
  },
  {
    Header: "Proccess Time (s)",
    accessor: "processTime",
  },
  {
    Header: "Channel",
    accessor: "channel",
  },
  {
    Header: "Product",
    accessor: "Context.product",
  },
  {
    Header: "Message Content",
    accessor: "Input.Content",
  },
  {
    Header: "Message Type",
    accessor: "Input.Type",
  },
  {
    Header: "Language",
    accessor: "lang",
  },
  {
    Header: "LiveChat",
    accessor: "liveChat",
    format: "boolean",
  },
  {
    Header: "Workspace",
    accessor: "ws",
  },
  {
    Header: "Intent",
    accessor: "intent",
  },
  {
    Header: "Confidence",
    accessor: "confidence",
  },
  {
    Header: "Answer",
    accessor: "Response.0.msg.text",
  },
  {
    Header: "Template",
    accessor: "ansRes.__tans",
  },
  {
    Header: "Flow",
    accessor: "ansRes.__func",
  },
];

const Import: any = [];

const ImportFormat = [...Export];

const LandFilterValue = ({ field, width, fieldState }: CustomFilterValueProps) => {
  return (
    <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
      <option value={""}></option>
      <option value={"TC"}>TC</option>
      <option value={"SC"}>SC</option>
      <option value={"EN"}>EN</option>
    </NativeSelect>
  );
};

const Filterables: Filterable[] = [
  {
    field: "sessionID",
    label: "Session ID",
    type: FieldType.text,
  },
  {
    field: "lang",
    label: "Language",
    type: FieldType.select,
    CustomFilterValue: LandFilterValue,
  },
  {
    field: "Context.product",
    label: "Product",
    type: FieldType.text,
  },
  {
    field: "channel",
    label: "Channel",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={"Talk2VA"}>Talk2VA</option>
          <option value={"Whatsapp"}>Whatsapp</option>
        </NativeSelect>
      );
    },
  },
  {
    label: "Intent",
    field: "intent",
    type: FieldType.text,
  },

  {
    label: "Input Message",
    field: "Input.Content",
    type: FieldType.text,
  },

  {
    label: "Answer ID",
    field: "ansRes.__ans",
    type: FieldType.text,
  },

  {
    field: "confidence",
    label: "Confidence",
    type: FieldType.number,
  },
];

const AdvFilter: any = [];

const schema: any = {
  Table,
  Info,
  Edit,
  Export,
  Import,
  ImportFormat,
  AdvFilter,
  Filterables,
};

export default schema;
