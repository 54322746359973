import React, { Component } from "react";

import PropsType from "prop-types";
import _ from "lodash";

import MenuButton from "./MenuButton";

import { Accessor, ColorX } from "IZOArc/STATIC";
import { VStack } from "IZOArc/LabIZO/Stackizo";
import { Box } from "@mui/material";
class MenuButtonList extends Component {
  static propTypes = {
    buttons: PropsType.array,
    mini: PropsType.bool,
    zIndex: PropsType.number,
  };

  static defaultProps = {
    buttons: [],
    mini: false,
    zIndex: 50,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(MenuButtonList.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  menuButton(caption, path, fafa, reqAuth, reqLevel = 999, submenu = null, disabled = false, id = null) {
    let { zIndex, mini } = this.props;
    return <MenuButton key={caption} caption={caption} path={path} fafa={fafa} reqAuth={reqAuth} reqLevel={reqLevel} submenu={submenu} zIndex={zIndex - 1} mini={mini} disabled={disabled} id={id} />;
  }

  renderButtons() {
    let { buttons } = this.props;
    return _.map(buttons, (o, i) => {
      return this.menuButton(o.caption, o.link, o.faIcon, o.auth, o.level, o.submenu, o.disabled, o.id);
    });
  }

  render() {
    let { mini, zIndex } = this.props;
    return (
      <VStack
        gap={0}
        sx={{
          background: ColorX.GetColorCSS("Secondary"),
          position: "absolute",
          height: "fit-content",
          top: 0,
          left: mini ? 40 : 140,
          zIndex: zIndex - 1,
          boxShadow: "5px 5px 5px #ededed, 5px 5px 5px #ffffff",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {this.renderButtons()}
      </VStack>
    );
  }
}

export default MenuButtonList;
