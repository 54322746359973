import { Typography } from "@mui/material";
import { ArrowRight, Check, Close } from "@mui/icons-material";
import { HMarquee } from "IZOArc/LabIZO/Animatizo";
import { HStack, Spacer, VStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, Authority, ColorX } from "IZOArc/STATIC";
import _ from "lodash";
import moment from "moment";
import PropsType from "prop-types";
import React, { Component, createRef } from "react";
import { v1 } from "uuid";
import ZCMsg from "_Utilities/ZChat/ZCMsg/ZCMsg";
import { JsonView } from "v2/Components/JsonView";

class ConvlogDetail extends Component {
  static propTypes = {
    id: PropsType.string,
    doc: PropsType.object,
    prev: PropsType.oneOfType([PropsType.object, PropsType.array]),
    intents: PropsType.array,
  };

  static defaultProps = {
    id: "",
    doc: {},
    prev: [],
    intents: [],
  };

  constructor() {
    super();
    this.state = {
      intents: [],
    };
    this.chatRef = createRef();
  }

  componentDidMount() {
    this._setAllStates();
    this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(ConvlogDetail.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        if (callback) callback();
      }
    );
  };

  scrollToBottom() {
    if (this.el) {
      this.el.scrollIntoView();
    }
  }

  transDate = (tzTime) => {
    try {
      if (!tzTime) throw Error;
      return moment(new Date(tzTime)).format("DD MMM YYYY, HH:mm:ss 0.SSS");
    } catch {
      return "Not Applicable.";
    }
  };

  indexColor = (idx) => {
    let bgColor = ColorX.GetColorCSS("Primary");
    if (idx % 2 === 1) {
      bgColor = ColorX.GetColorCSS("Secondary");
    }

    return bgColor;
  };

  renderJSON(doc) {
    let src = Accessor.Get(doc, "Response") || {};
    return (
      <VStack padding={2} style={{ width: "100%", maxHeight: 520 }} alignItems="flex-start">
        <JsonView>{src}</JsonView>
      </VStack>
    );
  }

  renderBubble(o, pos, opacity) {
    return <ZCMsg cssp="zchat z" key={v1()} _onQuickReply={() => {}} pos={pos} item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} opacity={opacity} />;
  }

  renderChat(doc, prev) {
    let response = [];
    if (!_.isEmpty(prev)) {
      if (!_.isArray(prev)) {
        prev = [prev];
      }
      _.map(prev, (o, i) => {
        let prevRes = Accessor.Get(o, "Response") || [];
        response.push(
          this.renderBubble(
            {
              msg: {
                text: Accessor.Get(o, "Input.Content") || "",
              },
            },
            "out",
            0.5
          )
        );

        _.map(prevRes, (v, x) => {
          response.push(this.renderBubble(v, "in", 0.5));
        });
      });
    }

    let thisRes = Accessor.Get(doc, "Response") || [];
    response.push(
      this.renderBubble(
        {
          msg: {
            text: Accessor.Get(doc, "Input.Content") || "",
          },
        },
        "out"
      )
    );
    _.map(thisRes, (o, i) => {
      response.push(this.renderBubble(o, "in"));
    });

    return (
      <VStack
        width="100%"
        padding={2}
        sx={{
          border: "1px solid " + ColorX.GetColorCSS("Primary", 0.2),
          overflow: "scroll",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          height: "400px",
        }}
        ref={this.chatRef}
      >
        {response}
        <div
          ref={(el) => {
            this.el = el;
          }}
        />
      </VStack>
    );
  }

  renderDetectedSemanticBlock(iSemantic, idx) {
    let bgColor = this.indexColor(idx);
    let textColor = this.indexColor(idx + 1);

    let { name, pattern, value } = iSemantic;
    let ivalue = value;
    if (!_.isString(ivalue)) {
      try {
        ivalue = JSON.stringify(ivalue);
      } catch {
        ivalue = ivalue.toString();
      }
    }

    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <Typography style={{ color: textColor, fontSize: 14, fontWeight: "bold" }}>{pattern}</Typography>
          <Spacer />
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ color: textColor, fontSize: 9, width: "30%" }}>{"Semantic".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ color: textColor, fontSize: 9, overflow: "visible" }} noWrap>
                {name}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ color: textColor, fontSize: 9, width: "30%" }}>{"Value".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ color: textColor, fontSize: 9, overflow: "visible" }} noWrap>
                {ivalue}
              </Typography>
            </HMarquee>
          </HStack>
        </VStack>
      </HStack>
    );
  }

  renderDetectedSemantics(doc) {
    let semantics = Accessor.Get(doc, "Detect.Semantics") || [];
    return (
      <VStack minHeight={250}>
        {this.renderHeader("Detected Semantics", 300)}
        <VStack style={{ maxHeight: 300, overflow: "auto", width: "100%", justifyContent: "flex-start" }}>
          {semantics.length > 0
            ? _.map(semantics, (o, i) => {
                return this.renderDetectedSemanticBlock(o, i);
              })
            : this.renderNoData("No Semantic Phrase Detected.")}
        </VStack>
      </VStack>
    );
  }

  renderDetectedEntityBlock(iEntity, idx) {
    let bgColor = this.indexColor(idx);
    let { name, pattern, value } = iEntity;

    let ivalue = value;
    if (!_.isString(ivalue)) {
      try {
        ivalue = JSON.stringify(ivalue);
      } catch {
        ivalue = ivalue.toString();
      }
    }

    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <Typography style={{ fontSize: 14, fontWeight: "bold" }}>{pattern}</Typography>
          <Spacer />
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Entity".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9, overflow: "visible" }} noWrap>
                {name.toString()}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Value".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9, overflow: "visible" }} noWrap>
                {ivalue}
              </Typography>
            </HMarquee>
          </HStack>
        </VStack>
      </HStack>
    );
  }

  renderDetectedEntities(doc) {
    let entities = Accessor.Get(doc, "Detect.Entities") || [];
    return (
      <VStack minHeight={250} sx={{ alignItems: "flex-start" }}>
        {this.renderHeader("Detected Keywords", 300)}
        <VStack sx={{ maxHeight: 200, overflow: "auto", width: "100%", justifyContent: "flex-start" }}>
          {entities.length > 0
            ? _.map(entities, (o, i) => {
                return this.renderDetectedEntityBlock(o, i);
              })
            : this.renderNoData("No Keywords Detected.")}
        </VStack>
      </VStack>
    );
  }

  renderNodeBlock(node, idx) {
    let bgColor = this.indexColor(idx);

    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <HStack>
            <ArrowRight />
            <HMarquee>
              <Typography style={{ fontSize: 14, fontWeight: "bold" }}>{node}</Typography>
            </HMarquee>
          </HStack>
          <Spacer />
        </VStack>
      </HStack>
    );
  }

  renderNodesVisited(doc) {
    let nodes = Accessor.Get(doc, "wsRes." + doc.ws + ".output.nodes_visited") || [];
    return (
      <VStack minHeight={250}>
        {this.renderHeader("Nodes Visited", 300)}
        <VStack style={{ maxHeight: 300, overflow: "auto", width: "100%", justifyContent: "flex-start" }}>
          {nodes.length > 0
            ? _.map(nodes, (o, i) => {
                return this.renderNodeBlock(o, i);
              })
            : this.renderNoData("No Node Visited.")}
        </VStack>
      </VStack>
    );
  }

  renderEntityBlock(iEntity, input, idx) {
    let bgColor = this.indexColor(idx);

    let { entity, location, value, confidence } = iEntity;
    let ivalue = value;
    if (!_.isString(ivalue)) {
      try {
        ivalue = JSON.stringify(ivalue);
      } catch {
        ivalue = ivalue.toString();
      }
    }

    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <Typography style={{ fontSize: 14, fontWeight: "bold" }}>{input && input.substring(location[0], location[1])}</Typography>
          <Spacer />
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Entity".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9, overflow: "visible" }} noWrap>
                {entity}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Value".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9, overflow: "visible" }} noWrap>
                {ivalue}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ fontSize: 9, width: "30%" }}>{"Confidence".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ fontSize: 9 }}>{confidence}</Typography>
            </HMarquee>
          </HStack>
        </VStack>
      </HStack>
    );
  }

  renderEntities(doc) {
    let entities = Accessor.Get(doc, "wsRes." + doc.ws + ".entities") || [];
    return (
      <VStack minHeight={250}>
        {this.renderHeader("NLP Entities", 300)}
        <VStack style={{ maxHeight: 200, overflow: "auto", width: "100%", justifyContent: "flex-start" }}>
          {entities.length > 0
            ? _.map(entities, (o, i) => {
                return this.renderEntityBlock(o, Accessor.Get(doc, "Input.Content"), i);
              })
            : this.renderNoData("No Entities Detected.")}
        </VStack>
      </VStack>
    );
  }

  renderIntentBlock(intent, idx) {
    let bgColor = this.indexColor(idx);
    let textColor = this.indexColor(idx + 1);
    let { intents } = this.state;
    let that = intents.find((o) => o.caption === intent.intent);
    return (
      <HStack style={{ background: bgColor }} key={idx}>
        <VStack alignItems="flex-start" padding={1} style={{ width: 300 }}>
          <Typography style={{ color: textColor, fontSize: 14, fontWeight: "bold" }}>{"#" + intent.intent}</Typography>
          <Spacer />
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ color: textColor, fontSize: 9, width: "30%" }}>{"Description".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ color: textColor, fontSize: 9, overflow: "visible" }} noWrap>
                {(that && that.Description) || "Intent not found."}
              </Typography>
            </HMarquee>
          </HStack>
          <HStack justifyContent="flex-start" gap={2}>
            <Typography style={{ color: textColor, fontSize: 9, width: "30%" }}>{"Confidence".toUpperCase()}</Typography>
            <HMarquee width="70%">
              <Typography style={{ color: textColor, fontSize: 9 }}>{intent.confidence}</Typography>
            </HMarquee>
          </HStack>
        </VStack>
      </HStack>
    );
  }

  renderIntentsInner(intents) {
    if (intents.length <= 0) {
      return this.renderNoData("No Intents Detected.");
    }

    let rtn = [];
    for (let i = 0; i < intents.length; i++) {
      rtn.push(this.renderIntentBlock(intents[i], i));
    }

    return rtn;
  }

  renderIntents(doc) {
    let intents = Accessor.Get(doc, "wsRes." + doc.ws + ".intents") || [];
    return (
      <VStack>
        {this.renderHeader("NLP Intents", 300)}
        <VStack style={{ maxHeight: 500, overflow: "auto", width: "100%" }}>{this.renderIntentsInner(intents)}</VStack>
      </VStack>
    );
  }

  renderNLP(doc) {
    const accessible = Authority.IsAccessibleQ("Convlog", 0, []);

    return (
      <HStack justifyContent="flex-start" gap={2} alignItems="flex-start">
        {this.renderIntents(doc)}
        <VStack gap={2}>
          {this.renderEntities(doc)}
          {accessible && this.renderNodesVisited(doc)}
        </VStack>
        <VStack gap={2}>
          {accessible && this.renderDetectedEntities(doc)}
          {accessible && this.renderDetectedSemantics(doc)}
        </VStack>
      </HStack>
    );
  }

  renderNoData(msg) {
    return (
      <HStack>
        <Typography>{msg}</Typography>
        <Spacer />
      </HStack>
    );
  }

  renderHeader(label, width = undefined) {
    return (
      <HStack width={width}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), fontWeight: "bold" }}>{label && label.toUpperCase()}</Typography>
        <Spacer />
      </HStack>
    );
  }

  renderField(label, value, width = 150, fontSize = 14) {
    if (_.isBoolean(value)) {
      value = value ? <Check /> : <Close />;
    }
    return (
      <HStack justifyContent="flex-start" gap={1} alignItems="flex-start" style={{ flexWrap: "wrap" }} key={label}>
        <Typography style={{ color: ColorX.GetColorCSS("Primary"), width: width, fontSize: fontSize }}>{label && label.toUpperCase()}</Typography>
        <Typography
          style={{
            color: ColorX.GetColorCSS("TableText"),
            fontSize: fontSize,
            flexWrap: "wrap",
            overflow: "auto",
            maxHeight: 200,
          }}
        >
          {value}
        </Typography>
      </HStack>
    );
  }

  renderMessage(doc) {
    let width = 120;
    return (
      <VStack width="40%" sapcing={2} sx={{ justifyContent: "flex-start" }}>
        {this.renderHeader("Message")}
        <HStack>
          {this.renderField("Language", doc.lang, width)}
          {this.renderField("Type", Accessor.Get(doc, "Input.Type"), width)}
        </HStack>
        {this.renderField("Input", Accessor.Get(doc, "Input.Content"), width)}
        {this.renderField("Product", Accessor.Get(doc, "Context.product"), width)}
      </VStack>
    );
  }

  renderBasic(doc) {
    return (
      <VStack width="50%" gap={2}>
        {this.renderHeader("Basic Information")}
        {this.renderField("Conversation ID", doc._id)}
        {this.renderField("Session ID", doc.sessionID)}
        {this.renderField("Server Recieve Time", this.transDate(doc.recTime))}
        {this.renderField("Server Sent Time", this.transDate(doc.outTime))}
        {this.renderField("Process Time", doc.processTime + "s")}
        {this.renderField("Channel", doc.channel)}
        {Authority.IsAccessibleQ("Convlog", 0, ["chatbotVersion"]) && this.renderField("Chatbot Version", doc.chatbotVersion)}
      </VStack>
    );
  }

  renderAnsRes(doc) {
    let mapping = {
      __change: "Change Topic?",
      __func: "Pre-Function",
      __ans: "Direct Answer",
      __tans: "Direct Template",
      __bfunc: "Post-Function",
      __param: "Other Parameters",
    };

    return _.map(mapping, (o, i) => {
      const accessible = Authority.IsAccessibleQ("Convlog", 0, [i]);

      if (i === "__change") return this.renderField(mapping[i] || "", (doc.ansRes && doc.ansRes[i]) || "false");
      else if (i === "__ans") return this.renderField(mapping[i] || "", (doc.ansRes && doc.ansRes[i]) || "N/A");
      else return accessible && this.renderField(mapping[i] || "", (doc.ansRes && doc.ansRes[i]) || "N/A");
    });
  }

  renderSummary(doc) {
    return (
      <VStack width="50%" gap={2}>
        {this.renderHeader("Response Summary")}
        {this.renderAnsRes(doc)}
      </VStack>
    );
  }

  renderInputBasic(doc) {
    let width = 120;
    return (
      <VStack width="40%" gap={2}>
        {this.renderHeader("NLP Basic")}
        {this.renderField("Workspace", doc.ws, width)}
        {this.renderField("Intent", doc.intent, width)}
        {this.renderField("Confidence", doc.confidence, width)}
        {this.renderField("LiveChat (Beta)", doc.liveChat)}
      </VStack>
    );
  }

  renderInfo(doc) {
    return (
      <VStack width="100%" gap={2} height="auto">
        <HStack gap={2} justifyContent="flex-start" alignItems="flex-start">
          {this.renderMessage(doc)}
          {this.renderBasic(doc)}
        </HStack>
        <HStack gap={2} justifyContent="flex-start" alignItems="flex-start">
          {this.renderInputBasic(doc)}
          {this.renderSummary(doc)}
        </HStack>
      </VStack>
    );
  }

  render() {
    let { doc, prev } = this.props;
    if (!doc) {
      return (
        <VStack>
          <Typography>{"Conversation Log cannot be loaded"}</Typography>
        </VStack>
      );
    }
    return (
      <HStack justifyContent="flex-start" sx={{ flexWrap: "wrap", height: "auto" }}>
        <VStack
          padding={1}
          height="100%"
          sx={{
            flexWrap: "wrap",
            width: "60%",
            minWidth: "768px",
          }}
        >
          {this.renderInfo(doc)}
          {this.renderNLP(doc)}
        </VStack>
        <VStack width={"40%"} padding={1} alignItems="flex-start" height="100%" sx={{ alignItems: "flex-start", justifyContent: "flex-start", minWidth: "300px" }}>
          {this.renderChat(doc, prev)}
          {this.renderJSON(doc)}
        </VStack>
      </HStack>
    );
  }
}

export default ConvlogDetail;
