import React, { Component } from "react";

import { Box, Typography } from "@mui/material";

import schema from "./schema";
import datalink from "./datalink";

import Datumizo from "IZOArc/LabIZO/Datumizo/Datumizo";
import { VStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, ColorX, Authority } from "IZOArc/STATIC";

class Answer extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "GENERIC ANSWERS",
      serverSidePagination: false,
      base: {
        title: "Generic Answers",
        exportDoc: "Generic_Answers",
        schema: schema,
        reqAuth: "Answer",

        tablizo: {
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
          showSelector: true,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Add: {
            title: "Add Answer",
            url: datalink.Request.Add,
            success: "Answer Added Successfully",
            fail: "Answer Add Failed: ",
            schema: schema.Add,
            buttons: ["Clear", "Submit"],
            onSubmit: "Add",
          },
          Delete: {
            title: "Delete this Answer?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Delete,
            success: "Answer Deleted Successfully.",
            fail: "Answer Delete Failed: ",
            onSubmit: "Delete",
          },
          Edit: {
            title: "Edit Answer ",
            url: datalink.Request.Edit,
            success: "Answer Edited Successfully",
            fail: "Answer Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },
          Info: {
            title: "Answer",
            schema: schema.Info,
            readOnly: true,
          },
          DeleteBulk: {
            title: (n) => "Delete these " + n + " Answers?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.DeleteBulk,
            success: "Answers Deleted Successfully.",
            fail: "Answers Delete Failed: ",
            onSubmit: "DeleteBulk",
          },
        },

        buttons: {
          inline: [
            { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
            { icon: "info", func: "Info", caption: "Details" },
          ],
          inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete", reqLevel: 0 }],
          left: [{ icon: "add", func: "Add", caption: "Add Answer", reqFunc: "Add", reqLevel: 0 }],
          right: [
            { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution", reqLevel: 0 },
            { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export", reqLevel: 0 },
            { icon: "import", func: "Import", caption: "Import", reqFunc: "Import", reqLevel: 0 },
          ],
        },
      },
    };
  }

  componentDidMount() {
    Authority.Require("Answer");
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Answer.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  render() {
    let { base, serverSidePagination, title } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo base={base} serverSidePagination={serverSidePagination} />
      </VStack>
    );
  }
}

export default Answer;
