import { Grid, Typography } from "@mui/material";
import { DBAnswerSpec } from "@types";
import { store } from "IZOArc/STATIC";
import { ContextId } from "Pages/FLE/components";
import { useFormContext } from "react-hook-form";
import { ContextTypeTypography } from "../../../components/ContextTypeTypography";
import { IFLEDoc, TFLEContext } from "../../../interfaces/FLE.interface";

interface IContextSummaryProps {
  index: number;
  name: `contexts.${number}`;
}
export const ContextSummary = ({ index, ...props }: IContextSummaryProps) => {
  const defaultLang = store.sysInfo.Language.default || "EN";
  const { watch } = useFormContext<IFLEDoc<DBAnswerSpec>>();

  const context = watch(props.name) as TFLEContext;

  return (
    <Grid container p={2} item xs={11}>
      <Grid item xs={3}>
        <ContextId name={`contexts.${index}.alias.id`} />
      </Grid>

      <Grid item xs={1}>
        <ContextTypeTypography>{context.type}</ContextTypeTypography>
      </Grid>

      {context.type === "system" && (
        <Grid item xs={2}>
          <ContextTypeTypography>{context.setting.type}</ContextTypeTypography>
        </Grid>
      )}

      {context.type === "entity" && (
        <Grid item xs={2}>
          <Typography sx={{ textAlign: "center" }}>{context.setting.values.map((o) => o.entity).join(", ")}</Typography>
        </Grid>
      )}

      <Grid item xs={6}>
        <Typography sx={{ textAlign: "center" }}>{context.question[defaultLang]}</Typography>
      </Grid>
    </Grid>
  );
};
