import GraphTitle from "../../Graphs/GraphTitle";
import GPie from "../../Graphs/GPie";
import "./Accuracy.scss";

import { VStack } from "IZOArc/LabIZO/Stackizo";

const Accuracy = ({ title, data }) => {
  return (
    <VStack className="Accuracy" width="100%">
      <GraphTitle title={title} offset={25} />
      <GPie data={data} toPercent={true} />
    </VStack>
  );
};

export default Accuracy;
