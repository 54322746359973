const Request = {
  DBInfo: "/Tables/WhatsappPermissionList/Info",
  List: "/Tables/WhatsappPermissionList/List",
  Add: "/Tables/WhatsappPermissionList/Add",
  Delete: "/Tables/WhatsappPermissionList/Delete",
  Edit: "/Tables/WhatsappPermissionList/Edit",
  Export: "/Tables/WhatsappPermissionList/Export",
  Import: "/Tables/WhatsappPermissionList/Import",
  DeleteBulk: "/Tables/WhatsappPermissionList/DeleteBulk",
};

let exports = {
  Request
};

export default exports;