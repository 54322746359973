import React from "react";

import _ from "lodash";
import { Grid } from "@mui/material";

import ScoreCard from "./ScoreCard/ScoreCard";
import ScoreCardVar from "./ScoreCard/ScoreCardVar";
import Header from "./Header/Header";
import Usage from "./Usage/Usage";
import Performance from "./Performance/Performance";
import Report from "./Report/Report";
import "./Dashboard.scss";
import { VStack, HStack } from "IZOArc/LabIZO/Stackizo";
import { Button } from "@mui/material";

import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const DashboardHook = ({ data, base, dateRange, onChangeDateRange }) => {
  const QuickView = [7, 30, 365];

  const renderScoreCards = (data) => {
    let scorecard_items = _.map(data, (o, i) => {
      if (i === 0 || i === 1) {
        return (
          <Grid item xs={6} lg={6} key={i} style={{ display: "flex" }}>
            <ScoreCard key={i} title={o.title} values={o.values} remarks={o.remarks}></ScoreCard>
          </Grid>
        );
      } else {
        return (
          <Grid item xs={6} lg={6} key={i}>
            <ScoreCardVar key={i} title={o.title} values={o.values} remarks={o.remarks}></ScoreCardVar>
          </Grid>
        );
      }
    });
    return (
      <div className={"ScoreCards"}>
        <Grid container spacing={2}>
          {scorecard_items}
        </Grid>
      </div>
    );
  };

  let { titles, graphTitles } = base;
  return (
    <Grid container spacing={2} className="Dashboard" width={"100%"} sx={{ pt: "10px", height: "100%", width: "100%" }}>
      {/* Top Left */}
      <Grid container item xs={12} lg={7} sx={{ display: "flex", height: "50%", width: "100%", maxWidth: "100%" }}>
        <VStack width={"100%"} style={{ height: "100%", justifyConent: "space-between" }}>
          <HStack width={"100%"}>
            <Header title={titles.Usage} />

            <HStack>
              {/* Quick View */}
              {QuickView.map((o, i) => (
                <Button
                  key={i}
                  onClick={() => {
                    onChangeDateRange([moment().subtract(o - 1, "d"), moment()]);
                  }}
                >
                  {o + "D"}
                </Button>
              ))}
              <RangePicker value={[dateRange.from, dateRange.to]} onChange={onChangeDateRange} />
            </HStack>
          </HStack>

          <Usage title={graphTitles.DailyCov} data={data} />
        </VStack>
      </Grid>

      {/* Top Right */}
      <Grid container item xs={12} lg={5} style={{ height: "50%", paddingTop: "10px" }}>
        <VStack width="100%">
          <Report base={base} dateRange={dateRange} />
          {renderScoreCards(data && data.Overview)}
        </VStack>
      </Grid>

      {/* Bot Left */}
      <Grid container item xs={12} lg={7} style={{ height: "50%", width: "100%" }}>
        <Performance base={base} data={data} />
      </Grid>

      {/* Bot Right */}
      <Grid item xs={12} lg={5} style={{ height: "50%" }}>
        <VStack>
          <Header title={titles.CutomerBehaviour} />
          {renderScoreCards(data && data.CustomerBehavior)}
        </VStack>
      </Grid>
    </Grid>
  );
};

export default DashboardHook;
