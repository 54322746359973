import { Button } from "@mui/material";
import { HStack } from "v2/Components/Layouts";
import { IFLETab } from "../constants/defaultTabs";

export function TabSelector({ tabs, tabIdx, setTabIdx }: ITabSelectorProps): JSX.Element {
  return (
    <HStack gap={2} justifyContent={"flex-start"} width={"50%"}>
      {tabs &&
        tabs.map((tab, index) => {
          return (
            <Button //
              key={`fle-tab-${index}`}
              fullWidth
              onClick={() => setTabIdx(index)}
              variant={index === tabIdx ? "contained" : "text"}
            >
              {tab.name}
            </Button>
          );
        })}
    </HStack>
  );
}
export interface ITabSelectorProps {
  tabs: IFLETab[];
  tabIdx: number;
  setTabIdx: (idx: number) => void;
}
