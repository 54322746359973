import { IFLEDoc } from "../interfaces/FLE.interface";
import { DBAnswerSpec } from "@types";

export const defaultDoc: IFLEDoc<DBAnswerSpec> = {
  _id: "",
  alias: "",
  contexts: [],
  intentDoc: {
    _id: "",
  },
  answers: {
    defaultAnsDoc: {
      _id: "",
      text: { EN: [""] },
    },
  },
};
