import React, { useState } from "react";

import Validation from "./Validation";
import _ from "lodash";
import TextField from "@mui/material/TextField";

const { Rules, ErrorMsg } = Validation;

const Validate = (value, criteria = []) => {
  let error = "";
  _.map(criteria, (o, i) => {
    if (!Rules[o](value)) {
      if (_.isEmpty(error)) {
        error = ErrorMsg[o];
      }
    }
  });
  return { err: !_.isEmpty(error), msg: error };
};

const PasswordFeild = ({ autoFocus = false, id = "", criteria = [], label = "", onInput }) => {
  const [error, setError] = useState(true);
  const [msg, setMsg] = useState("Required!");

  const handleInput = (value) => {
    let { err, msg } = Validate(value, criteria);
    setError(err);
    setMsg(msg);
    if (!err) onInput(value);
  };

  return <TextField error={error} autoFocus={autoFocus} margin="dense" id={id} label={label} type="password" fullWidth onInput={(e) => handleInput(e.target.value)} helperText={msg && msg} />;
};

export default PasswordFeild;
