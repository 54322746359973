import _ from "lodash";
import { Warning, RemoveCircle } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import "../TBank.css";
import CheckPass from "../CheckPass";
import ZCMsg from "_Utilities/ZChat/ZCMsg/ZCMsg";
import ZEye from "_Utilities/ZEye/ZEye";

import { HStack, VStack } from "IZOArc/LabIZO/Stackizo";
import { ColorX } from "IZOArc/STATIC";

const Table = [
  {
    label: "Language",
    name: "lang",
    width: 115,
  },
  {
    label: "P. Time",
    name: "After.processTime",
    width: 115,
  },
  {
    label: "Question",
    name: "Input.Content",
  },
  {
    label: "Answer (Before)",
    name: "Before.Response.0.msg.text",
  },
  {
    label: " ",
    name: "Before.Response",
    width: 60,
    Cell: (row, field, addOns) => (
      <HStack>
        <ZEye>
          <VStack>
            {_.map(field, (o, i) => {
              return <ZCMsg key={i} cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />;
            })}
          </VStack>
        </ZEye>
      </HStack>
    ),
  },
  {
    label: "Answer (After)",
    name: "After.Response.0.msg.text",
  },
  {
    label: " ",
    name: "After.Response",
    width: 60,
    Cell: (row, field, addOns) => (
      <HStack>
        <ZEye>
          <VStack>
            {_.map(field, (o, i) => {
              return <ZCMsg key={i} cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />;
            })}
          </VStack>
        </ZEye>
      </HStack>
    ),
  },
  {
    label: "Accept",
    name: "After.Tag.passed",
    defaultSort: "asc",
    width: 115,
    Cell: (row, field, addOns) => {
      let { trainID, onVerifyToggle } = addOns;
      if (!row.Before.Tag.ignored) return <CheckPass docID={row._id} doc={row} tagged={row.After.Tag.tagged || row.After.Tag.preTagged} passed={field} trainID={trainID} onToggle={onVerifyToggle} />;
      return <div />;
    },
  },
  {
    label: "Warnings",
    name: "After.Tag.preTagged",
    width: 120,
    Cell: (row, field, addOns) => {
      if (field && !row.After.Tag.tagged) {
        return (
          <HStack>
            <Tooltip title={"Tagged by Classifier"}>
              <Warning style={{ color: ColorX.GetColorCSS("Caution") }} />
            </Tooltip>
          </HStack>
        );
      } else {
        return <HStack />;
      }
    },
  },
  {
    label: "-",
    name: "Before.Tag.ignored",
    width: 80,
    Cell: (row, field, addOns) => {
      if (field) {
        return (
          <HStack>
            <Tooltip title={"Entry is Ignored"}>
              <RemoveCircle style={{ color: ColorX.GetColorCSS("grey") }} />
            </Tooltip>
          </HStack>
        );
      }
      return <HStack />;
    },
  },
];

const Info = [];

const schema = {
  Table,
  Info,
};

export default schema;
