import React, { Component } from "react";

import PropsType from "prop-types";
import { IconButton } from "@mui/material";

import MUIUtils from "./_gears/MUIUtils";

import { Accessor, ColorX } from "IZOArc/STATIC";

class StyledIconButton extends Component {
  render() {
    const { theme, children, ...others } = this.props;
    return (
      <IconButton sx={{ height: "50x", width: "50px" }} color={"primary"} {...others}>
        {children}
      </IconButton>
    );
  }
}

export default StyledIconButton;
