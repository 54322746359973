import { TextField } from "@mui/material";
import { AvailLANG, DBAnswerSpec } from "@types";
import { useContext } from "react";
import { ControlledTextField } from "v2/Components/HookForm/ControlledTextField";
import { VStack } from "v2/Components/Layouts";
import { FLEFormContext } from "../hooks/FLEContexts";
import { IFLEDoc } from "../interfaces/FLE.interface";
import { store } from "IZOArc/STATIC";
import { Control, Controller, UseControllerProps, FieldValues, Path } from "react-hook-form";

interface IProps<TFieldValues extends FieldValues = FieldValues> {
  langs?: AvailLANG[];
  name: Path<TFieldValues>;
  control: Control<TFieldValues, any>;
  disabled?: boolean;
}

export function ControlledMultilangAnswerFeilds<T extends FieldValues = FieldValues>({ langs = ["EN", "TC", "SC"], name, control, disabled, ...props }: IProps<T>) {
  const defaultLang = store.sysInfo.Language.default;
  const formCtxt = useContext(FLEFormContext);

  return (
    <VStack width="100%">
      {langs.map((lang) => {
        return (
          <ControlledTextField<T>
            //
            name={`${name}.text.${lang}.0` as Path<T>}
            control={control}
            label={lang}
            key={lang}
            required={lang == defaultLang}
            rules={{
              required: lang == defaultLang ? "This field is required" : undefined,
            }}
            disabled={disabled || formCtxt?.mode == "Info"}
            fullWidth
            multiline
            {...props}
          />
        );
      })}
    </VStack>
  );
}
