import { DBAnswerSpec } from "@types";
import { IFLEDoc } from "Pages/FLE/interfaces/FLE.interface";
import { Control } from "react-hook-form";
import { IValidationPayload } from "v2/Components";
import { AnswerSetup } from "../FLESetup/FLETabs/AnswerSetup";
import { ContextSetup } from "../FLESetup/FLETabs/ContextSetup";
import { QuestionSetup } from "../FLESetup/FLETabs/QuestionSetup";

export interface IFLETab {
  name: string;
  component: (id: string) => JSX.Element;
}
export const defaultTabs: IFLETab[] = [
  { name: "Question", component: () => <QuestionSetup /> },
  { name: "Context", component: () => <ContextSetup /> },
  { name: "Answer", component: (id) => <AnswerSetup /> },
];
