/*REACT*/
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";

import schema from "./schema";
import { Request } from "./datalink";

import Datumizo from "IZOArc/LabIZO/Datumizo/Datumizo";
import { VStack } from "IZOArc/LabIZO/Stackizo";
import { ColorX, Authority, ErrorX } from "IZOArc/STATIC";
import { store } from "IZOArc/STATIC";
import axios from "axios";
import { DOMAIN } from "../../config/config";
import { AccessTime, CastForEducation, Close, SkipNext } from "@mui/icons-material";
const T2VATrainer = () => {
  useEffect(() => {
    Authority.Require("Talk2VA");
  }, []);
  let dtizo: any = null;
  const MountDtizo = (callback: any) => {
    dtizo = callback;
  };

  const Learn = {
    onClick: (id: string, data: object) => {
      store.Ask("Learn", "Confirm Changes", async () => {
        await Learn.onConfirm(id, data);
      });
    },
    onConfirm: async (id: string, data: object) => {
      console.log("Learn");
      let url = DOMAIN + Request.Learn;
      let payload = {
        data: data,
        JWT: store.user.JWT,
      };
      try {
        let res = await axios.post(url, payload);
        if (res.data.Success === true) {
          Learn.onSuccess(res);
        } else {
          Learn.onFail(res.data?.payload);
        }
      } catch (e) {
        Learn.onFail(e);
      }
    },
    onSuccess: (res: any) => {
      store.Alert("Learn", "success");
      dtizo.Reload(true);
    },
    onFail: (e: any) => {
      const msg = "Cannot learn example: " + e.message;
      ErrorX.Handle({ ...e, message: msg });
      dtizo.Reload();
    },
  };

  const Pass = {
    onClick: (id: string, data: object) => {
      store.Ask("Pass", "Confirm Changes", async () => {
        await Pass.onConfirm(id, data);
      });
    },
    onConfirm: async (id: string, data: object) => {
      console.log("Pass");
      let url = DOMAIN + Request.Pass;
      let payload = {
        data: data,
        JWT: store.user.JWT,
      };
      try {
        let res = await axios.post(url, payload);
        if (res.data.Success === true) {
          Pass.onSuccess(res);
        } else {
          Pass.onFail(res.data?.payload);
        }
      } catch (e) {
        Pass.onFail(e);
      }
    },
    onSuccess: (res: any) => {
      store.Alert("Set to Pass", "success");
      dtizo.Reload(true);
    },
    onFail: (e: any) => {
      const msg = "Cannot set example to pass: " + e.message;
      ErrorX.Handle({ ...e, message: msg });
      dtizo.Reload();
    },
  };

  const Pending = {
    onClick: (id: string, data: object) => {
      store.Ask("Pending", "Confirm Changes", async () => {
        await Pending.onConfirm(id, data);
      });
    },
    onConfirm: async (id: string, data: object) => {
      console.log("Pending");
      let url = DOMAIN + Request.Pending;
      let payload = {
        data: data,
        JWT: store.user.JWT,
      };
      try {
        let res = await axios.post(url, payload);
        if (res.data.Success === true) {
          Pending.onSuccess(res);
        } else {
          Pending.onFail(res.data?.payload);
        }
      } catch (e) {
        Pending.onFail(e);
      }
    },
    onSuccess: (res: any) => {
      store.Alert("Set to Pending", "success");
      dtizo.Reload(true);
    },
    onFail: (e: any) => {
      const msg = "Cannot set example to pending: " + e.message;
      ErrorX.Handle({ ...e, message: msg });
      dtizo.Reload();
    },
  };

  const Reject = {
    onClick: (id: string, data: object) => {
      store.Ask("Reject", "Confirm Changes", async () => {
        await Reject.onConfirm(id, data);
      });
    },
    onConfirm: async (id: string, data: object) => {
      console.log("Reject");
      let url = DOMAIN + Request.Reject;
      let payload = {
        data: data,
        JWT: store.user.JWT,
      };
      try {
        let res = await axios.post(url, payload);
        if (res.data.Success === true) {
          Reject.onSuccess(res);
        } else {
          Reject.onFail(res.data?.payload);
        }
      } catch (e) {
        Reject.onFail(e);
      }
    },
    onSuccess: (res: any) => {
      store.Alert("Set to Reject", "success");
      dtizo.Reload(true);
    },
    onFail: (e: any) => {
      const msg = "Cannot set example to reject: " + e.message;
      ErrorX.Handle({ ...e, message: msg });
      dtizo.Reload();
    },
  };

  const base = {
    title: "T2VA Trainer Entry",
    exportDoc: "T2VA_Trainer_Entry",
    schema: schema,
    reqAuth: "Talk2VA",

    tablizo: {
      columnsToolbar: true,
      filterToolbar: true,
      densityToolbar: true,
      exportToolbar: false,
      showSelector: true,
    },
    Connect: {
      DeleteBulk: Request.DeleteBulk,
      List: Request.List,
      schema: schema.Table,
    },

    operations: {
      Add: {
        title: "Add Entry",
        url: Request.Add,
        success: "Answer Added Successfully",
        fail: "Entry Add Failed: ",
        schema: schema.Add,
        buttons: ["Clear", "Submit"],
        onSubmit: "Add",
      },
      Delete: {
        title: "Delete this Entry?",
        content: "Caution: This is irrevertable.",
        url: Request.Delete,
        success: "Entry Deleted Successfully.",
        fail: "Entry Delete Failed: ",
        onSubmit: "Delete",
      },
      Edit: {
        title: "Edit Entry ",
        url: Request.Edit,
        success: "Entry Edited Successfully",
        fail: "Entry Edit Failed: ",
        schema: schema.Edit,
        buttons: ["Revert", "Submit"],
        onSubmit: "Edit",
      },
      Info: {
        title: "Entry",
        schema: schema.Info,
        readOnly: true,
      },
      DeleteBulk: {
        title: "Delete the Selected Entries?",
        content: "Caution: This is irrevertable.",
        url: Request.DeleteBulk,
        success: "Entries Deleted Successfully.",
        fail: "Entries Delete Failed: ",
        onSubmit: "DeleteBulk",
      },
    },

    buttons: {
      inline: [
        { icon: <CastForEducation />, func: Learn, caption: "Submit to Learn" },
        { icon: <AccessTime />, func: Pending, caption: "Discuss" },
        { icon: <SkipNext />, func: Pass, caption: "Pass" },
        { icon: <Close />, func: Reject, caption: "Reject" },
      ],
      inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete", reqLevel: 0 }],
      left: [],
      right: [{ icon: "deletebulk", func: "DeleteBulk", caption: (n: number) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution", reqLevel: 0 }],
    },
  };
  const title = "T2VATrainer";

  return (
    <VStack>
      <Box padding={1} width="100%">
        <Typography
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: 25,
            color: ColorX.GetColorCSS("Primary"),
          }}
        >
          {title}
        </Typography>
      </Box>
      <Datumizo base={base} serverSidePagination={false} onMounted={MountDtizo} />
    </VStack>
  );
};

export default T2VATrainer;
