import { ConditionOperator, FieldType, Filterable } from "IZOArc/LabIZO/Tablizo";

export const Filterables: Filterable[] = [
  {
    field: "alias",
    label: "FAQ ID",
    type: FieldType.text,
  },
  {
    field: "$description",
    label: "Button Caption",
    type: FieldType.custom,
    CustomFilterOperator: {
      contains: ConditionOperator.contains,
    },
    CustomCondition: (condition) => {
      return {
        $custom: {
          field: condition.field,
          operator: "contains",
          value: condition.value,
        },
      };
    },
  },
  {
    field: `$question`,
    label: "Question",
    type: FieldType.custom,
    CustomFilterOperator: {
      contains: ConditionOperator.contains,
    },
    CustomCondition: (condition) => {
      return {
        $custom: {
          field: condition.field,
          operator: "contains",
          value: condition.value,
        },
      };
    },
  },
  {
    field: `$answer`,
    label: "Answer",
    type: FieldType.custom,
    CustomFilterOperator: {
      contains: ConditionOperator.contains,
    },
    CustomCondition: (condition) => {
      return {
        $custom: {
          field: condition.field,
          operator: "contains",
          value: condition.value,
        },
      };
    },
  },
];
