import { Alert } from "@mui/material";
import { DBAnswerSpec } from "@types";
import { FieldErrors } from "react-hook-form";
import { IFLEDoc } from "../interfaces/FLE.interface";

export function ErrorSummary({ errors }: { errors: FieldErrors<IFLEDoc<DBAnswerSpec>> }) {
  return (
    <>
      {errors.intentDoc && <Alert severity="error">{"Settings are incomplete on Question Page."}</Alert>}
      {errors.contexts && <Alert severity="error">{"Settings are incomplete on Context Page."}</Alert>}
      {errors.answers && <Alert severity="error">{"Settings are incomplete on Answer Page."}</Alert>}
      {errors.alias && <Alert severity="error">{"Alias is invalid"}</Alert>}
    </>
  );
}
