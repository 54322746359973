const Request = {
  DBInfo: "/Base/User/Info",
  List: "/Base/User/List",
  Add: "/Base/User/Add",
  Delete: "/Base/User/Delete",
  Edit: "/Base/User/Edit",
  Export: "/Base/User/Export",
  Import: "/Base/User/Import",
  DeleteBulk: "/Base/User/DeleteBulk",
  // Unlock: "/Base/User/Unlock",
};

let exports = {
  Request,
};

export default exports;
