import axios from "axios";
import { DOMAIN } from "config/config";
import { ErrorX, store } from "IZOArc/STATIC";
import { useEffect, useState } from "react";
import { IUIEntity } from "../interfaces/IUIEntity";

export function useEntities() {
  const [entities, setEntities] = useState<IUIEntity[] | null>(null);
  useEffect(() => {
    async function fetchEntities() {
      const url = DOMAIN + "/Tables/Entity/List";
      const body = {
        JWT: store.user.JWT,
        data: {
          selector: {
            $and: [{ domain: { $eq: "master" } }],
          },
        },
        addOns: {
          workspace: "master",
        },
      };
      const { data } = await axios.post(url, body);
      const { Success, payload } = data;
      if (!Success) ErrorX.Handle(payload);
      setEntities(payload.docs);
    }
    fetchEntities();
  }, []);
  return entities;
}
