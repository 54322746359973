import React, { Component } from 'react';
import PropsType from 'prop-types';
import _ from 'lodash';

import ZCTouch from '../ZCTouch';

import "./ZCMsg.css";

class ZCMImgButtons extends Component {

  static propTypes = {
    buttons: PropsType.array,
    disabled: PropsType.bool
  }

  static defaultProps = {
    buttons: [],
    disabled: true
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  renderImgButtons(){
    let {cssp, buttons, _onQuickReply, onPhoneClick, onWebClick, disabled} = this.state;

    let rendered = [];
    _.map(buttons, (o, i) => {
      let func = null;
      switch (o.type){
        case 'web':
          func = () => onWebClick(o.payload);
          break;
        case 'phone':
          func = () => onPhoneClick(o.payload);
          break;
        default:
          func = () => _onQuickReply(o);
          break;
      } 

      rendered.push(
        <div key={i} className={cssp + " msg-imgbtn " + (disabled? "disabled" : "")}>
          <ZCTouch cssPrefix={cssp} onPress={func} disabled={disabled}>
            <div className={cssp + " msg-imgbtn-img " + (disabled? "disabled" : "")}>
              <img src={o.image} alt={o.title} draggable={false}/>
            </div>
            {o.showText &&
              <div className={cssp + " msg-imgbtn-text " + (disabled? "disabled" : "")}>
                {o.title}
              </div>
            } 
          </ZCTouch>
        </div>
      );
    });

    return rendered;
  }

  render(){
    let { cssp } = this.state;
    return (
      <div className={cssp + " msg-imgbtnlist"}>
        {this.renderImgButtons()}
      </div>
    )
  }
}

export default ZCMImgButtons;