import _ from "lodash";

const Rules = {
  required: (value) => !_.isEmpty(value) || _.isNumber(value),
  minimum8: (value) => !value || /^.{8,}$/i.test(value),
  comprise: (value) => !value || /^(?=.*[A-z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{8,}$/i.test(value),
};

const ErrorMsg = {
  required: "Required!",
  minimum8: "Must have minimum of (8) characters",
  comprise: "Must be comprised of letters, numbers, and special characters",
};

const Criteria = [
  "• Be a minimum of eight (8) characters in length; ",
  "• Be comprised of letters, numbers, and special characters to the extent possible;",
  // "• A maximum lifespan of 90 days for any password except system service account; ",
  "• Password history to avoid reuse of last thirteen (13) passwords; and ",
  // "• Account lockout after ten (10) invalid logon attempts",
];

const Validation = {
  Rules,
  ErrorMsg,
  Criteria,
};

export default Validation;
