import React, { Component } from 'react';
import PropsType from 'prop-types';

import moment from 'moment';

import "./ZCMsg.css";

class ZCMFooter extends Component {

  static propTypes = {
    cssPrefix: PropsType.string,
    pos: PropsType.string,
    createdAt: PropsType.oneOfType([PropsType.object,PropsType.string]),
    status: PropsType.string,
    lapseTime: PropsType.number,
    showStatus: PropsType.bool,
    showDateTime: PropsType.bool,
    showLapseTime: PropsType.bool
  }

  static defaultProps = {
    cssPrefix: "",
    pos: "out",
    createdAt: null,
    status: "",
    lapseTime: 0,
    showStatus: true,
    showDateTime: true,
    showLapseTime: true
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({ 
      ...props
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat){
    if(prevStat !== nextProps){
      return {
        ...nextProps
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  renderLapseTime(){
    let { cssp, showLapseTime, lapseTime } = this.state;
    if(!showLapseTime || !lapseTime) return;
    return (
      <div className={cssp + " msg-footer-lapse"}>
        {lapseTime + "s"}
      </div>
    );
  }

  renderStatus(){
    let { cssp, showStatus, status } = this.state;
    if(!showStatus) return;

    let icon = <div/>;
    switch(status){
      case "pending":
        icon = <i className="fas fa-clock"/> 
        break;
      case "sent":
        icon = <i className="fas fa-check"/>
        break;
      case "received": 
      icon = <i className="fas fa-check-double"/>
        break;
      case "read":
        icon = <i className="fas fa-book-reader"/>
        break;
      case "error":
        icon = <i className="fas fa-times"/>
        break;
      default:
        icon = <i className="fas fa-clock"/> 
        break;
    }

    return (
      <div className={cssp + " msg-footer-status"}>
        {icon}
      </div>
    );
  }

  renderDateTime(){
    let { cssp, showDateTime, createdAt } = this.state;
    if(!showDateTime) return;
    let format = "HH:mm";
    let createdAtM = moment(createdAt);
    if(moment().date !== createdAtM.date){
      format = "DD MMM HH:mm"
    }
    if(moment().year !== createdAtM.year){
      format = "DD MMM, YYYY HH:mm"
    }

    return (
      <div className={cssp + " msg-footer-datetime"}>
        {createdAtM.format(format)}
      </div>
    );
  }

  render(){
    let { cssp, pos } = this.state;
    return (
      <div className={cssp + " msg-footer " + pos}>
        {pos === "in" && this.renderLapseTime()}
        {pos === "out" && this.renderStatus()}
        {this.renderDateTime()}
      </div>
    )
  }
}

export default ZCMFooter;