import { T2EContext, T2EResponse } from "@types";
import axios from "axios";
import { Accessor, store, ErrorX, ColorX } from "IZOArc/STATIC";
import { ChatDOMAIN } from "config/config";

export const contextAPI = {
  update: async (
    contexts: T2EContext[],
    sessionId: string,
    actionId: string,
    config = {
      url: `${ChatDOMAIN}/Talk2VA/context/${actionId}`,
    }
  ): Promise<T2EResponse | void> => {
    try {
      console.log(`[>] Updating context for action ${actionId}`);

      const res = await axios.post<T2EResponse>(config.url, {
        // JWT: store.user.JWT,
        session_id: sessionId,
        contexts,
      });

      return res.data;
    } catch (e: any) {
      ErrorX.Handle(e);
    }
  },
};
