import axios from "axios";
import { DOMAIN } from "./config";
import store from "../IZOArc/STATIC/AppStore";

interface IGetProductItemsRes {
  Success: boolean;
  payload: string[];
}

export const getProductItems = async (planName: string): Promise<string[]> => {
  //if (store.productItems?.items) {
  //  return store.productItems.items;
  //}

  const apiPayload = {
    JWT: store.user.JWT,
    data: {},
  };
  const { data }: { data: IGetProductItemsRes } = await axios.post(`${DOMAIN}/Data/ProductList/GetProductItems`, apiPayload);

  if (!data.Success) {
    throw new Error("Error:: Cannot fetch product items from backend");
  }

  store.setProductItems(data.payload);
  return data.payload;
};
