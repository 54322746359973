import React, { Component } from "react";

import { Box, Typography } from "@mui/material";

import schema from "./schema";
import datalink from "./datalink";

import Datumizo from "IZOArc/LabIZO/Datumizo/Datumizo";
import { VStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, ColorX, Authority } from "IZOArc/STATIC";

/**
 * @augments {Component<Props, State>}
 */
class CounterExample extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "COUNTER EXAMPLES",
      serverSidePagination: false,
      base: {
        title: "Counter Example",
        exportDoc: "counterexample",
        schema: schema,
        reqAuth: "Workspace.CounterExample",

        noDefaultTable: false,
        noDefaultButtons: false,

        tablizo: {
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
          density: "standard",
          defaultPageSize: 25,
          showSelector: true,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Add: {
            title: "Add Counter Example",
            url: datalink.Request.Add,
            success: "Counter Example Added Successfully",
            fail: "Counter Example Add Failed: ",
            schema: schema.Add,
            buttons: ["Clear", "Submit"],
            onSubmit: "Add",
          },
          Delete: {
            title: "Delete this Counter Example?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Delete,
            success: "Counter Example Deleted Successfully.",
            fail: "Counter Example Delete Failed: ",
            onSubmit: "Delete",
          },
          Edit: {
            title: "Edit Counter Example ",
            url: datalink.Request.Edit,
            success: "Counter Example Edited Successfully",
            fail: "Counter Example Edit Failed: ",
            schema: schema.Edit,
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },
          Info: {
            title: "Counter Examples ",
            url: datalink.Request.Info,
            success: "Counter Examples Load Successfully",
            fail: "Counter Examples Load Failed: ",
            schema: schema.Info,
            readOnly: true,
          },
          Import: {
            title: "Counter Example Import",
            content: "",
            url: datalink.Request.Import,
            success: "Counter Example Imported Successfully.",
            fail: "Counter Example Import Failed: ",
            schema: schema.ImportFormat,
            replace: false,
          },
          Export: {
            url: datalink.Request.Export,
            schema: schema.Export,
          },
          Sync: {
            title: "Sync from NLP",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Sync,
            success: "Entity Sync Successfully.",
            fail: "Entity Sync Failed: ",
            onSubmit: "Sync",
          },
          DeleteBulk: {
            title: (n) => "Delete these " + n + " Counter Example?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.DeleteBulk,
            success: "Counter Example Deleted Successfully.",
            fail: "Counter Example Delete Failed: ",
            onSubmit: "DeleteBulk",
          },
        },

        buttons: {
          inline: [
            { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
            { icon: "info", func: "Info", caption: "Details" },
            { icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" },
          ],
          left: [{ icon: "add", func: "Add", caption: "Add Counter Example", reqFunc: "Add" }],
          right: [
            { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution" },
            { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export" },
            { icon: "import", func: "Import", caption: "Import", reqFunc: "Import" },
            { icon: "syncfrom", func: "Sync", caption: "Sync", reqFunc: "Sync" },
          ],
        },
      },
      addOns: {},
    };
  }

  componentDidMount() {
    Authority.Require("Workspace.CounterExample");
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(CounterExample.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  render() {
    let { base, serverSidePagination, title, addOns } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo base={base} serverSidePagination={serverSidePagination} onMounted={this.onMountDatumizo} addOns={addOns} />
      </VStack>
    );
  }
}

export default CounterExample;
