import { Component } from "react";

import { observer } from "mobx-react";

import { ExitToAppOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";

import "./Container.css";
import Version from "config/version";
import IZOVersion from "../version";

import { Accessor, ColorX, store } from "IZOArc/STATIC";
import { HStack, Spacer } from "IZOArc/LabIZO/Stackizo";
import Accessizo from "IZOArc/LabIZO/Accessizo";
import { Project } from "config/config";

class NavBar extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(NavBar.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  _Logout = () => {
    store.isLoading(true);
    store.clearUser();
    store.clearProductItems();
    store.isLoading(false);
    store.Alert("Logout Successful", "success");
  };

  render() {
    return (
      <HStack width="100%" height="30px" bgcolor={ColorX.GetColorCSS("NavBar")} paddingRight={2} position="fixed" zIndex="300" overflow="hidden" style={{ userSelect: "none" }}>
        <HStack>
          <HStack width="fit-content">
            <Typography style={{ marginLeft: 10, fontFamily: "Palanquin", fontSize: 14, color: ColorX.GetColorCSS("Secondary", 0.5) }}>{IZOVersion}</Typography>
          </HStack>
          <HStack width="fit-content">
            <Typography style={{ marginLeft: 30, fontFamily: "Palanquin", fontSize: 14, color: ColorX.GetColorCSS("Secondary", 1) }}>{"Logged in as "}</Typography>
            <Typography style={{ marginLeft: 10, fontFamily: "Palanquin", fontSize: 14, color: ColorX.GetColorCSS("Secondary", 0.8) }}>{store.user && store.user.UserDisplayName}</Typography>
          </HStack>

          <Spacer />

          {/* <Accessizo reqLevel={0} auth={store.user.auth} level={store.user.level}>
            <Typography style={{ fontFamily: "Palanquin", color: ColorX.GetColorCSS("Secondary", 0.5) }}>{"v" + Version}</Typography>
          </Accessizo> */}
          <Box width="fit-content" marginX={3}>
            {Project}
          </Box>
          <Box
            position="absolute"
            sx={{
              width: 70,
              height: 70,
              marginX: 3,
              right: 180,
              top: -20,
              opacity: 0.2,
              backgroundColor: "white",
            }}
            className="syva_logo"
          >
            {/* <img src={NavbarDis && NavbarDis.src} alt="elain" draggable={false} /> */}
          </Box>
          <Tooltip title="Logout" arrow={true} placement="bottom">
            <IconButton style={{ color: ColorX.GetColorCSS("Secondary", 1) }} size="small" onClick={() => this._Logout()}>
              <ExitToAppOutlined />
            </IconButton>
          </Tooltip>
        </HStack>
      </HStack>
    );
  }
}

export default observer(NavBar);
