import { FormizoSchema } from "IZOArc/LabIZO/Formizo";

const initial: FormizoSchema[] = [
  {
    label: "RemoteDB same as BaseDB?",
    name: "sameAsBaseDB",
    format: "bool",
    boolStyle: "checkbox",
  },
  {
    control: "sameAsBaseDB",
    inverse: true,
    fold: [
      {
        label: "BASE",
        name: "CouchDB.BASE",
        format: "text",
      },
      {
        label: "USERNAME",
        name: "CouchDB.USERNAME",
        format: "text",
      },
      {
        label: "PASSWORD",
        name: "CouchDB.PASSWORD",
        format: "text",
      },
      {
        label: "URL",
        name: "CouchDB.URL",
        format: "text",
      },
    ],
  },
  {
    label: "Initial Watsons also?",
    name: "initialwatsons",
    format: "bool",
    boolStyle: "checkbox",
  },
  {
    label: "Use Default Watsons Workspace?",
    name: "defaultwatsons",
    format: "bool",
    boolStyle: "checkbox",
  },
  {
    control: "defaultwatsons",
    inverse: true,
    fold: [
      {
        label: "URL",
        name: "Watsons.URL",
        format: "text",
      },
      {
        label: "APIKEY",
        name: "Watsons.APIKEY",
        format: "text",
      },
      {
        label: "Version",
        name: "Watsons.VERSION",
        format: "text",
      },
      {
        label: "EN",
        name: "Watsons.EN",
        format: "text",
      },
      {
        label: "TC",
        name: "Watsons.TC",
        format: "text",
      },
      {
        label: "SC",
        name: "Watsons.SC",
        format: "text",
      },
    ],
  },
  {
    label: "Conversation Log Database",
    name: "ConvDB.type",
    format: "select",
    selectStyle: "dropdown",
    selectRef: "ConvDBTypes",
    selectCap: "caption",
    selectVal: "_id",
    placeholder: "Database Type",
    validate: ["required"],
  },
  {
    control: "ConvDB.type",
    controlFunc: (doc, field) => doc?.ConvDB?.type === "ElasticSearch",
    fold: [
      {
        label: "Username",
        name: "ConvDB.username",
        format: "text",
      },
      {
        label: "Password",
        name: "ConvDB.password",
        format: "text",
      },
      {
        label: "URL",
        name: "ConvDB.url",
        format: "text",
      },
      {
        label: "Index",
        name: "ConvDB.index",
        format: "text",
      },
      {
        label: "Index Timeframe",
        name: "ConvDB.indexTimeframe",
        format: "select",
        selectStyle: "dropdown",
        selectRef: "IndexTimeframeTypes",
        selectCap: "caption",
        selectVal: "_id",
        placeholder: "Index Timeframe Type",
        validate: ["required"],
      },
    ],
  },
];
export const schema = {
  initial,
};
