import React, { Component } from "react";

import { observer } from "mobx-react";

import "./Talk2VA.css";
import Analyzer from "./_gears/Analyzer";
import Messenger from "./_gears/Messenger";

import { Accessor, Authority } from "IZOArc/STATIC";
import { HStack, VStack } from "IZOArc/LabIZO/Stackizo";

class Talk2VA extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      sessionID: undefined,
      records: [],
    };
  }

  componentDidMount() {
    Authority.Require("Talk2VA");
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Talk2VA.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountMessenger = (callbacks) => {
    this.MountMessenger = callbacks;
  };

  onMountAnalyzer = (callbacks) => {
    this.MountAnalyzer = callbacks;
  };

  onMsgRecieved = (data, res, startTime, endTime, _id, version) => {
    this.MountAnalyzer.recordForInfo(data, res, startTime, endTime, _id, version);
  };

  onMsgSelected = (msgId) => {
    this.MountAnalyzer.selectMsg(msgId);
  };

  render() {
    return (
      <HStack alignItems={"flex-start"}>
        <VStack height={"95vh"} width={600} padding={2}>
          <Messenger onMounted={this.onMountMessenger} LiveChatEnabled={true} onMsgRecieved={this.onMsgRecieved} onMsgSelected={this.onMsgSelected} viaBackend={false} />
        </VStack>
        <Analyzer onMounted={this.onMountAnalyzer} />
      </HStack>
    );
  }
}

export default observer(Talk2VA);
