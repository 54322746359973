import { Box, Dialog, DialogTitle, CircularProgress, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import fileDownload from "js-file-download";
import { IReportDateRange } from "./Report";
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import { store } from "IZOArc/STATIC";
import { DOMAIN } from "config/config";

interface IReportDownloadDialogProps {
  open: boolean;
  handleCancel: (event: React.SyntheticEvent) => void;
  reportId: string;
  base: any;
  dateRange: IReportDateRange;
}

export const ReportDownloadDialog: React.FC<IReportDownloadDialogProps> = ({ open, handleCancel, reportId, base, dateRange }) => {
  const [loading, setLoading] = useState(true);
  const fetchInterval = useRef<NodeJS.Timer>();

  const handleClose = (event: React.SyntheticEvent, reason: string): void => {
    if (reason !== "backdropClick") handleCancel(event);
  };

  const fetchReportStatus = async (reportId: string): Promise<void> => {
    const url = `${DOMAIN}${base.url.ReportFetchStatus}`;
    const payload = {
      JWT: store.user.JWT,
      data: {
        reportId: reportId,
      },
    };
    const { data: responseData } = await axios.post(url, payload);
    if (!responseData.Success) store.Alert(`Failed to create report: ${responseData.payload.error}`);
    if (responseData.payload.ready) {
      try {
        await downloadReport();
      } catch (error: any) {
        store.Alert(`Failed to download report: ${error}`);
      }
      clearInterval(fetchInterval.current);
      setLoading(false);
      handleCancel(null!);
    }
  };

  const downloadReport = async (): Promise<void> => {
    const url = `${DOMAIN}${base.url.ReportDownloader}`;
    const payload = {
      JWT: store.user.JWT,
      data: { reportId },
    };
    const options: AxiosRequestConfig = { responseType: "blob" };
    const res = await axios.post(url, payload, options);

    const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    let docName = `${base.exportDoc}${dateRange.from.format("YYYYMMDD")}-${dateRange.to.format("YYYYMMDD")}.xlsx`;
    fileDownload(blob, docName);
  };

  useEffect(() => {
    fetchInterval.current = setInterval(() => fetchReportStatus(reportId), 10000);
    setTimeout(() => fetchReportStatus(reportId), 2000);
    //fetchReportStatus(reportId);
  }, []);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Preparing usage report {dateRange.from.format("YYYYMMDD") + "-" + dateRange.to.format("YYYYMMDD")}</DialogTitle>
      {loading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3}>
          <Box>
            <CircularProgress />
          </Box>
          <Box mt={3}>
            <Button onClick={handleCancel} variant="contained" color="primary">
              Cancel
            </Button>
          </Box>
        </Box>
      ) : (
        <div>ok</div>
      )}
    </Dialog>
  );
};
