// //page
import Container from "IZOArc/Container/Container";
import { Denied, NoMatch } from "IZOArc/Fallback";
// //store
import { Accessor, Env, store } from "IZOArc/STATIC";
// //react
// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Chat from "./Chat/Chat";
import { theme } from "config/theme";

import { ThemeProvider } from "@mui/material";

// //css
import "./index.css";
// //pages
import {
  //
  Answer,
  Avatar,
  AWSLiveChat,
  Convlog,
  Dashboard,
  FAQ,
  FLE,
  Home,
  Initialize,
  System,
  T2VATrainer,
  Talk2VA,
  Training,
  Workspace,
  WhatsappPermissionList,
  FileHandler
} from "./Pages";
// //sub-pages
import { CounterExample, Entity, Intent } from "./Pages/Workspace";
// import "./preset.css";
// //others
import * as serviceWorker from "./serviceWorker";

// //react 18
import { createRoot } from "react-dom/client";
// import { ThemeProvider } from "@mui/material";
import { Typography } from "@mui/material";
import Chat from "Chat/Chat";
import RequestLog from "Pages/RequestLog/RequestLog";

// //mute the console on production launch
if (process.env.NODE_ENV === "production") {
  window.console.log = () => {};
}

store.isLoading(false);
store.clearAsk();
if (store.isLoggedIn()) {
  Env.CheckInitialized();
  Env.GetSysInfo();
  Env.RefreshToken();
}

//refresh token every 10 minutes
setInterval(() => {
  if (store.isLoggedIn()) {
    Env.RefreshToken();
  }
}, 600000);

const container = document.getElementById("root");
const root = createRoot(container!);

const App = () => (
  <ThemeProvider theme={theme}>
    <Container>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="Denied" element={<Denied />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="Convlog" element={<Convlog />} />
        <Route path="RequestLog" element={<RequestLog />} />
        <Route path="Talk2VA" element={<Talk2VA />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="Training" element={<Training />} />
        <Route path="Answer" element={<Answer />} />
        <Route path="Workspace" element={<Workspace />} />
        <Route path="Workspace/Intent" element={<Intent />} />
        <Route path="Workspace/Entity" element={<Entity />} />
        <Route path="FAQ/CounterExample" element={<CounterExample />} />
        <Route path="System" element={<System />} />
        <Route path="T2VATrainer" element={<T2VATrainer />} />
        <Route key="aws" path="AWSLiveChat" element={<AWSLiveChat />} />
        <Route path="FLE" element={<FLE />} />
        <Route path="Initialize" element={<Initialize />} />
        <Route path="/TestChat" element={<Chat />} />
        <Route path='WhatsappPermissionList' element={<WhatsappPermissionList />}/>
        <Route path="FileHandler" element={<FileHandler />}/>
      </Routes>
    </Container>
  </ThemeProvider>
);

root.render(
  <BrowserRouter basename="/cms">
    <Routes>
      <Route path="*" element={<App />}></Route>
      {/* <Route element={<NoMatch />} /> */}
    </Routes>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
