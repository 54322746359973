import { FormizoCustom } from "IZOArc/LabIZO/Formizo/FormizoSchema.interface";
import { store } from "IZOArc/STATIC";
import _ from "lodash";
import { QuickReply } from "../schema";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

/**
 * button caption qill be generated if not given uppon add, but will be later required during edit
 */

const ButtonCaptiionSchema = {
  add: (lang: { display: string; value: string }) => ({
    label: "Button Caption",
    name: `intent.${lang.value}.description`,
    format: "text",
  }),
  info: (lang: { display: string; value: string }) => ({
    label: "Button Caption",
    name: `intent.${lang.value}.description`,
    format: "text",
    validate: ["required"],
  }),
};

const FaqFormSchema = (fLang: { display: string; value: string }, buttonCapSchema: any, productItems: string[]) => {
  return [
    {
      label: "ID",
      name: "name",
      validate: ["required"],
      format: "text",
      readOnly: true,
    },
    {
      label: "Created At",
      name: "CREATED_AT",
      format: "text",
      readOnly: true,
    },
    {
      label: "Last Modified",
      name: "LASTMODIFIED",
      format: "text",
      readOnly: true,
    },
    {
      label: "Alias",
      name: "alias",
      validate: ["required"],
      format: "text",
    },
    {
      label: "Description",
      name: "description",
      format: "text",
    },
    {
      columns: [
        {
          width: "48vw",
          page: [
            {
              header: "Questions",
            },
            {
              tabs: store.sysInfo.Language.available.map((lang) => ({
                label: `${lang.display}`,
                error: (formValue: any, addOns: any, { formError }: any) => {
                  let examplesErrors = _.get(formError, `intent.${lang.value}.examples`) || [];
                  const captionError = _.get(formError, `intent.${lang.value}.description`) || [];
                  const error = [...examplesErrors, ...captionError].filter((_e: any) => !_.isEmpty(_e));
                  return error.length > 0;
                },
                page: () => {
                  const defaultLang = store.sysInfo.Language.default;
                  const minItems = defaultLang === lang.value ? 1 : 0;

                  return [
                    buttonCapSchema(lang),
                    {
                      label: `Examples (${lang.display})`,
                      name: `intent.${lang.value}.examples`,
                      canAdd: true,
                      canDelete: true,
                      minItems: minItems,
                      array: [
                        {
                          label: "",
                          name: "text",
                          format: "text",
                          validate: ["required"],
                        },
                      ],
                    },
                  ];
                },
              })),
            },
          ],
        },
        {
          width: "48vw",
          page: [
            {
              header: "Answer",
            },
            {
              label: "Answer ID",
              name: "answer._id",
              format: "text",
              readOnly: true,
            },
            {
              autos: productItems.map((product) => ({
                label: `${product}`,
                page: [
                  ...store.sysInfo.Language.available.slice().sort((a, b) => {
                    if (a.value === fLang.value) return -1;
                    if (b.value === fLang.value) return 1;
                    return 0;
                  }).map((o) => {
                    if (o.value === fLang.value)
                      return {
                        label: `${o.display}`,
                        foldStyle: "none",
                        defaultShow: true,
                        collapse: [
                          {
                            name: `answer.product.${product}.${o.value}.0`,
                            format: "textarea",
                            rows: 5,
                            variant: "outlined",
                            validate: ["required"],
                            resizable: true,
                          },
                        ],
                      };
                    return {
                      label: `${o.display}`,
                      foldStyle: "none",
                      collapse: [
                        {
                          name: `answer.product.${product}.${o.value}.0`,
                          format: "textarea",
                          rows: 5,
                          variant: "outlined",
                          validate: ["required"],
                          resizable: true,
                        },
                      ],
                    };
                  }),
                  {
                    label: "Quick Reply Buttons",
                    name: `answer.product.${product}.quickReplies`,
                    canAdd: true,
                    canDelete: true,
                    headerStyle: "outlined",
                    canMove: true,
                    addStyle: "header",
                    array: [
                      {
                        label: "Display",
                        width: 50,
                        name: "title",
                        tabs: [
                          {
                            label: "EN",
                            page: [
                              {
                                label: "Display",
                                name: "title.EN",
                                format: "text",
                              },
                            ],
                          },
                          {
                            label: "TC",
                            page: [
                              {
                                label: "Display",
                                name: "title.TC",
                                format: "text",
                              },
                            ],
                          },
                          {
                            label: "SC",
                            page: [
                              {
                                label: "Display",
                                name: "title.SC",
                                format: "text",
                              },
                            ],
                          },
                        ],
                      },
                      {
                        label: "Button Payload",
                        name: "payload",
                        format: "select",
                        selectStyle: "dropdown",
                        selectRef: "intents",
                        selectCap: "caption",
                        selectVal: "_id",
                        placeholder: "Intent",
                        validate: ["required"],
                        variant: "grid",
                        width: 250,
                      },
                    ],
                  },
                  //replaced
                  {
                    label: "Products replaced by same Quick Reply Buttons",
                    name: `answer.product.${product}.additionalQuickReplies`,
                    canAdd: true,
                    canDelete: true,
                    headerStyle: "outlined",
                    addStyle: "header",
                    array: [
                      {
                        label: "Select Product",
                        name: "product",
                        format: "select",
                        selectStyle: "dropdown",
                        selectRef: productItems,
                        placeholder: "Product",
                      },
                    ],
                  },

                  {
                    //label: "Replace the Quick Reply Buttons of all product",
                    name: "answer.product",
                    format: "display",
                    Custom: (row: any, field: any, addOns: any) => {
                      return (
                        <Box display="flex" justifyContent="center" gap={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              let currentProductQuickReplies = _.cloneDeep(row.answer.product[product].quickReplies);
                              const selectedProducts = row.answer.product[product].additionalQuickReplies;
                              let selectedProductNames = selectedProducts.map((item: any) => item.product);

                              Object.keys(row.answer.product).forEach((productItem: string) => {
                                if (selectedProductNames.includes(productItem)) {
                                  //use cloneDeep to avoid changing the original value
                                  row.answer.product[productItem].quickReplies = _.cloneDeep(currentProductQuickReplies);
                                  console.log("Replace:", row.answer.product[productItem]);
                                }
                              });

                              // delete the empty QRB somehow generated
                              Object.keys(row.answer.product).forEach((productItem: string) => {
                                row.answer.product[productItem].quickReplies = row.answer.product[productItem].quickReplies.filter((reply: any) => reply);
                              });
                            }}
                          >
                            Replace selected product
                          </Button>

                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                              e.preventDefault();
                              let currentProductQuickReplies2 = _.cloneDeep(row.answer.product[product].quickReplies);
                              Object.keys(row.answer.product).forEach((productItem: string) => {
                                row.answer.product[productItem].quickReplies = _.cloneDeep(currentProductQuickReplies2);
                                console.log("Replace:", row.answer.product[productItem]);
                              });

                              // delete the empty QRB somehow generated
                              Object.keys(row.answer.product).forEach((productItem: string) => {
                                row.answer.product[productItem].quickReplies = row.answer.product[productItem].quickReplies.filter((reply: any) => reply);
                              });
                            }}
                          >
                            Replace all product
                          </Button>
                        </Box>
                      );
                    },
                  },
                ],
              })),
            },
          ],
          QuickReply,
        },
      ],
    },
  ];
};

// Wrapper function for Info
export const DynamicInfo = (productItems: string[]): FormizoCustom => {
  return (_formValue, _addOns, fLang: { display: string; value: string }) => FaqFormSchema(fLang, ButtonCaptiionSchema.info, productItems);
};

// Wrapper function for Info
export const DynamicAdd = (productItems: string[]): FormizoCustom => {
  return (_formValue, _addOns, fLang: { display: string; value: string }) => FaqFormSchema(fLang, ButtonCaptiionSchema.add, productItems);
};

export const DynamicEdit = DynamicInfo;
