import React from "react";

import Accuracy from "./Accuracy/Accuracy";
import Distribution from "./Distribution/Distribution";
import Header from "../Header/Header";
import "./Performance.scss";

import { VStack, HStack } from "IZOArc/LabIZO/Stackizo";
import { ColorX } from "IZOArc/STATIC";

const Performance = ({ base, data }) => {
  let { titles, graphTitles } = base;

  return (
    <VStack width='100%'>
      <Header title={(titles && titles.Performance) || ""} />
      <HStack
        className='Performance'
        width='100%'
        height='100%'
        style={{ boxShadow: "5px 5px 13px #ededed, 5px 5px 13px #ffffff", backgroundColor: ColorX.GetColorCSS("GraphBG"), borderRadius: "20px", overflow: "hidden" }}
      >
        <Accuracy title={graphTitles && graphTitles.IntentAcc} data={data && data.IntentAccuracy} />
        <Distribution title={graphTitles && graphTitles.IntentDis} data={data && data.IntentDistribution} />
      </HStack>
    </VStack>
  );
};
export default Performance;
