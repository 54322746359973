import React, { Component } from "react";
import PropsType from "prop-types";
import _ from "lodash";

import ZCTouch from "../ZCTouch";

import "./ZCMsg.css";

class ZCMButtons extends Component {
  static propTypes = {
    buttons: PropsType.array,
    disabled: PropsType.bool,
  };

  static defaultProps = {
    buttons: [],
    disabled: true,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  static getDerivedStateFromProps(nextProps, prevStat) {
    if (prevStat !== nextProps) {
      return {
        ...nextProps,
      };
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  renderButtons() {
    let { cssp, buttons, _onQuickReply, onPhoneClick, onWebClick, disabled, buttonWidthFitContent } = this.state;

    let rendered = [];
    _.map(buttons, (o, i) => {
      let func = null;
      switch (o.type) {
        case "web":
          func = () => onWebClick(o.payload);
          break;
        case "phone":
          func = () => onPhoneClick(o.payload);
          break;
        default:
          func = () => _onQuickReply(o);
          break;
      }

      rendered.push(
        <div key={i} className={cssp + " msg-btn" + (disabled ? " disabled" : "") + (buttonWidthFitContent ? " fitcontent" : "")}>
          <ZCTouch cssPrefix={cssp} onPress={func} disabled={disabled}>
            <div className={cssp + " msg-btn-text" + (disabled ? " disabled" : "")}>{o.title}</div>
          </ZCTouch>
        </div>
      );
    });

    return rendered;
  }

  render() {
    let { cssp } = this.state;
    return <div className={cssp + " msg-btnlist"}>{this.renderButtons()}</div>;
  }
}

export default ZCMButtons;
