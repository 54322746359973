import React, { Component } from "react";

import { observer } from "mobx-react";
import axios from "axios";
import fileDownload from "js-file-download";
import { Box, Typography } from "@mui/material";

import schema from "./schema";
import datalink from "./datalink";
import { DOMAIN } from "config/config";
import FAQCreate from "./FAQCreate";
import ImportConfirm from "./ImportConfirm/ImportConfirm";

import Datumizo from "IZOArc/LabIZO/Datumizo/Datumizo";
import { Accessor, ColorX, Authority, store, ErrorX } from "IZOArc/STATIC";
import { VStack } from "IZOArc/LabIZO/Stackizo";
import _ from "lodash";
import { getProductItems } from "config/sunlife-product-config";

class FAQ extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      title: "FAQ MANAGEMENT",
      serverSidePagination: false,
      productItems: [],
      base: {
        title: "FAQs",
        exportDoc: "FAQs",
        schema: schema,
        reqAuth: "FAQ",

        tablizo: {
          showSelector: true,
          columnsToolbar: true,
          filterToolbar: true,
          densityToolbar: true,
          exportToolbar: false,
        },

        Connect: {
          DBInfo: datalink.Request.DBInfo,
          List: datalink.Request.List,
          schema: schema.Table,
        },

        operations: {
          Add: {
            title: "Create FAQ",
            url: datalink.Request.Add,
            success: "FAQ Added Successfully",
            fail: "FAQ Add Failed: ",
            schema: schema.DynamicAdd([]),
            buttons: ["Clear", "Submit"],
            Custom: (id, row, onQuit, onQuitRefresh, renderFormizo) => {
              const addOns = {
                workspace: "master",
                intents: this.state.intents,
              };
              return <FAQCreate onQuit={onQuit} onQuitRefresh={onQuitRefresh} getQuickReplies={this.getQuickReplies} addOns={addOns} />;
            },
          },
          Delete: {
            title: "Delete this FAQ?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.Delete,
            success: "FAQ Deleted Successfully.",
            fail: "FAQ Delete Failed: ",
            onSubmit: "Delete",
          },
          Edit: {
            title: "Edit FAQ",
            url: datalink.Request.Edit,
            success: "FAQ Edited Successfully",
            fail: "FAQ Edit Failed: ",
            schema: schema.DynamicEdit([]),
            buttons: ["Revert", "Submit"],
            onSubmit: "Edit",
          },
          Info: {
            title: "FAQ DETAILS",
            schema: schema.DynamicInfo([]),
            readOnly: true,
          },

          Export: {
            url: datalink.Request.Export,
          },

          Import: {
            title: "FAQ Import",
            content: "",
            url: datalink.Request.Import,
            onSuccess: this.ImportConfirm.onReturn,
            success: "FAQ Imported Successfully.",
            fail: "FAQ Import Failed: ",
          },

          ImportConfirm: {
            title: "Import Summary",
            Custom: (id, row, onQuit, onQuitRefresh, renderFormizo, addOns) => <ImportConfirm docID={id} doc={row} onQuit={onQuit} onQuitRefresh={onQuitRefresh} addOns={addOns} />,
          },

          DeleteBulk: {
            title: (n) => "Delete these " + n + " FAQs?",
            content: "Caution: This is irrevertable.",
            url: datalink.Request.DeleteBulk,
            success: "FAQs Deleted Successfully.",
            fail: "FAQs Delete Failed: ",
            onSubmit: "DeleteBulk",
          },
        },

        buttons: {
          inline: [
            { icon: "edit", func: "Edit", caption: "Edit", reqFunc: "Edit" },
            { icon: "info", func: "Info", caption: "Details" },
          ],
          inlineOpposite: [{ icon: "delete", func: "Delete", caption: "Delete", reqFunc: "Delete" }],
          left: [{ icon: "add", func: "Add", caption: "Add FAQ", reqFunc: "Add", reqLevel: 2 }],
          right: [
            {
              icon: "analyse",
              func: this.Analyse,
              caption: "Analyse",
              reqFunc: "Analyse",
              reqLevel: 0,
              theme: {
                label: "white",
                background: ColorX.GetColorCSS("Decorate1"),
                hover: {
                  background: "#115293",
                },
              },
            },
            { icon: "deletebulk", func: "DeleteBulk", caption: (n) => "Delete (" + n + ")", reqFunc: "Delete", theme: "caution", reqLevel: 2 },
            { icon: "export", func: "Export", caption: (n) => "Export (" + (n === 0 ? "All" : n) + ")", reqFunc: "Export", reqLevel: 2 },
            {
              icon: "import",
              func: "Import",
              caption: "Import",
              reqFunc: "Import",
              reqLevel: 2,
            },
          ],
        },
      },
    };
  }

  async componentDidMount() {
    Authority.Require("FAQ");
    const productItems = await getProductItems();
    this.setState((state, props) => {
      state.base.operations.Info.schema = schema.DynamicInfo(productItems);
      state.base.operations.Add.schema = schema.DynamicAdd(productItems);
      state.base.operations.Edit.schema = schema.DynamicEdit(productItems);
      return {
        ...state,
        ...props,
      };
    }, this.getQuickReplies);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(FAQ.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  onMountDatumizo = (callbacks) => {
    this.MountDatumizo = callbacks;
  };

  Analyse = async (rows) => {
    let url = DOMAIN + "/Tables/FAQ/Analyse";

    let payload = {
      data: {
        rows,
      },
      JWT: store.user.JWT,
    };

    try {
      console.log("/Tables/FAQ/Analyse", payload);

      let options = {
        responseType: "blob", //!important
      };
      store.isLoading(true);
      let res = await axios.post(url, payload, options);
      store.isLoading(false);
      if (res.data) {
        const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        fileDownload(blob, "Conflict Report.xlsx");
      } else {
        store.Alert("Failed", "error");
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  ImportConfirm = {
    onReturn: (payload) => {
      let { uuid } = payload;
      if (this.MountDatumizo) {
        this.MountDatumizo.CustomInner("ImportConfirm", uuid, payload);
      }
      store.clearAsk();
    },
  };

  getQuickReplies = async () => {
    let url = DOMAIN + "/Tables/Intent/List";
    let payload = {
      JWT: store.user.JWT,
      data: {
        skip: 0,
        limit: 9999,
        fields: ["_id"],
      },
      addOns: {
        workspace: "ALL",
        allIntents: true,
      },
    };
    try {
      let rtn = await axios.post(url, payload);
      console.log("/Tables/Intent/List", rtn.data);

      if (rtn.data.Success === true) {
        let docs = rtn.data.payload.docs;
        let intents = _.map(docs, (o, i) => {
          let [workspace, alias] = o._id.split(":");

          return {
            _id: `CMD_BTN ${alias}`,
            caption: alias,
          };
        });

        intents = _.filter(intents, (o) => o._id !== "");
        intents = [...intents];
        console.log(intents);
        this.setState((state, props) => ({
          intents: intents,
        }));
      } else {
        ErrorX.Handle(rtn.data);
      }
    } catch (e) {
      ErrorX.Handle(e);
    }
  };

  render() {
    let { base, serverSidePagination, title, intents } = this.state;
    return (
      <VStack>
        <Box padding={1} width="100%">
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: 25,
              color: ColorX.GetColorCSS("Primary"),
            }}
          >
            {title}
          </Typography>
        </Box>
        <Datumizo
          onMounted={this.onMountDatumizo}
          base={base}
          serverSidePagination={serverSidePagination}
          addOns={{
            workspace: "master",
            intents,
          }}
          languageSelector={true}
        />
      </VStack>
    );
  }
}

export default observer(FAQ);
