let mwconfig = [
  {
    label: "Disclaimer Active",
    name: "active",
    format: "bool",
    boolStyle: "switch"
  },
  {
    label: "Disclaimer Answer ID",
    name: "disclaimerID", 
    format: "text",
  },
  {
    label: "Disclaimer Confirmed Answer ID",
    name: "thankyouID",
    format: "text"
  },
  {
    label: "Disclaimer After Confirm Welcome Message Answer ID",
    name: "welcomeID",
    format: "text"
  },
  {
    label: "Verify Words",
    name: "verifyWords",
    format: "array",
    arrayStyle: "table",
    canAdd: true,
    canDelete: true,
    array: [
      {
        label: "",
        name: "",
        format: "text"
      },
    ],
  }
];

let schema = {
  mwconfig,
};

export default schema;
