import React, { Component } from "react";

import PropsType from "prop-types";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { HStack } from "IZOArc/LabIZO/Stackizo";
import { Accessor, ColorX } from "IZOArc/STATIC";

class CheckPass extends Component {
  static propTypes = {
    docID: PropsType.string,
    doc: PropsType.object,
    passed: PropsType.bool,
    tagged: PropsType.bool,
    trainID: PropsType.string,
    onToggle: PropsType.func,
  };

  static defaultProps = {
    docID: "",
    doc: {},
    passed: false,
    tagged: false,
    trainID: "",
    onToggle: () => {},
  };

  constructor() {
    super();
    this.state = {
      pass: false,
      tag: false,
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this.setState((state, props) => ({
        pass: props.passed,
        tag: props.tagged,
      }));
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(CheckPass.defaultProps))) {
      this.setState({
        pass: this.props.passed,
        tag: this.props.tagged,
      });
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  _setPass = (f) => {
    let { onToggle, docID } = this.props;
    this.setState(
      {
        pass: f,
        tag: true,
      },
      () => {
        if (onToggle) onToggle(docID, f);
      }
    );
  };

  render() {
    let { pass, tag } = this.state;
    return (
      <HStack>
        {(!tag || pass) && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              this._setPass(tag ? false : true);
            }}
            style={{ color: ColorX.GetColorCSS("Approval"), opacity: tag ? 1 : 0.4 }}
          >
            <CheckCircle />
          </IconButton>
        )}
        {(!tag || !pass) && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              this._setPass(tag ? true : false);
            }}
            style={{ color: ColorX.GetColorCSS("Cancel"), opacity: tag ? 1 : 0.4 }}
          >
            <Cancel />
          </IconButton>
        )}
      </HStack>
    );
  }
}

export default CheckPass;
